import React from 'react';
import { Card, Empty, Table } from 'antd';
import {
  arrayOf, shape, string, bool,
} from 'prop-types';
import columns from './columns';

const SupportRequests = ({ data, loading, title }) => (
  <Card title={title}>
    {(!data || data.length === 0) && !loading ? (<Empty description="No support request yet" />) : (
      <Table
        loading={loading}
        rowKey="id"
        dataSource={data}
        columns={columns}
        onRow={() => ({
          onClick: () => {
            // console.log(record);
          },
        })}
      />
    )}
  </Card>
);

SupportRequests.defaultProps = {
  title: 'Recent Support Requests',
};

SupportRequests.propTypes = {
  data: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  title: string,
};

export default SupportRequests;
