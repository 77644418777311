import React, { useEffect } from 'react';
import { Select, Input } from 'antd';
import { shape, arrayOf, func } from 'prop-types';

const { Option } = Select;
const { Search } = Input;
const Filter = ({ cohorts, onFilterChange }) => {
  useEffect(() => {
    onFilterChange('ACCOUNT_TYPE', 'STUDENT');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohorts]);
  return (
    <div className="gx-mb-2">
      <Select onSelect={(val) => onFilterChange('COHORT_NAME', val)} className="gx-mr-3 gx-mb-3" placeholder="Select Cohort" style={{ width: '20%' }}>
        <Option key="ALL" value="ALL">All Cohort</Option>
        {cohorts.map(({ id, name }) => (
          <Option key={id} value={id}>{name}</Option>
        ))}
      </Select>
      <Select defaultValue="STUDENT" onSelect={(val) => onFilterChange('ACCOUNT_TYPE', val)} className="gx-mr-3 gx-mb-3" placeholder="Account Type" style={{ width: '20%' }}>
        <Option value="ALL">All Users</Option>
        <Option value="STUDENT">Student</Option>
        <Option value="TEACHER">Teacher</Option>
      </Select>
      <Search
        placeholder="Search user by name or email"
        onChange={(e) => onFilterChange('SEARCH', e.target.value)}
        style={{ width: '55%' }}
      />
    </div>
  );
};
Filter.propTypes = {
  cohorts: arrayOf(shape({})).isRequired,
  onFilterChange: func.isRequired,
};

export default Filter;
