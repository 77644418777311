import React, { useContext, useState, useEffect } from 'react';
import {
  Col, Row, Empty, Divider,
} from 'antd';
import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { GET_STUDENT_ACTIVITIES } from '../../../../graphql';
import ActivityCard from '../../common/ActivityCard';
import ViewSpinner from '../../../../components/viewSpinner/ViewSpinner';

import { AuthContext } from '../../../../appContext';

import './styles.less';

const StudentActivityHome = () => {
  const { userInfo: { id: userId } } = useContext(AuthContext);
  const [activities, setActivities] = useState({
    onlineClass: [],
    assignment: [],
  });
  const { data: activityPayload, loading: activityLoading } = useQuery(GET_STUDENT_ACTIVITIES, {
    variables: { where: { userId: { equals: userId } } },
    // fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const { studentActivities } = activityPayload;
      const sorted = sortBy(studentActivities, [(value) => value.activity.dueDate]).reverse();
      const onlineClass = sorted.filter(({ activity: { type } }) => type === 'ONLINE_CLASS');
      const assignment = sorted.filter(({ activity: { type } }) => type === 'ASSIGNMENT');
      setActivities({ onlineClass, assignment });
    }
  }, [activityPayload, activityLoading]);
  return (
    <div className="student-activity-home">
      <Row>
        <Col span={24}>
          <h4 className="ic-ft-dosis header">Your activities</h4>
        </Col>
      </Row>
      <Row className="gx-mt-1">
        <Divider className="gx-mb-4" orientation="left">Online Classes</Divider>
        {activities.onlineClass.map((
          {
            id, status, activity: { title, dueDate, type }, submission,
          },
        ) => (
          <ActivityCard
            id={id}
            type={type}
            key={id}
            submission={submission}
            status={status}
            date={dueDate}
            title={title}
          />
        ))}
        {!activityLoading && activities.onlineClass.length === 0 && (
          <Col span={24}>
            <Empty description="You don't have any online class yet" />
          </Col>
        )}
      </Row>
      <Row className="gx-mb-2">
        <Divider className="gx-mb-4" orientation="left">Assignments</Divider>
        {activities.assignment.map((
          {
            id, status, activity: { title, dueDate, type }, submission,
          },
        ) => (
          <ActivityCard
            id={id}
            type={type}
            key={id}
            submission={submission}
            status={status}
            date={dueDate}
            title={title}
          />
        ))}
        {!activityLoading && activities.assignment.length === 0 && (
          <Col span={24}>
            <Empty description="You don't have any assignment yet" />
          </Col>
        )}
      </Row>
      {activityLoading && (<ViewSpinner />)}
    </div>
  );
};

export default StudentActivityHome;
