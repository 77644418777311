// @todo: this component looks similar to the other, refactor
import React, { useEffect, useContext } from 'react';
import { Drawer, Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { arrayOf, any } from 'prop-types';
import {
  UiContext, toggleCollapsedSideNav, updateWindowsWidth, AuthContext,
} from '../../../appContext';
import SidebarContent from './SidebarContent';
import LearnContent from './LearnContent';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from '../../../constants/ThemeSetting';

const { Sider } = Layout;

const Sidebar = ({ routes }) => {
  const {
    uiState: {
      navStyle, themeType, navCollapsed, width,
    }, uiDispatch,
  } = useContext(UiContext);
  const { userInfo: { accountType } } = useContext(AuthContext);
  const { location: { pathname } } = useHistory();
  const onToggleCollapsedNav = () => {
    uiDispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      uiDispatch(updateWindowsWidth(window.innerWidth));
    });
  }, [uiDispatch]);

  let drawerStyle = 'gx-collapsed-sidebar';

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = '';
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar gx-mini-custom-sidebar';
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = 'gx-custom-sidebar';
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar';
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = 'gx-collapsed-sidebar';
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = 'gx-collapsed-sidebar';
  }

  const isLearnMode = accountType === 'STUDENT' && pathname.includes('learn');
  const RenderContent = isLearnMode ? <LearnContent /> : <SidebarContent routes={routes} />;
  return (
    <Sider
      className={`gx-app-sidebar ${isLearnMode ? 'learn-sb' : ''} ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR
        || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
      collapsible
    >
      {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE
          ? (
            <Drawer
              className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
              placement="left"
              closable={false}
              onClose={onToggleCollapsedNav}
              visible={navCollapsed}
            >
              {RenderContent}
            </Drawer>
          )
          : <>{RenderContent}</>
      }
    </Sider>
  );
};

Sidebar.propTypes = {
  routes: arrayOf(any.isRequired).isRequired,
};

export default Sidebar;
