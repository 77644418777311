import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { Tag } from 'antd';

const ReplyFooter = ({ handleReply, isClosed }) => {
  const [message, setMessage] = useState('');
  const onReply = () => {
    if (message === '') return;
    handleReply(message);
    return setMessage('');
  };
  return (
    <div className="gx-chat-main-footer">
      <div className="gx-flex-row gx-align-items-center" style={{ maxHeight: 51 }}>
        {!isClosed ? (
          <>
            <div className="gx-col">
              <div className="gx-form-group">
                <textarea
                  id="required"
                  className="gx-border-0 ant-input gx-chat-textarea"
                  placeholder="Type in a reply"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </div>
            </div>
            <i role="presentation" onClick={onReply} className="gx-icon-btn icon icon-sent" />
          </>
        ) : (
          <div className="gx-mt-1">
            <h4 className="gx-m-0 closed-text">This support is closed, cannot reply to it</h4>
            <Tag className="gx-mt-1" color="volcano">Support Closed</Tag>
          </div>
        )}
      </div>
    </div>
  );
};

ReplyFooter.propTypes = {
  handleReply: func.isRequired,
  isClosed: bool.isRequired,
};

export default ReplyFooter;
