import {
  ACTIVITIES, ACTIVITY_SUBMISSIONS,
} from '../../routes';
import HomeActivity from './home';
import ActivitySubmission from './submission';

export default [
  {
    id: 6, title: 'Home', exact: true, path: ACTIVITIES, component: HomeActivity,
  },
  {
    id: 7, title: 'Activity Submission', exact: true, path: ACTIVITY_SUBMISSIONS, component: ActivitySubmission,
  },
];
