/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { bool, string, func } from 'prop-types';
import { LOGIN } from '../routes';
import { AuthContext } from '../appContext';
import CircularProgress from '../lib/CircularProgress';

const ProtectedRoute = ({
  exact, path, component: Component, ...rest
}) => {
  const { isAuthenticated, authStateLoading } = useContext(AuthContext);

  if (authStateLoading || isAuthenticated === null) {
    return <CircularProgress />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={() => (isAuthenticated ? <Component /> : <Redirect to={LOGIN} />)}
    />
  );
};

ProtectedRoute.propTypes = {
  exact: bool.isRequired,
  component: func.isRequired,
  path: string.isRequired,
};

export default ProtectedRoute;
