import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Divider } from 'antd';
import { GiPerson } from 'react-icons/gi';
import { MdSubject } from 'react-icons/md';
import { FcGraduationCap } from 'react-icons/fc';
import { useQuery } from '@apollo/client';
import SupportRequests from './supportRequests';
import IconWithTextCard from '../../lib/Metrics/IconWithTextCard';
import RecentActivities from './RecentActivities';
import { AuthContext } from '../../appContext';
import { GET_INSTRUCTOR_STATISTICS, GET_ALL_ACTIVITY, GET_USER_SUPPORTS } from '../../graphql';
import ViewSpinner from '../../components/viewSpinner/ViewSpinner';
import './styles.less';

const TeacherDashboard = () => {
  const { userInfo } = useContext(AuthContext);
  const { data: statPayload, loading: loadingStat } = useQuery(GET_INSTRUCTOR_STATISTICS);
  const { data: activityPayload, loading: activityLoading } = useQuery(GET_ALL_ACTIVITY, {
    variables: {
      orderBy: { createdAt: 'desc' },
      take: 10,
    },
    fetchPolicy: 'network-only',
  });
  const { data: supportPayload, loading: loadingSupport } = useQuery(GET_USER_SUPPORTS, {
    variables: {
      take: 6,
    },
    fetchPolicy: 'network-only',
  });
  const [statistics, setStatistics] = useState(null);
  const [activities, setActivities] = useState([]);
  const [supports, setSupports] = useState([]);
  useEffect(() => {
    if (!loadingStat && statPayload) {
      const { getInstructorStatistics } = statPayload;
      setStatistics(getInstructorStatistics);
    }
  }, [statPayload, loadingStat]);
  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const { activities: _activities } = activityPayload;
      setActivities(_activities);
    }
  }, [activityPayload, activityLoading]);
  useEffect(() => {
    if (!loadingSupport && supportPayload) {
      const { userSupports } = supportPayload;
      setSupports(userSupports);
    }
  }, [supportPayload, loadingSupport]);
  return (
    <Row className="dashboard">
      <>
        {loadingStat && <ViewSpinner />}
        <Col span={24} className="heading">
          <h3>
            Welcome!
            {' '}
            {userInfo && (
              <span className="name">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
            )}
          </h3>
        </Col>
        <Col span={24}>
          <Divider orientation="left">You have</Divider>
        </Col>
        {!loadingStat && statistics && (
          <>
            <Col md={8} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<FcGraduationCap color="#1cc7e3" size={40} />}
                title={statistics.cohorts}
                subTitle="Cohorts"
              />
            </Col>
            <Col md={8} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<MdSubject color="#ee4f76" size={40} />}
                title={statistics.subjects}
                subTitle="Subjects"
              />
            </Col>
            <Col md={8} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<GiPerson size={40} color="#5db85d" />}
                title={statistics.students}
                subTitle="Students"
              />
            </Col>
          </>
        )}
        <Col span={24}>
          <RecentActivities columnType="Teacher" title="Your Recent Activities" data={activities} loading={activityLoading} />
        </Col>
        <Col span={24}>
          <SupportRequests title="Recent Support Request" loading={loadingSupport} data={supports} />
        </Col>
      </>
    </Row>
  );
};

export default TeacherDashboard;
