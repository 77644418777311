/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { AuthContext } from '../../appContext/AuthContext';
import AdminDashboard from './AdminDashboard';
import TeacherDashboard from './TeacherDashboard';

const Dashboard = () => {
  const { userInfo: { role } } = useContext(AuthContext);

  return (
    <>
      {role === 'ADMIN' ? (
        <AdminDashboard />
      ) : role === 'INSTRUCTOR' ? (
        <TeacherDashboard />
      ) : null}
    </>
  );
};

export default Dashboard;
