import React, { useState, useEffect } from 'react';
import {
  Card, Button, Form, Col, Select, Input, Row, message, Modal,
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { shape, string } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { differenceBy } from 'lodash';
import AddCohortModal from './AddModal';
import { GET_ALL_COHORT, UPDATE_USER_INFO } from '../../../../../graphql';

const { Item: FormItem } = Form;
const { Option } = Select;
const { confirm } = Modal;
const CohortCard = ({ user, title }) => {
  const { data, loading } = useQuery(GET_ALL_COHORT);
  const [mutateUpdateUser] = useMutation(UPDATE_USER_INFO);
  const [showAddModal, setShowAddModal] = useState(false);
  const [allCohort, setAllCohort] = useState([]);
  useEffect(() => {
    if (!loading && data) {
      const { getAllCohort } = data;
      setAllCohort(getAllCohort);
    }
  }, [data, loading]);
  const getCohortSubjects = (cohortId, userSubject) => {
    const result = userSubject.filter(({ subject: { cohortId: _cohortId } }) => _cohortId === cohortId);
    return result;
  };
  const selectCohort = (userCohort) => {
    const userCohortId = userCohort.map(({ cohort: { id } }) => ({ id }));
    const diff = differenceBy(allCohort, userCohortId, 'id');
    return diff;
  };
  const handleRemoveCohort = async (userCohortId, userCohortSubject) => {
    const userSubjectId = userCohortSubject.map(({ id }) => ({ id }));
    const hide = message.loading('Removing cohort..', 0);
    try {
      await mutateUpdateUser({
        variables: {
          id: user.id,
          data: {
            UserSubject: {
              delete: userSubjectId,
            },
            UserCohort: {
              delete: { id: userCohortId },
            },
          },
        },
      });
      hide();
      message.success('Cohort Removed');
    } catch (error) {
      hide();
      message.error('Error, could not remove cohort');
    }
  };
  const showConfirm = (userCohortId, userCohortSubject) => {
    confirm({
      title: 'Do you wish to remove this cohort',
      icon: <ExclamationCircleOutlined />,
      content: 'this would prevent user from access cohort resource',
      onOk() {
        return handleRemoveCohort(userCohortId, userCohortSubject);
      },
      onCancel() {},
    });
  };

  return (
    <>
      {showAddModal && (
      <AddCohortModal
        cohorts={selectCohort(user.UserCohort)}
        userId={user.id}
        visible={showAddModal}
        closeModal={setShowAddModal}
      />
      )}
      <Card
        className="cohort-card"
        title={title}
        extra={(
          <Button
            className="gx-m-0"
            type="primary"
            size="small"
            onClick={() => setShowAddModal(true)}
          >
            Add Cohort
          </Button>
        )}
      >
        <Form layout="vertical">
          {user.UserCohort.map(({ id: userCohortId, cohort: { name, id: cohortId } }, idx) => {
            const userCohortSubject = getCohortSubjects(cohortId, user.UserSubject);
            return (
              <Row key={cohortId} className="no-flex-column">
                <Col xs={24} sm={10}>
                  <FormItem initialValue={name} name={`cohort_${cohortId}`} label={idx === 0 ? 'Cohort' : ''}>
                    <Input disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={10}>
                  <FormItem initialValue={userCohortSubject.map(({ subject: { id: _id } }) => _id)} name={`subject_${cohortId}`} label={idx === 0 ? 'Subjects' : ''}>
                    <Select disabled mode="multiple">
                      {userCohortSubject && userCohortSubject.map(({ subject: { id, name: _name } }) => (
                        <Option key={id} value={id}>{_name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col className={`${idx === 0 && 'gx-mt-4'}`} xs={12} sm={4}>
                  <Button onClick={() => showConfirm(userCohortId, userCohortSubject)} type="text" className="gx-m-0">
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Form>
      </Card>
    </>
  );
};

CohortCard.defaultProps = {
  title: 'Teacher Cohort and Subjects',
};

CohortCard.propTypes = {
  user: shape({}).isRequired,
  title: string,
};

export default CohortCard;
