import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
	mutation loginUser($userName: String!, $password: String!) {
		loginUser(input: { userName: $userName, password: $password }) {
			id
			firstName
			lastName
			role
			accountType
			id
			token
			userName
			profileTeacher {
				id
				profileImg
				gender
				doe
			}
			profileStudent {
				id
				profileImg
				gender
			}
		}
	}
`;

// register teacher
export const REGISTER_TEACHER = gql`
	mutation registerTeacher($input: UserCreateInput!) {
		registerUser(input: $input) {
			id
			email
			lastName
			userName
			profileTeacher {
				id
				kinName
			}
		}
	}
`;

// register teacher
export const REGISTER_STUDENT = gql`
	mutation registerStudent($input: UserCreateInput!) {
		registerUser(input: $input) {
			id
			email
			userName
			profileStudent {
				id
				admissionNumber
			}
		}
	}
`;

// Update user profile
export const UPDATE_USER_INFO = gql`
	mutation updateUserInfo($id: String!, $data: UserUpdateInput!){
	updateUser(where: {id: $id}, data: $data) {
		  id
			lastName
			firstName
			email
			accountType
			status
			userName
			profileStudent {
				id
				profileImg
				gender
				dob
				state
				zipCode
				country
				parentName
				parentPhoneNumber
				address
				admissionNumber
			}
			profileTeacher {
				id
				address
				phoneNumber
				profileImg
				state
				zipCode
				country
				kinName
				kinPhoneNumber
				kinAddress
				doe
			}
			UserCohort {
				id
				cohort {
					id
					name
				}
			}
			UserSubject {
				id
				subject {
					id
					name
					cohortId
				}
			}
	  }
	}
`;


// delete a user
export const DELETE_USER = gql`
	mutation deleteUser($id: String!) {
		deleteUser(where: { id: $id }) {
			id
			lastName
			firstName
		}
	}
`;


// complete reset password
export const COMPLETE_FORGET_PASSWORD = gql`
	mutation completeForgetPassword($input: CompleteForgetPasswordInput!) {
		completeForgetPassword(input: $input)
	}
`;

// init reset password
export const INIT_FORGET_PASSWORD = gql`
	mutation initForgetPassword($email: String!) {
		initForgetPassword(email: $email)
	}
`;

// reset password
export const RESET_PASSWORD = gql`
	mutation resetPassword($userId: String!) {
		resetPassword(userId: $userId){
			id
			email
		}
	}
`;

// change password
export const CHANGE_PASSWORD = gql`
	mutation changePassword($input: ChangePasswordInput!) {
		changePassword(input: $input){
			id
			email
		}
	}
`

