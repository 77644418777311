import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { GiPerson } from 'react-icons/gi';
import { MdSubject } from 'react-icons/md';
import { FcGraduationCap } from 'react-icons/fc';
import IconWithTextCard from '../../lib/Metrics/IconWithTextCard';
import RecentActivities from './RecentActivities';
import SupportRequests from './supportRequests';
import { AuthContext } from '../../appContext';
import { GET_STATISTICS, GET_ALL_ACTIVITY, GET_SUPPORTS } from '../../graphql';
import ViewSpinner from '../../components/viewSpinner/ViewSpinner';
import './styles.less';

const AdminDashboard = () => {
  const { userInfo } = useContext(AuthContext);
  const { data: statisticPayload, loading: statisticsLoading } = useQuery(GET_STATISTICS);
  const { data: activityPayload, loading: activityLoading } = useQuery(GET_ALL_ACTIVITY, {
    variables: {
      orderBy: { dueDate: 'desc' },
      take: 10,
    },
  });
  const { data: supportPayload, loading: loadingSupport } = useQuery(GET_SUPPORTS, {
    variables: {
      take: 6,
    },
    fetchPolicy: 'network-only',
  });
  const [statistics, setStatistics] = useState(null);
  const [recentActivities, setRecentActivities] = useState([]);
  const [supports, setSupports] = useState([]);
  useEffect(() => {
    if (!statisticsLoading && statisticPayload) {
      const { getStatistics } = statisticPayload;
      setStatistics(getStatistics);
    }
  }, [statisticsLoading, statisticPayload]);

  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const { activities } = activityPayload;
      setRecentActivities(activities);
    }
  }, [activityLoading, activityPayload]);
  useEffect(() => {
    if (!loadingSupport && supportPayload) {
      const { supports: _supports } = supportPayload;
      setSupports(_supports);
    }
  }, [supportPayload, loadingSupport]);
  return (
    <div className="dashboard">
      <Row>
        {(activityLoading || statisticsLoading) && (
        <ViewSpinner />
        )}
        <Col span={24} className="heading">
          <h3>
            Welcome!
            {' '}
            {userInfo && (
              <span className="name">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
            )}
          </h3>
        </Col>
        <Col span={24}>
          <Divider orientation="left">ADMIN, You have</Divider>
        </Col>
        {!statisticsLoading && statistics && (
          <>
            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<GiPerson size={40} color="#5db85d" />}
                title={statistics.students}
                subTitle="Students"
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<FaChalkboardTeacher color="#2658a1" size={40} />}
                title={statistics.teachers}
                subTitle="Teachers"
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<FcGraduationCap color="#1cc7e3" size={40} />}
                title={statistics.cohorts}
                subTitle="Cohorts"
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
              <IconWithTextCard
                icon={<MdSubject color="#ee4f76" size={40} />}
                title={statistics.subjects}
                subTitle="Subjects"
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col span={24} className="gx-mb-4">
          <RecentActivities data={recentActivities} loading={activityLoading} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SupportRequests title="Recent Support Request" loading={loadingSupport} data={supports} />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
