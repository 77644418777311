import React from 'react';
import { Card, Table } from 'antd';
import {
  arrayOf, bool, func, shape,
} from 'prop-types';
import columns from './columns';

const UserTable = ({ data, loading, handleDeleteUser }) => (
  <Card className="gx-card" title="Users">
    <Table rowKey="id" loading={loading} className="gx-table-responsive" columns={columns(handleDeleteUser)} dataSource={data} />
  </Card>
);

UserTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  handleDeleteUser: func.isRequired,
};

export default UserTable;
