import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card, Form, Row, Input, Col, Switch, Button, Space, InputNumber, message, Upload,
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import Editor from '../../../components/editor';
import { GET_TOPIC, UPDATE_TOPIC, UPLOAD_FILES } from '../../../graphql';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const { Item: FormItem, useForm } = Form;
const EditTopic = () => {
  const { location: { search }, goBack } = useHistory();
  const [form] = useForm();
  const topicId = new URLSearchParams(search).get('topic');
  const [fileDeleteCache, setFileDeleteCache] = useState([]);
  const { data: topicPayload, loading: loadingTopic } = useQuery(GET_TOPIC, { variables: { where: { id: topicId } } });
  const [updateTopic, { loading }] = useMutation(UPDATE_TOPIC);
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILES);

  const [content, setContent] = useState('');

  const handleFormSubmit = async (inputs) => {
    try {
      const { attachment } = inputs;
      const status = inputs.status ? 'PUBLISHED' : 'DRAFT';
      const payload = {
        title: { set: inputs.title },
        status: { set: status },
        video: { set: inputs.video },
        topicNo: { set: inputs.topicNo },
        content: { set: content },
      };
      if (attachment && attachment.fileList) {
        const { fileList } = attachment;
        let fileArr = [];
        let existingFiles = [];
        fileList.forEach((file) => {
          if (file.originFileObj) {
            const actualFile = file.originFileObj;
            fileArr = [...fileArr, actualFile];
          } else {
            existingFiles = [...existingFiles, { id: file.uid }];
          }
        });
        const { data: { createFiles } } = await filesUpload({ variables: { files: fileArr, folder: 'topic' } });
        const filesMap = createFiles.map(({ id }) => ({ id }));
        payload.uploads = {
          connect: [...filesMap, ...existingFiles],
        };
      }

      if (fileDeleteCache.length > 0) {
        if (payload.uploads) {
          payload.uploads.disconnect = fileDeleteCache;
        } else {
          payload.uploads = {
            disconnect: fileDeleteCache,
          };
        }
      }
      await updateTopic({ variables: { data: payload, where: { id: topicId } } });
      message.success('Topic updated successfully');
      goBack();
    } catch (err) {
      message.error('Could not update topic');
    }
  };
  useEffect(() => {
    if (!loadingTopic && topicPayload) {
      const { topic } = topicPayload;
      const status = topic.status !== 'DRAFT';
      form.setFields([
        { name: 'title', value: topic.title },
        { name: 'video', value: topic.video },
        { name: 'status', value: status },
        { name: 'topicNo', value: topic.topicNo },
      ]);
      setContent(topic.content);
    }
  }, [topicPayload, loadingTopic, form]);

  const makeDefaultFileList = () => {
    const { uploads } = topicPayload.topic;
    const fileList = uploads.map(({
      id, url, filename,
    }) => ({
      uid: id,
      name: `${filename.slice(11) || 'File'}`,
      status: 'done',
      url,
    }));
    return fileList;
  };

  return (
    <div>
      <Card className="gx-card gx-p-1 new-topic">
        {!loadingTopic && topicPayload && (
        <Form form={form} name="update-topic" requiredMark="optional" onFinish={handleFormSubmit} layout="vertical">
          <Row className="no-flex-column">
            <Col span={24} className="gx-mb-3">
              <div className="heading">
                <h3>Update Topic</h3>
                <Button onClick={goBack} size="middle" shape="circle" icon={<ArrowLeftOutlined />} />
              </div>
            </Col>
            <Col span={24}>
              <FormItem name="status" valuePropName="checked" required={false}>
                <Switch checkedChildren="Publish" unCheckedChildren="Draft" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name="title" required label="Title">
                <Input placeholder="enter a title" />
              </FormItem>
            </Col>
            <Col xm={24} md={14}>
              <FormItem name="video" required={false} label="Video Id">
                <Input placeholder="e.g ZZUyshYtsw" />
              </FormItem>
            </Col>
            <Col xm={24} md={10}>
              <FormItem name="topicNo" required={false} label="Topic Order">
                <InputNumber className="gx-w-100" placeholder="ordering" type="number" />
              </FormItem>
            </Col>
          </Row>
          <Col className="gx-mt-2" span={24}>
            <Editor content={content} setContent={setContent} />
          </Col>
          <Col className="gx-mt-4" span={24}>
            <FormItem
              label="Attachment"
              valuePropName="file"
              name="attachment"
            >
              <Upload
                listType="picture"
                beforeUpload={() => false}
                onRemove={(file) => setFileDeleteCache([...fileDeleteCache, { id: file.uid }])}
                defaultFileList={makeDefaultFileList()}
              >
                <Button icon={<UploadOutlined />}>Attach files</Button>
              </Upload>
            </FormItem>
          </Col>
          <Col className="gx-mt-4" span={24}>
            <Space>
              <Button onClick={goBack}>Cancel</Button>
              <Button loading={loading || uploadLoading} htmlType="submit" type="primary">Save Topic</Button>
            </Space>
          </Col>
        </Form>
        )}
        {loadingTopic && <ViewSpinner />}
      </Card>
    </div>
  );
};

export default EditTopic;
