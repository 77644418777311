import { DASHBOARD as BASE } from './main';

export const DASHBOARD_HOME = `${BASE}/`;
export const LEARNING = `${BASE}/learning`;
export const ACTIVITIES = `${BASE}/activities`;
export const SUPPORT = `${BASE}/support`;
export const MESSAGING_SMS = `${BASE}/messaging/sms`;
export const MESSAGING_EMAIL = `${BASE}/messaging/email`;
export const PROFILE = `${BASE}/profile`;
export const SETUP_USERS = `${BASE}/setup/users`;
export const SETUP_COHORT = `${BASE}/setup/cohort`;

// Routes for levels
export const SETUP_COHORT_HOME = `${SETUP_COHORT}/`;
export const SETUP_COHORT_DETAIL = `${SETUP_COHORT}/:id`;
export const SETUP_COHORT_DETAIL_R = `${SETUP_COHORT}/`;

// Routes fro users
export const SETUP_USER_HOME = `${SETUP_USERS}/`;
export const SETUP_USER_NEW = `${SETUP_USERS}/new/:userType`;
export const SETUP_USER_NEW_R = `${SETUP_USERS}/new/`; // this is used in the push method
export const SETUP_USER_DETAIL = `${SETUP_USERS}/detail/:id`;
export const SETUP_USER_DETAIL_R = `${SETUP_USERS}/detail/`;

// routes for learning
export const LEARNING_TOPIC = `${LEARNING}/:subjectId`;
export const LEARNING_TOPIC_CREATE = `${LEARNING}/topic/new`;
export const LEARNING_TOPIC_EDIT = `${LEARNING}/topic/edit`;

// routes for activities
// activity edit and activity create shifted to home drawer
export const ACTIVITY_SUBMISSIONS = `${ACTIVITIES}/:activityId`;
export const ACTIVITY_SUBMISSION_DETAIL = `${ACTIVITIES}/:activityId/:submissionId`;

// messaging
export const CREATE_MESSAGE_SMS = `${MESSAGING_SMS}/new`;
export const CREATE_MESSAGE_EMAIL = `${MESSAGING_EMAIL}/new`;
export const VIEW_MESSAGE_EMAIL = `${MESSAGING_EMAIL}/:id`;
export const VIEW_MESSAGE_SMS = `${MESSAGING_SMS}/:id`;

// -------------------------------- Student related routes -------------------

// Routes for student (entry)
export const STUDENT_HOME = `${BASE}/student/home`;
export const STUDENT_ACTIVITIES = `${BASE}/student/activities`;
export const STUDENT_SUPPORT = `${BASE}/student/support`;
export const STUDENT_PROFILE = `${BASE}/student/profile`;

// Student home routes
export const STUDENT_HOME_SUBJECT = `${STUDENT_HOME}/cohort/:id`;
export const STUDENT_HOME_SUBJECT_R = `${STUDENT_HOME}/cohort/`;
export const STUDENT_HOME_SUBJECT_LEARN = `${STUDENT_HOME_SUBJECT}/learn`;

// student activity routes
export const STUDENT_ACTIVITY_DETAILS = `${STUDENT_ACTIVITIES}/:id`;

// student support route
export const STUDENT_SUPPORT_REPLY = `${STUDENT_SUPPORT}/:id`;

export const sbTeacherMatchKey = (pathname) => {
  const entry = pathname.split('/');
  const routeRegion = entry.length > 1 ? `${entry[1]}` : 'dashboard';
  switch (routeRegion) {
    case 'dashboard':
      return 1;
    case 'learning':
      return 2;
    case 'activities':
      return 3;
    case 'support':
      return 4;
    case 'messaging':
      return 5;
    case 'profile':
      return 5;
    case 'setup':
      return 6;
    default:
      return 1;
  }
};
