import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import { func, shape, string } from 'prop-types';
import config from './config';

const Editor = ({ content, setContent, overrideConfig }) => {
  const editor = useRef(null);
  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={{ ...config, ...overrideConfig }}
      tabIndex="0"
      onBlur={(newContent) => setContent(newContent.target.innerHTML)}
    />
  );
};

Editor.defaultProps = {
  overrideConfig: {},
};

Editor.propTypes = {
  content: string.isRequired,
  setContent: func.isRequired,
  overrideConfig: shape({}),
};

export default Editor;
