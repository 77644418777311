import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { GET_SINGLE_USER, GET_ALL_COHORT } from '../../../graphql';
import { StudentDetail, TeacherDetail } from './detailCard';
import { SETUP_USER_HOME } from '../../../routes';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';

const UserDetail = () => {
  const { id } = useParams();
  const { loading: cohortLoading, data: cohortPayload } = useQuery(GET_ALL_COHORT);
  const {
    data: userPayload,
    loading: loadingUser,
  } = useQuery(GET_SINGLE_USER, { variables: { id } });
  const [userDetail, setUserDetail] = useState(null);
  const [cohorts, setCohorts] = useState([]);

  useEffect(() => {
    if (!loadingUser && userPayload) {
      const { User } = userPayload;
      setUserDetail(User);
    }
  }, [userPayload, loadingUser]);

  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { getAllCohort } = cohortPayload;
      setCohorts(getAllCohort);
    }
  }, [cohortLoading, cohortPayload]);

  if (loadingUser) {
    return <ViewSpinner />;
  }
  return (
    <div>
      <div className="no-padding-button-round gx-mb-2">
        <Link to={SETUP_USER_HOME}>
          <Button shape="round">
            <LeftCircleOutlined />
            Back
          </Button>
        </Link>
      </div>
      {userDetail && (
      <>
        {userDetail.accountType === 'STUDENT' ? <StudentDetail cohorts={cohorts} userDetail={userDetail} /> : <TeacherDetail cohorts={cohorts} userDetail={userDetail} />}
      </>
      )}
    </div>
  );
};

export default UserDetail;
