import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import CustomScrollbars from '../../../util/CustomScrollbars';
import SearchBox from '../../../lib/SearchBox';
import TicketCell from './ticketCell';

const { TabPane } = Tabs;

const SupportRequest = ({ supports, onSelectTicket, selectedTicketId }) => {
  const [search, setSearch] = useState('');
  const [tickets, setTickets] = useState(supports);
  const handleSearch = (value) => {
    if (value === '') {
      setTickets(supports);
      return setSearch(value);
    }
    const match = supports.filter(({ user: { firstName, lastName } }) => {
      const name = `${firstName} ${lastName}`.toLowerCase();
      return name.includes(value.toLowerCase());
    });
    setSearch(value);
    setTickets(match);
  };

  useEffect(() => {
    setTickets(supports);
  }, [supports]);
  return (
    <div className="gx-chat-sidenav-main">
      <div className="gx-chat-sidenav-header">
        <div className="gx-chat-search-wrapper">
          <SearchBox
            styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
            placeholder="Search supports"
            onChange={(e) => handleSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <div className="gx-chat-sidenav-content">
        <Tabs className="gx-tabs-half" defaultActiveKey="1">
          <TabPane label="Support Tickets" tab="Support Tickets" key="1">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
              {supports.length === 0
                ? <div className="gx-p-5">No tickets yet</div>
                : (
                  <div className="gx-chat-user">
                    {tickets.map((support) => (
                      <TicketCell
                        key={support.id}
                        ticket={support}
                        selectedTicketId={selectedTicketId}
                        onSelectTicket={onSelectTicket}
                      />
                    ))}
                  </div>
                )}
            </CustomScrollbars>
          </TabPane>
          {/* <TabPane label="Support Tickets" tab="Closed ticket" key="2">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
              {supports.length === 0
                ? <div className="gx-p-5">No tickets yet</div>
                : (
                  <div className="gx-chat-user">
                    {tickets.map((support) => (
                      <TicketCell
                        key={support.id}
                        ticket={support}
                        selectedTicketId={selectedTicketId}
                        onSelectTicket={onSelectTicket}
                      />
                    ))}
                  </div>
                )}
            </CustomScrollbars>
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

SupportRequest.defaultProps = {
  selectedTicketId: null,
};
SupportRequest.propTypes = {
  supports: arrayOf(shape({})).isRequired,
  onSelectTicket: func.isRequired,
  selectedTicketId: string,
};

export default SupportRequest;
