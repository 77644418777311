import React, { useState, useContext, useEffect } from 'react';
import {
  Row, Col, Form, Input, Button, Avatar, Card, Upload, Select, message, notification, Tag,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  UPLOAD_FILE, CHANGE_PASSWORD, GET_SINGLE_USER, UPDATE_USER_INFO,
} from '../../graphql';
import PasswordModal from './PasswordModal';
import { AuthContext } from '../../appContext/AuthContext';
import { LOGIN } from '../../routes';
import ViewSpinner from '../../components/viewSpinner/ViewSpinner';
import './styles.less';

const { Item: FormItem } = Form;
const { Option } = Select;

const RoleTag = (props) => {
  // eslint-disable-next-line react/prop-types
  const { label, onClose } = props;
  return (
    <Tag
      color="green"
      onClose={onClose}
      style={{ marginBottom: '-2px' }}
    >
      {label}
    </Tag>
  );
};

const Profile = () => {
  const [changePassword, showChangePassword] = useState(false);
  const { userInfo: { id }, revokeAuth } = useContext(AuthContext);
  const { data: userPayload, loading: loadingUser } = useQuery(GET_SINGLE_USER, { variables: { id } });
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [mutateChangePassword, { loading: changePassLoading }] = useMutation(CHANGE_PASSWORD);
  const [mutateUpdateUser, { updateLoading }] = useMutation(UPDATE_USER_INFO);
  const [profile, setProfile] = useState(null);
  const [profileTeacher, setProfileTeacher] = useState(null);
  const [user, setUser] = useState(null);
  const { push } = useHistory();

  useEffect(() => {
    if (!loadingUser && userPayload) {
      const { User } = userPayload;
      setUser(User);
      setProfileTeacher(User.profileTeacher);
      setProfile(User.profileTeacher.profileImg);
    }
  }, [userPayload, loadingUser]);
  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    try {
      const { data: { createFile: { url } } } = await filesUpload({ variables: { file, folder: 'profileImg' } });
      await mutateUpdateUser({
        variables: {
          id,
          data: {
            profileTeacher: {
              update: { profileImg: { set: url } },
            },
          },
        },
      });
      setProfile(url);
      message.success('Profile picture updated successfully');
      onSuccess({ url });
    } catch (error) {
      onError(error);
      message.error('Error, could not update picture');
    }
  };
  const getCohortSubjects = (cohortId, userSubject) => {
    const subjects = userSubject.filter(({ subject: { cohortId: _cohortId } }) => _cohortId === cohortId)
      .map(({ subject: { name } }) => name);
    return subjects;
  };
  const handleFormSubmit = async (inputValue) => {
    const { oldPassword, newPassword } = inputValue;
    try {
      await mutateChangePassword({
        variables: {
          input: {
            newPassword,
            oldPassword,
            userId: id,
          },
        },
      });
      notification.success({ message: 'Password successfully changed', description: 'Please login with your new password' });
      revokeAuth();
      push(LOGIN);
    } catch (error) {
      message.error('Error, could not change password');
    }
  };
  return (
    <div className="profile-teacher">
      <PasswordModal
        handleCancel={() => showChangePassword(false)}
        loading={changePassLoading}
        visible={changePassword}
        handleFormSubmit={handleFormSubmit}
      />
      { !loadingUser && user && (
      <>
        <Card className="profile-card" title="Teacher Information">
          <Row>
            <Col md={10} xs={24}>
              <div className="photo-card">
                <div className="avatar-wrapper">
                  <Avatar src={profile} size="large" className="teacher-avatar" />
                </div>
                <Upload
                  customRequest={handleFileUpload}
                  accept="image/png,image/jpeg,image/jpg"
                  showUploadList={false}
                >
                  <Button
                    loading={uploadLoading || updateLoading}
                    className="upload-btn"
                    icon={<UploadOutlined />}
                  >
                    Change Picture
                  </Button>
                </Upload>
              </div>
            </Col>
            <Col md={14} xs={24}>
              <Form layout="vertical">
                <FormItem initialValue={user.firstName} label="First Name" name="firstName">
                  <Input disabled />
                </FormItem>
                <FormItem initialValue={user.lastName} label="Last Name" name="lastName">
                  <Input disabled />
                </FormItem>
                <FormItem initialValue={profileTeacher.phoneNumber || 'None'} label="Phone Number" name="phoneNumber">
                  <Input disabled />
                </FormItem>
                <FormItem initialValue={user.email} label="Email" name="email">
                  <Input disabled />
                </FormItem>
                <FormItem initialValue={profileTeacher.address || 'Not set'} label="Address" name="address">
                  <Input disabled />
                </FormItem>
                <FormItem
                  initialValue={moment(profileTeacher.doe).format('MMM Do YYYY')}
                  label="Date Employment"
                  name="doe"
                >
                  <Input disabled />
                </FormItem>
                <FormItem initialValue={[user.role]} label="Role" name="role">
                  <Select disabled tagRender={RoleTag} mode="multiple">
                    <Option key="1" value="INSTRUCTOR">INSTRUCTOR</Option>
                    <Option key="2" value="ADMIN">ADMIN</Option>
                  </Select>
                </FormItem>
              </Form>
              <Button onClick={() => showChangePassword(true)} className="change-pass-btn">Change Password</Button>
            </Col>
          </Row>
        </Card>
        <Card className="cohort-card" title="Teacher Cohort and Subjects">
          <Form layout="vertical">
            {user.UserCohort.map(({ cohort: { name, id: cohortId } }, idx) => (
              <Row key={cohortId} className="no-flex-column">
                <Col xs={24} sm={12}>
                  <FormItem initialValue={name} name={`cohort_${cohortId}`} label={idx === 0 ? 'Cohort' : ''}>
                    <Input disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem initialValue={getCohortSubjects(cohortId, user.UserSubject)} name={`subject_${cohortId}`} label={idx === 0 ? 'Subjects' : ''}>
                    <Select disabled mode="multiple">
                      <Option key="1" value="1">Maths</Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            ))}
          </Form>
        </Card>
      </>
      )}
      {loadingUser && <ViewSpinner />}
    </div>
  );
};

export default Profile;
