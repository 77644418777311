import React, { useState } from 'react';
import {
  Modal, Form, Select, Space, Button, message,
} from 'antd';
import { useMutation } from '@apollo/client';
import {
  func, bool, arrayOf, shape, string,
} from 'prop-types';
import { UPDATE_USER_INFO } from '../../../../../graphql';

const { Option } = Select;
const { Item: FormItem, useForm } = Form;
const AddCohortModal = ({
  closeModal, visible, cohorts, userId,
}) => {
  const [mutateUpdateUser, { loading: updateLoading }] = useMutation(UPDATE_USER_INFO);
  const [subject, setSubject] = useState(null);
  const [form] = useForm();
  const handleFormFinish = async (inputs) => {
    const { cohort, subjects } = inputs;
    try {
      await mutateUpdateUser({
        variables: {
          id: userId,
          data: {
            UserCohort: {
              create: { cohort: { connect: { id: cohort } } },
            },
            UserSubject: {
              create: subjects.map((id) => ({
                subject: { connect: { id } },
              })),
            },
          },
        },
      });
      message.success('Cohort added successfully');
      return closeModal(!visible);
    } catch (error) {
      message.error('Error, could not update user cohort');
    }
  };
  const handleValueChange = (field) => {
    if (field.cohort) {
      const cohort = cohorts.filter(({ id }) => id === field.cohort);
      if (cohort.length && cohort.length > 0) {
        const { Subject } = cohort[0];
        setSubject(Subject);
      }
    }
  };
  return (
    <Modal
      visible={visible}
      title="Add User's Cohort"
      onCancel={() => closeModal(!visible)}
      footer={null}
    >
      <Form onFinish={handleFormFinish} form={form} onValuesChange={handleValueChange} layout="vertical">
        <FormItem label="Cohort" name="cohort" rules={[{ required: true }]}>
          <Select placeholder="select cohort">
            {cohorts.map(({ id, name }) => (
              <Option key={id} value={id}>{name}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="Subject" name="subjects" rules={[{ required: true }]}>
          <Select disabled={!subject} placeholder="select subject" mode="multiple">
            {subject && subject.map(({ id, name }) => (
              <Option key={id} value={id}>{name}</Option>
            ))}
          </Select>
        </FormItem>
        <Space>
          <Button onClick={() => closeModal(!visible)}>Cancel</Button>
          <Button loading={updateLoading} type="primary" htmlType="submit">Add</Button>
        </Space>
      </Form>
    </Modal>
  );
};

AddCohortModal.propTypes = {
  closeModal: func.isRequired,
  visible: bool.isRequired,
  cohorts: arrayOf(shape({})).isRequired,
  userId: string.isRequired,
};

export default AddCohortModal;
