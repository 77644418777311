import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { UiContext } from '../../../../appContext';
import { SET_SIDEBAR_MODE } from '../../../../constants/ActionTypes';
import { GET_TOPIC } from '../../../../graphql';
import Reader from './Reader';
import ViewSpinner from '../../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const Learn = () => {
  const { uiDispatch } = useContext(UiContext);
  const { location: { search } } = useHistory();
  const topicId = new URLSearchParams(search).get('t');
  const { data: topicPayload, loading } = useQuery(GET_TOPIC, { variables: { where: { id: topicId } } });
  const [topicDetail, setTopicDetails] = useState(null);
  useEffect(() => {
    if (!loading && topicPayload) {
      const { topic } = topicPayload;
      setTopicDetails(topic);
    }
  }, [topicPayload, loading]);

  useEffect(() => {
    // @todo: use here to set update completed
    uiDispatch({ type: SET_SIDEBAR_MODE, payload: 'LEARN' });
  }, [uiDispatch]);

  return (
    <div>
      {!loading && topicDetail && <Reader topic={topicDetail} />}
      {loading && <ViewSpinner />}
    </div>
  );
};

export default Learn;
