import { createContext } from 'react';
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
} from '../constants/ThemeSetting';
import {
  TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, SHOW_MESSAGE, HIDE_MESSAGE, SET_SIDEBAR_MODE,
} from '../constants/ActionTypes';

export const UiContext = createContext({});
export const UiProvider = UiContext.Provider;
export const UiConsumer = UiContext.Consumer;

export const initialSettings = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: THEME_COLOR,
  isDirectionRTL: false,
  width: window.innerWidth,
  SbMode: 'NORMAL',
  error: '',
  loading: false,
  message: '',
  navCollapsed: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

export const uiReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    }
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };
    case SHOW_MESSAGE: {
      return {
        ...state, error: '', message: action.payload, loading: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state, loading: false, error: '', message: '',
      };
    }
    case SET_SIDEBAR_MODE: {
      return {
        ...state, SbMode: action.payload,
      };
    }
    default:
      return state;
  }
};

// ------ Actions -----
export function updateWindowsWidth(width) {
  return { type: WINDOW_WIDTH, width };
}
export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export const showMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});
