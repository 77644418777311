import { gql } from '@apollo/client';

export const GET_SUPPORTS = gql`
	query supports($where: SupportWhereInput, $orderBy: [SupportOrderByWithRelationInput!]) {
		supports(where: $where, orderBy: $orderBy) {
			id
			supportId
			title
			replyCount
			description
			isClosed
			createdAt
			user {
				firstName
				lastName
				profileStudent {
					id
					profileImg
				}
			}
		}
	}
`;

export const GET_USER_SUPPORTS = gql`
	query getUserSupports($where: SupportWhereInput, $orderBy: [SupportOrderByWithRelationInput!], $take: Int) {
		userSupports(where: $where, orderBy: $orderBy, take: $take) {
			id
			supportId
			title
			replyCount
			description
			isClosed
			createdAt
			user {
				firstName
				lastName
				profileStudent {
					id
					profileImg
				}
			}
		}
	}
`;

export const GET_SUPPORT_WITH_REPLIES = gql`
	query support($where: SupportWhereUniqueInput!) {
		support(where: $where) {
			id
			title
			description
			isClosed
			createdAt
			user {
				id
				firstName
				lastName
				profileStudent {
					profileImg
				}
			}
			replies{
				id
				message
				createdAt
				user {
					id
					firstName
					lastName
					profileTeacher {
						id
          	profileImg
        	}
				}
			}
		}
	}
`;

export const GET_SUPPORT_REPLY = gql`
	query supportReplies($where: SupportReplyWhereInput) {
		supportReplies(where: $where) {
			id
			message
			createdAt
			supportId
			user {
				id
				firstName
				lastName
				profileStudent {
					id
					profileImg
				}
				profileTeacher {
					id
					profileImg
				}
			}
			Support {
				id
				title
				description
    	}
		}
	}
`;

export const GET_MESSAGING = gql`
	query messaging($where: MessagingWhereUniqueInput!){
		messaging(where: $where){
			id
			type
			title
			body
			recipiantCount
			createdAt
			recipiant {
				id
				firstName
				lastName
				accountType
				email
			}
		}
	}
`;

export const GET_MESSAGES = gql`
	query messages($where: MessagingWhereInput, $orderBy: [MessagingOrderByInput!]){
		messages(where: $where, orderBy: $orderBy){
			id
			type
			title
			body
			recipiantCount
			createdAt
			recipiant {
				id
				firstName
				lastName
			}
		}
	}
`;
