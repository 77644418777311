/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useState } from 'react';
import {
  Form, Col, Select, Button,
} from 'antd';
import { arrayOf, shape } from 'prop-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem, List: FormList } = Form;
const { Option } = Select;
const CohortField = ({ availableCohorts }) => {
  const [cohortCache, setCohortCache] = useState({});

  const updateCache = (val, index) => {
    setCohortCache({ ...cohortCache, [index]: val });
  };
  const onRemove = (remove, fields) => {
    const lastItem = fields.length - 1;
    if (lastItem === -1) return remove(0);
    setCohortCache({ ...cohortCache, [lastItem]: undefined });
    return remove(lastItem);
  };
  const getCohortSubjects = (cacheKey) => {
    const cohortId = cohortCache[cacheKey];
    if (cohortId) {
      const selectCohort = availableCohorts.filter(({ id }) => id === cohortId)[0];
      if (selectCohort && selectCohort.Subject) {
        const { Subject } = selectCohort;
        const subjects = [...Subject];
        if (subjects.length > 0) subjects.push({ name: 'All', id: 'ALL' });
        return subjects.map(({ name, id }) => (
          <Option value={id} key={id}>{name}</Option>
        ));
      }
      return <></>;
    }
    return <></>;
  };
  return (
    <>
      <FormList name="userCohort">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Fragment key={index}>
                <Col sm={24} md={12}>
                  <FormItem {...field} fieldKey={[field.fieldKey, 'cohort']} name={[field.name, 'cohort']} label="Cohort">
                    <Select onSelect={((val) => updateCache(val, index))} placeholder="Select cohort">
                      {availableCohorts.map(({ name, id, ...rest }) => (
                        <Option disabled={rest.selected} value={id} key={id}>{name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem {...field} fieldKey={[field.fieldKey, 'subject']} name={[field.name, 'subject']} label="Subject">
                    <Select mode="multiple" disabled={!cohortCache[index]} placeholder="Select subject">
                      {getCohortSubjects(index)}
                    </Select>
                  </FormItem>
                </Col>
              </Fragment>
            ))}
            <Col sm={24} md={24}>
              <FormItem>
                <Button type="dashed" onClick={() => add()}>
                  <PlusOutlined />
                  {' '}
                  Add Cohort
                </Button>
                <Button disabled={fields.length < 1} type="dashed" onClick={() => onRemove(remove, fields)}>
                  <MinusOutlined />
                  {' '}
                  Remove Cohort
                </Button>
              </FormItem>
            </Col>
          </>
        )}
      </FormList>
    </>
  );
};

CohortField.propTypes = {
  availableCohorts: arrayOf(shape({})).isRequired,
};
export default CohortField;
