import { getStates, getCountries } from 'country-state-picker';

export const makeStateOption = (countryCode) => {
  const states = getStates(countryCode).map((key, idx) => {
    const value = key.split(' ')[0] || idx;
    return {
      label: value, id: `${value}${idx}`, value, key: `${value}${idx}`,
    };
  });
  return states;
};

export const countriesOptions = getCountries().map(({ name }) => ({ label: name, id: name, value: name }));

// selects users subject from given cohortId
export const selectSubjectsByCohortId = (searchingCohortId, userSubject) => {
  let matchingSubjects = [];
  userSubject.forEach(({ subject: { cohortId, id, name }, id: creationId }) => {
    if (searchingCohortId === cohortId) {
      matchingSubjects = [...matchingSubjects, { name, id, creationId }];
    }
  });
  return matchingSubjects;
};
