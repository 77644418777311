import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToWithProps = ({ change }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const view = document.querySelector('.gx-main-content-wrapper');
    if (view) {
      view.scrollIntoView();
    }
  }, [change]);

  return null;
};
export default () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const view = document.querySelector('.gx-main-content-wrapper');
    if (view) {
      view.scrollIntoView();
    }
  }, [pathname]);

  return null;
};
