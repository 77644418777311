import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { Button } from 'antd';
import {
  sortBy, truncate, find, findIndex,
} from 'lodash';
import { MdDoneAll } from 'react-icons/md';
import { ArrowLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import CustomScrollbars from '../../../util/CustomScrollbars';
import { LearnContext, learnActionTypes } from '../../../appContext/learnContext';
import { GET_SUBJECT } from '../../../graphql';
import { TAB_SIZE } from '../../../constants/ThemeSetting';
import { UiContext, toggleCollapsedSideNav } from '../../../appContext';
import './styles.less';

const LearnContent = () => {
  const { location: { search, pathname }, push } = useHistory();
  const { uiState: { width }, uiDispatch } = useContext(UiContext);
  const { learnDispatch } = useContext(LearnContext);
  const searchParams = new URLSearchParams(search);
  const subjectId = searchParams.get('subjectId');
  const topicId = searchParams.get('t');
  const { data: subjectPayload, loading } = useQuery(GET_SUBJECT, {
    variables: { where: { id: subjectId } }, fetchPolicy: 'network-only',
  });
  const [topics, setTopics] = useState([]);
  const [initialTopicId, setInitialTopicId] = useState('');

  useEffect(() => {
    if (!loading && subjectPayload) {
      const { subject } = subjectPayload;
      const filteredTopics = subject.Topic.filter(({ status }) => status === 'PUBLISHED');
      const sortedTopics = sortBy(filteredTopics, ['topicNo']);
      const initTopicValue = sortedTopics.length > 0 ? sortedTopics[0].id : 'EMPTY';
      setInitialTopicId(initTopicValue);
      setTopics(sortedTopics);
      learnDispatch({ type: learnActionTypes.SET_SUBJECT, payload: { ...subject, Topic: sortedTopics } });
    }
  }, [loading, subjectPayload, learnDispatch]);

  useEffect(() => {
    if (!loading && topics) {
      if (!topicId) {
        const topic = find(topics, ['id', initialTopicId]);
        learnDispatch({
          type: learnActionTypes.SET_SELECTED_TOPIC,
          payload: {
            title: topic ? topic.title : 'None',
            index: 0,
          },
        });
        push(`${pathname}?subjectId=${subjectId}&t=${initialTopicId}`);
      } else {
        const index = findIndex(topics, ['id', topicId]);
        const topic = topics[index];
        learnDispatch({
          type: learnActionTypes.SET_SELECTED_TOPIC,
          payload: {
            title: topic ? topic.title : 'None',
            index,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [topicId, loading, topics]);
  const handleGoBack = () => {
    const paths = pathname.split('/');
    const url = paths.splice(0, 4).join('/');
    if (width < TAB_SIZE) {
      // this is the drawer
      uiDispatch(toggleCollapsedSideNav(false));
    }
    push(url);
  };
  const handleTopicClick = (id, index) => {
    const topic = find(topics, ['id', id]);
    learnDispatch({
      type: learnActionTypes.SET_SELECTED_TOPIC,
      payload: {
        title: topic ? topic.title : 'None',
        index,
      },
    });
    if (width < TAB_SIZE) {
      uiDispatch(toggleCollapsedSideNav(false));
    }
  };
  return (
    <div className="sb-learn-topics">
      <div className="sb-header">
        <Button onClick={handleGoBack} type="text" size="large">
          <ArrowLeftOutlined />
          Back
        </Button>
      </div>
      <CustomScrollbars className="scroll-height">
        {!loading && topics && (
          <>
            {topics.map(({ id, title }, idx) => (
              <Link key={id} onClick={() => handleTopicClick(id, idx)} to={`${pathname}?subjectId=${subjectId}&t=${id}`}>
                <div className={id === topicId ? 'topic active' : 'topic'}>
                  {idx === 0 ? <MdDoneAll color="#77CABD" size="20" /> : <CaretRightOutlined />}
                  <h3>{truncate(`${idx + 1}. ${title}`, { length: 30 })}</h3>
                </div>
              </Link>
            ))}
          </>
        )}
      </CustomScrollbars>
    </div>
  );
};

export default LearnContent;
