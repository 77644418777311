import React from 'react';
import {
  Modal, Button, Form, Input,
} from 'antd';
import { func, bool } from 'prop-types';

const { Item: FormItem, useForm } = Form;
const PasswordModal = ({
  handleCancel, visible, handleFormSubmit, loading,
}) => {
  const [form] = useForm();
  return (
    <Modal
      visible={visible}
      title="Change your Password"
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} name="new_password" layout="vertical" onFinish={handleFormSubmit}>
        <FormItem name="oldPassword" label="Current Password" rules={[{ required: true, message: 'Provide your current password' }]}>
          <Input.Password placeholder="Current Password" />
        </FormItem>
        <FormItem name="newPassword" label="New Password" rules={[{ required: true, message: 'Provide your new password' }]}>
          <Input.Password placeholder="New Password" />
        </FormItem>
        <FormItem
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          rules={[{ required: true, message: 'new password should match confirm password' }, ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('password mismatch!');
            },
          })]}
        >
          <Input.Password placeholder="Confirm new password" />
        </FormItem>
        <FormItem className="gx-mb-0">
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Complete
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

PasswordModal.propTypes = {
  handleCancel: func.isRequired,
  visible: bool.isRequired,
  handleFormSubmit: func.isRequired,
  loading: bool.isRequired,
};

export default PasswordModal;
