import React, { useEffect, useState } from 'react';
import {
  Form, Button, Col, Row, Input, Select, DatePicker, Checkbox, Upload, message, Tag,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { find, differenceBy } from 'lodash';
import {
  func, bool, shape, arrayOf,
} from 'prop-types';
import { UPLOAD_FILES, UPDATE_ACTIVITY } from '../../../graphql';
import Editor from '../../../components/editor';

const { Option } = Select;
const { Item: FormItem } = Form;

const EditActivity = ({
  cohort, setLoading, cohortLoading, closeModal, form, initValue,
}) => {
  const [instruction, setInstruction] = useState(initValue.instruction);
  const [updateActivity] = useMutation(UPDATE_ACTIVITY);
  const [filesUpload] = useMutation(UPLOAD_FILES);
  const [subjects, setSubjects] = useState([]);
  const [fileDeleteCache, setFileDeleteCache] = useState([]);

  const handleFormSubmit = async (inputs) => {
    const {
      attachment, type, dueDate, title, hasTextInput,
    } = inputs;
    const inputSubjectId = inputs.subjects.map((id) => ({ id }));
    const existingSubjectId = initValue.subjects.map(({ id }) => ({ id }));
    const subjectDiff = differenceBy(existingSubjectId, inputSubjectId, 'id');
    const editActivity = {
      title: { set: title },
      type: { set: type },
      instruction: { set: instruction },
      hasTextInput: { set: hasTextInput },
      dueDate: { set: moment(dueDate).toString() },
      cohort: { connect: { id: inputs.cohort } },
      subjects: { connect: inputSubjectId },
    };
    if (subjectDiff.length > 0) {
      editActivity.subjects.disconnect = subjectDiff;
    }
    try {
      setLoading(true);
      if (attachment && attachment.fileList) {
        const { fileList } = attachment;
        let fileArr = [];
        let existingFiles = [];
        fileList.forEach((file) => {
          if (file.originFileObj) {
            const actualFile = file.originFileObj;
            fileArr = [...fileArr, actualFile];
          } else {
            existingFiles = [...existingFiles, { id: file.uid }];
          }
        });
        const { data: { createFiles } } = await filesUpload({ variables: { files: fileArr, folder: 'activity' } });
        const filesMap = createFiles.map(({ id }) => ({ id }));
        editActivity.attachments = {
          connect: [...filesMap, ...existingFiles],
        };
      }
      if (fileDeleteCache.length > 0) {
        if (editActivity.attachments) {
          editActivity.attachments.disconnect = fileDeleteCache;
        } else {
          editActivity.attachments = {
            disconnect: fileDeleteCache,
          };
        }
      }
      await updateActivity({
        variables: { data: editActivity, where: { id: initValue.id } },
      });
      setLoading(false);
      form.resetFields();
      message.success('Activity updated successfully');
      return closeModal();
    } catch (err) {
      message.error('Error updating activity');
    }
  };
  const getSubjects = (cohortId) => {
    const withSubject = find(cohort, (c) => c.id === cohortId);
    if (!withSubject || withSubject.Subject.length < 1) return [];
    return withSubject.Subject;
  };
  const makeDefaultFileList = () => {
    const { attachments } = initValue;
    const fileList = attachments.map(({
      id, url, filename,
    }) => ({
      uid: id,
      name: `${filename.slice(11) || 'File'}`,
      status: 'done',
      url,
    }));
    return fileList;
  };

  useEffect(() => {
    const initSubjectsOption = getSubjects(initValue.cohort.id);
    setSubjects(initSubjectsOption);
    // eslint-disable-next-line
  }, [initValue]);

  return (
    <Form onFinish={handleFormSubmit} form={form} name="add-activity-form" layout="vertical" hideRequiredMark>
      <Row style={{ flexDirection: 'row' }}>
        <Col span={24} className="gx-mb-3">
          <Tag color="green" style={{ padding: '0.3rem 0.5rem' }}>
            <h5 className="gx-m-0">
              Created By:
              {' '}
              {initValue.user ? `${initValue.user.firstName} ${initValue.user.lastName}` : 'User Unavailable'}
            </h5>
          </Tag>
        </Col>
        <Col span={24}>
          <FormItem initialValue={initValue.title} name="title" label="Activity Title" rules={[{ required: true, message: 'Provide activity title' }]}>
            <Input placeholder="activity title" />
          </FormItem>
        </Col>
        <Col xm={24} md={12}>
          <FormItem initialValue={moment(initValue.dueDate)} name="dueDate" label="Due Date" rules={[{ required: true, message: 'Provide due date' }]}>
            <DatePicker className="gx-w-100" placeholder="select deu date" />
          </FormItem>
        </Col>
        <Col xm={24} md={12}>
          <FormItem initialValue={initValue.type} name="type" label="Activity Type">
            <Select>
              <Option key="ONLINE_CLASS">Online Class</Option>
              <Option key="ASSIGNMENT">Assignment</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem initialValue={initValue.embedLink} name="embedLink" label="Form Embed Link">
            <Input placeholder="e.g <iframe src='https://docs.google.com/forms../>" />
          </FormItem>
        </Col>
        <Col span={24}>
          <Editor overrideConfig={{ toolbarSticky: false }} content={instruction} setContent={setInstruction} />
        </Col>
        <Col className="gx-mt-4" xm={24} md={12}>
          <FormItem initialValue={initValue.cohort.id} name="cohort" label="Cohort" rules={[{ required: true, message: 'Provide cohort' }]}>
            <Select disabled loading={cohortLoading} placeholder="Select cohort">
              {cohort.map(({ id, name }) => (
                <Option value={id} key={id}>{name}</Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col className="gx-mt-4" xm={24} md={12}>
          <FormItem initialValue={initValue.subjects.map(({ id }) => id)} name="subjects" label="Subjects" rules={[{ required: true, message: 'Provide activity subjects' }]}>
            <Select disabled mode="multiple">
              {subjects.map(({ id, name }) => (
                <Option key={id} value={id}>{name}</Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem initialValue={initValue.hasTextInput} valuePropName="checked" name="hasTextInput">
            <Checkbox>Show text input?</Checkbox>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label="Attachment"
            valuePropName="file"
            name="attachment"
          >
            <Upload
              listType="picture"
              onRemove={(file) => setFileDeleteCache([...fileDeleteCache, { id: file.uid }])}
              beforeUpload={() => false}
              defaultFileList={makeDefaultFileList()}
            >
              <Button icon={<UploadOutlined />}>Attach files</Button>
            </Upload>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

EditActivity.propTypes = {
  cohort: arrayOf(shape({})).isRequired,
  setLoading: func.isRequired,
  cohortLoading: bool.isRequired,
  closeModal: func.isRequired,
  form: shape({}).isRequired,
  initValue: shape({}).isRequired,
};

export default EditActivity;
