import React from 'react';
import { Button } from 'antd';
import {
  func, shape, arrayOf,
} from 'prop-types';
import Replies from './replies';

const ReplyContainer = ({
  replies, onReply, selectedTicket, setDrawerState, closeSupport,
}) => (
  <div className="gx-chat-box">
    {selectedTicket === null
      ? (
        <div className="gx-comment-box">
          <div className="gx-fs-80"><i className="icon icon-chat gx-text-muted" /></div>
          <h1 className="gx-text-muted">Selected a ticket</h1>
          <Button
            className="gx-d-block gx-d-lg-none"
            type="primary"
            onClick={() => setDrawerState(true)}
          >
            Select a ticket
          </Button>
        </div>
      )
      : (
        <Replies
          closeSupport={closeSupport}
          setDrawerState={setDrawerState}
          replies={replies}
          selectedTicket={selectedTicket}
          onReply={onReply}
        />
      )}
  </div>
);

ReplyContainer.defaultProps = {
  selectedTicket: null,
};

ReplyContainer.propTypes = {
  replies: arrayOf(shape({})).isRequired,
  onReply: func.isRequired,
  selectedTicket: shape({}),
  setDrawerState: func.isRequired,
  closeSupport: func.isRequired,
};

export default ReplyContainer;
