import React from 'react';
import { Tag } from 'antd';

export default [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Account Type',
    dataIndex: 'accountType',
    key: 'accountType',
    render: (text) => {
      if (text === 'STUDENT') return <Tag color="#2db7f5">{text}</Tag>;
      if (text === 'ADMIN') return <Tag color="#87d068">{text}</Tag>;
      if (text === 'TEACHER') return <Tag color="#108ee9">{text}</Tag>;
    },
  },
  {
    title: 'Cohorts',
    dataIndex: 'UserCohort',
    key: 'cohorts',
    render: (userCohort) => (
      <span>
        {userCohort.length > 0 ? (
          userCohort.map(({ id, cohort: { name } }) => (
            <Tag key={id}>{name}</Tag>
          ))
        ) : <span>None</span>}
      </span>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
];
