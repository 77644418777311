import { gql } from '@apollo/client';

export const ADD_SUBJECT = gql`
	mutation createSubject($input: SubjectCreateInput!) {
		createSubject(input: $input) {
			id
			name
		}
	}
`;

export const DELETE_SUBJECT = gql`
	mutation deleteSubject($where: SubjectWhereUniqueInput!) {
		deleteSubject(where: $where) {
			id
		}
	}
`;

export const UPDATE_SUBJECT = gql`
	mutation updateSubject($where: SubjectWhereUniqueInput!, $data: SubjectUpdateInput!) {
		updateSubject(where: $where, data: $data) {
			id
			name
		}
	}
`;
