import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { arrayOf, shape } from 'prop-types';
import { TeacherSidebar as Sidebar } from '../../components/Sidebar';
import TopBar from '../../components/topBar';
import { footerText } from '../../util/config';

const { Content, Footer } = Layout;

const InstructorDashboard = ({ routes }) => (
  <Layout className="gx-app-layout">
    <Sidebar routes={routes} />
    <Layout>
      <TopBar />
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          <Switch>
            {routes.map(({
              exact, path, view, key, hasSubRoute, sub,
            }) => {
              if (hasSubRoute) {
                return sub.map(({ path: subPath, view: subView }) => (
                  <Route path={subPath} component={subView} key={key} />
                ));
              }
              return <Route exact={exact} path={path} component={view} key={key} />;
            })}
          </Switch>
        </div>
        <Footer>
          <div className="gx-layout-footer-content">{footerText}</div>
        </Footer>
      </Content>
    </Layout>
  </Layout>
);

InstructorDashboard.propTypes = {
  routes: arrayOf(shape({})).isRequired,
};

export default InstructorDashboard;
