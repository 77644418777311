import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Card, Collapse, message, Empty,
} from 'antd';
import PanelContent from './PanelContent';
import Header from './Header';
import { AuthContext } from '../../../appContext';
import {
  GET_ALL_COHORT, GET_USER_COHORT, GET_USER_SUBJECT, GET_SUBJECTS,
} from '../../../graphql';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const { Panel } = Collapse;
const Home = () => {
  const { userInfo: { role, id: userId } } = useContext(AuthContext);
  const query = role === 'ADMIN' ? GET_ALL_COHORT : GET_USER_COHORT;
  const querySubject = role === 'ADMIN' ? GET_SUBJECTS : GET_USER_SUBJECT;
  const params = role === 'ADMIN' ? {} : { variables: { where: { userId: { equals: userId } } } };
  const { data: cohortPayload, loading: cohortLoading, error: cohortError } = useQuery(query, params);
  const { data: subjectPayload, loading: subjectLoading, error: subjectError } = useQuery(querySubject, params);
  const [cohorts, setCohorts] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [cohortWithSubject, setCohortWithSubject] = useState([]);

  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      let cohortArr = [];
      if (role === 'ADMIN') {
        cohortArr = cohortPayload.getAllCohort;
      } else {
        const userCohort = cohortPayload.userCohorts;
        cohortArr = userCohort.map(({ cohort }) => cohort);
      }
      setCohorts(cohortArr);
    }
  }, [cohortPayload, cohortLoading, role]);
  useEffect(() => {
    if (!subjectLoading && subjectPayload) {
      let subjectArr = [];
      if (role === 'ADMIN') {
        subjectArr = subjectPayload.subjects;
      } else {
        const userSubject = subjectPayload.userSubjects;
        subjectArr = userSubject.map(({ subject }) => subject);
      }
      setSubjects(subjectArr);
    }
  }, [subjectPayload, subjectLoading, role]);

  useEffect(() => {
    if (subjects && cohorts) {
      let cohortWithSubjectArr = [];
      cohorts.forEach((cohort) => {
        const availSubject = subjects.filter(({ cohortId }) => cohortId === cohort.id);
        cohortWithSubjectArr = [...cohortWithSubjectArr, { ...cohort, subjects: availSubject }];
      });
      setCohortWithSubject(cohortWithSubjectArr);
    }
  }, [subjects, cohorts]);

  if (cohortError || subjectError) {
    if (cohortError.message && cohortError.message.includes('Failed to fetch')) {
      message.error('Network error, check connection');
    } else {
      message.error('Error, something went wrong');
    }
  }
  return (
    <div>
      <Card className="gx-card">
        <h4>Your Cohorts</h4>
        <p className="gx-m-0">Create and update learning content here</p>
      </Card>
      {cohortWithSubject.length > 0 && (
      <Collapse defaultActiveKey="0" className="gx-mb-5" accordion expandIconPosition="right">
        {cohortWithSubject.map(({ name, subjects: subs }, idx) => (
          <Panel className="site-collapse-custom-panel" header={<Header subjectNumber={subs.length} cohortName={name} />} key={idx}>
            <PanelContent subjects={subs} />
          </Panel>
        ))}
      </Collapse>
      )}
      {!(cohortLoading || subjectLoading) && cohortWithSubject.length === 0 && <Empty description="You don't have a cohort" />}
      {(cohortLoading || subjectLoading) && <ViewSpinner /> }
    </div>
  );
};

export default Home;
