import React, { useEffect } from 'react';
import {
  Form, Input, Button, notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useHistory, Redirect } from 'react-router-dom';
import { COMPLETE_FORGET_PASSWORD } from '../../graphql';
import DecoCenter from '../../components/DecoCenter';
import { LOGIN } from '../../routes';

const { Item: FormItem } = Form;
const CompleteForgetPassword = () => {
  const [completeForgetPassword, { loading, data }] = useMutation(COMPLETE_FORGET_PASSWORD);
  const { location: { search }, push } = useHistory();
  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('rep');
  const handleFormSubmit = async (formInput) => {
    const { newPassword } = formInput;
    const input = { newPassword, email, token };
    try {
      await completeForgetPassword({ variables: { input } });
    } catch (error) {
      if (error.message && error.message.includes('Received status code 400')) {
        notification.error({ message: 'Action not allowed', description: 'cannot create new password, invalid request' });
      } else {
        notification.error({ message: 'Ops! Something went wrong' });
      }
    }
  };
  useEffect(() => {
    if (!loading && data) {
      notification.success({ message: 'Password changed successfully', description: 'Please proceed to login using your new password' });
      push(LOGIN);
    }
  }, [loading, data, push]);

  if (!token || !email) {
    return <Redirect to={LOGIN} />;
  }
  return (
    <DecoCenter>
      <Form layout="vertical" requiredMark={false} onFinish={handleFormSubmit} name="forget_password" className="form-wrapper">
        <div className="gx-login-text">
          <h3 className="gx-m-4 gx-fs-xl">New Password</h3>
        </div>
        <FormItem name="newPassword" className="input-field" label="New password" rules={[{ required: true, min: 6, message: 'Please provide a valid password' }]}>
          <Input.Password type="password" placeholder="choose a new password" />
        </FormItem>
        <FormItem
          name="comfirmPassword"
          className="input-field"
          label="Confirm password"
          dependencies={['newPassword']}
          rules={[{ required: true, message: 'new password should match confirm password' }, ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('password mismatch!');
            },
          })]}
        >
          <Input.Password placeholder="confirm password" />
        </FormItem>
        <Form.Item className="input-field">
          <Button loading={loading} htmlType="submit" size="large" type="primary" className="gx-w-100">
            {loading ? 'Loading' : 'Confirm'}
          </Button>
        </Form.Item>
      </Form>
    </DecoCenter>
  );
};

export default CompleteForgetPassword;
