/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { Tag, Button, Popconfirm } from 'antd';

export default (handleViewClicked, handleDeleteClick) => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => (<span>{moment(text).format('Do MMM YYYY')}</span>),
  },
  {
    title: 'Student Name',
    key: 'name',
    render: (_, { studentActivity: { user: { lastName, firstName } } }) => (<span>{`${firstName} ${lastName}`}</span>),
  },
  {
    title: 'A/Number',
    key: 'admission_number',
    render: (_, { studentActivity: { user: { profileStudent: { admissionNumber } } } }) => (<Tag color="purple">{`${admissionNumber}`}</Tag>),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (text) => (text === 'PENDING' ? <Tag color="orange">{`${text}`}</Tag> : text === 'ACCEPTED' ? <Tag color="green">{`${text}`}</Tag> : <Tag color="red">{`${text}`}</Tag>),
  },
  {
    title: 'Activity',
    key: 'activity',
    dataIndex: 'activity',
    render: ({ title }) => (<span>{title}</span>),
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    render: (text) => (text > 0 ? <Tag color="#2db7f5">{`${text}%`}</Tag> : <Tag>{`${text}%`}</Tag>),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span>
        <Button onClick={() => handleViewClicked(record.id)} type="primary" size="small">View</Button>
        <Popconfirm
          title="Are you sure to delete this submission?"
          okText="Yes"
          placement="left"
          cancelText="Cancel"
          onConfirm={() => handleDeleteClick(record.id)}
        >
          <Button danger size="small">
            Delete
          </Button>
        </Popconfirm>
      </span>
    ),
  },
];
