/* eslint-disable no-nested-ternary */
import React from 'react';
import { useParams } from 'react-router-dom';
import { INIT_FORGET_PASSWORD, COMPLETE_FORGET_PASSWORD } from '../../constants/accountAction';
import ForgetPassword from './ForgetPassword';
import CompleteForgetPassword from './CompleteForget';
import './styles.less';

const Account = () => {
  const { action } = useParams();
  return (
    <div className="account-container">
      {action === INIT_FORGET_PASSWORD ? (
        <ForgetPassword />
      ) : action === COMPLETE_FORGET_PASSWORD ? (
        <CompleteForgetPassword />
      ) : (
        <div>
          <h3>Invalid action</h3>
        </div>
      )}
    </div>
  );
};

export default Account;
