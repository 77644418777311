import React from 'react';
import { Table, Empty, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  shape, arrayOf, bool, string,
} from 'prop-types';
import columns, { instructorColumns } from './columns';
import { ACTIVITIES } from '../../../routes';

const RecentActivities = ({
  data, loading, title, columnType,
}) => {
  const { push } = useHistory();
  return (
    <Card title={title}>
      {(!data || data.length === 0) && !loading ? (<Empty description="No activities yet" />) : (
        <Table
          loading={loading}
          rowKey="id"
          dataSource={data}
          columns={columnType === 'ADMIN' ? columns : instructorColumns}
          onRow={({ id }) => ({
            onClick: () => {
              push(`${ACTIVITIES}?detail=${id}`);
            },
          })}
        />
      )}
    </Card>
  );
};

RecentActivities.defaultProps = {
  title: 'Recently Created Activities',
  columnType: 'ADMIN',
};

RecentActivities.propTypes = {
  data: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  title: string,
  columnType: string,
};

export default RecentActivities;
