import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { bool, func, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { LearnContext } from '../../appContext/learnContext';
import { NAV_STYLE_MINI_SIDEBAR } from '../../constants/ThemeSetting';

import './styles.less';

const { Item: BreadcrumbItem } = Breadcrumb;
const LearnHeader = ({ isMobile, navStyle, handleToggleNav }) => {
  const { location: { pathname } } = useHistory();
  const { learnState: { subject, selectedTopic } } = useContext(LearnContext);
  const isLearn = pathname.includes('learn');
  const navs = pathname.split('/').filter((path) => path.length < 20 && path.length > 1);

  if (!isLearn) {
    return (
      <>
        {!isMobile ? (
          <Breadcrumb>
            {navs.map((pathName, idx) => (
              <BreadcrumbItem key={idx}>{upperFirst(pathName)}</BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : (
          <i
            className={`gx-icon-btn toggler icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'}`}
            onClick={handleToggleNav}
            role="presentation"
          />
        )}
      </>
    );
  }
  return (
    <div className="learn-header">
      {isMobile && (
      <i
        className={`gx-icon-btn toggler icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'}`}
        onClick={handleToggleNav}
        role="presentation"
      />
      )}
      <div className="subject-info">
        {!isMobile && <h5>{subject && subject.name}</h5>}
        <p className="ic-ft-dosis">
          <span>
            {`Lesson ${selectedTopic.index + 1}:`}
          </span>
          {' '}
          {selectedTopic.title}
        </p>
      </div>
    </div>
  );
};

LearnHeader.propTypes = {
  isMobile: bool.isRequired,
  navStyle: string.isRequired,
  handleToggleNav: func.isRequired,
};

export default LearnHeader;
