export default {
  readonly: false,
  minHeight: 400,
  style: { font: '16px Arial' },
  buttons: 'bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize,print,preview,find',
  //   uploader: {
  //     insertImageAsBase64URI: true,
  //   },
  // events: {
  //   focus: () => console.log('focuses'),
  //   afterInit: () => console.log('INTIED'),
  // },
};
