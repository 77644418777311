import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
	query getTopics($where: TopicWhereInput) {
		topics(where: $where) {
			id
			title
			updatedAt
			createdAt
		}
	}
`;

export const GET_TOPIC = gql`
	query getTopic($where: TopicWhereUniqueInput!) {
		topic(where: $where) {
			id
			title
			topicNo
			video
			status
			content
			uploads{
				id
				url 
				filename
			}
			user {
				id
			}
			createdAt
		}
	}
`;
