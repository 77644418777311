import { STUDENT_HOME, STUDENT_HOME_SUBJECT, STUDENT_HOME_SUBJECT_LEARN } from '../../../routes';
import Landing from './landing';
import Subjects from './subjects';
import Learn from './learn';

export default [
  {
    id: 20, title: 'Home', exact: true, path: STUDENT_HOME, component: Landing,
  },
  {
    id: 22, title: 'Subject', exact: true, path: STUDENT_HOME_SUBJECT, component: Subjects,
  },
  {
    id: 23, title: 'Learn', exact: true, path: STUDENT_HOME_SUBJECT_LEARN, component: Learn,
  },
];
