import React, { useContext } from 'react';
import { Menu } from 'antd';
import { arrayOf, any } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import { UiContext, AuthContext, toggleCollapsedSideNav } from '../../../appContext';
import CustomScrollbars from '../../../util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import { NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../../constants/ThemeSetting';
import './styles.less';

const { Item } = Menu;

const SidebarContent = ({ routes }) => {
  const { uiState: { navStyle, width }, uiDispatch } = useContext(UiContext);
  const { revokeAuth } = useContext(AuthContext);
  const { location: { pathname } } = useHistory();
  const getSelected = () => {
    if (pathname.includes('cohort') || pathname.split('/')[3] === 'home') {
      return '1';
    }
    if (pathname.includes('activities')) {
      return '2';
    }
    if (pathname.includes('support')) {
      return '3';
    }
    if (pathname.includes('profile')) {
      return '4';
    }
  };
  const selectedKey = getSelected();
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu selectedKeys={[selectedKey]} theme="dark" mode="inline">
            {routes.map(({
              icon, title, path, key,
            }) => (
              <Item
                className={navStyle === NAV_STYLE_MINI_SIDEBAR ? 'ic-nav-item-student mini' : 'ic-nav-item-student'}
                key={key}
              >
                <Link
                  to={path}
                  onClick={width < TAB_SIZE ? () => uiDispatch(toggleCollapsedSideNav(false)) : null}
                >
                  <div className="link-wrapper">
                    <span className="ic-bar" />
                    {icon}
                    <h4>{title}</h4>
                  </div>
                </Link>
              </Item>
            ))}
          </Menu>
        </CustomScrollbars>
      </div>
      <div className="sb-footer ic-nav-item-student">
        <Link onClick={revokeAuth} to="/">
          <div className="link-wrapper">
            <span className="ic-bar" />
            <MdKeyboardBackspace />
            <h4>Logout</h4>
          </div>
        </Link>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  routes: arrayOf(any.isRequired).isRequired,
};

export default SidebarContent;
