import { STUDENT_SUPPORT, STUDENT_SUPPORT_REPLY } from '../../../routes';
import StudentSupportHome from './home';
import StudentSupportReply from './reply';

export default [
  {
    id: 56, title: 'Supports', exact: true, path: STUDENT_SUPPORT, component: StudentSupportHome,
  },
  {
    id: 58, title: 'Replies', exact: true, path: STUDENT_SUPPORT_REPLY, component: StudentSupportReply,
  },
];
