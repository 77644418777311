import React, { useContext, useState } from 'react';
import {
  Row, Modal, message, notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import MessageForm from './messageForm';
import Recipiant from './recipiant';
import { AuthContext } from '../../../appContext';
import { CREATE_MESSAGING, GET_MESSAGES } from '../../../graphql';
import { MESSAGING_EMAIL, MESSAGING_SMS } from '../../../routes';
import './styles.less';

const { confirm } = Modal;
const Detail = () => {
  const { userInfo } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const { location: { pathname }, push } = useHistory();
  const url = pathname.split('/');
  const messageType = url[url.length - 2];
  const [messagePayload, setMessagePayload] = useState({
    title: null,
    body: null,
  });
  const [recipiant, setRecipiant] = useState(null);
  const [createMessage] = useMutation(CREATE_MESSAGING);
  const handleFormCompete = (inputs) => {
    setMessagePayload({ ...inputs });
    setStep(2);
  };
  const handleCreateMessage = async () => {
    try {
      const newMessage = {
        type: messageType.toUpperCase(),
        title: messagePayload.title,
        body: messagePayload.body,
        userId: userInfo ? userInfo.id : '',
        recipiant: { connect: recipiant.map((id) => ({ id })) },
      };
      await createMessage({
        variables: { data: newMessage },
        update: (cache, { data: { createMessaging } }) => {
          const type = messageType.toUpperCase();
          const { messages: existingMessages } = cache.readQuery({
            query: GET_MESSAGES,
            variables: {
              where: { type: { equals: type } },
              orderBy: { createdAt: 'desc' },
            },
          });
          cache.writeQuery({
            query: GET_MESSAGES,
            variables: {
              where: { type: { equals: type } },
              orderBy: { createdAt: 'desc' },
            },
            data: {
              messages: [...existingMessages, createMessaging],
            },
          });
        },
      });
      notification.success({
        message: 'Message successfully sent',
        description: 'Your messages has been sent out',
      });
      const link = messageType === 'sms' ? MESSAGING_SMS : MESSAGING_EMAIL;
      push(link);
    } catch (error) {
      message.error('Error, could not send messages');
    }
  };
  const showConfirm = () => {
    confirm({
      title: 'Do you wish to send out messages',
      icon: <ExclamationCircleOutlined />,
      content: 'this action cannot be reverted or stopped, please confirm',
      onOk() {
        return handleCreateMessage();
      },
      onCancel() {
        message.info('message sending was canceled');
      },
    });
  };

  const handleSelectComplete = () => {
    if (!recipiant || recipiant.length < 1) {
      return message.error('No recipiant selected yet');
    }
    showConfirm();
  };

  return (
    <Row className="messaging">
      {step === 1 && <MessageForm initValue={messagePayload} type={messageType} onFormFinish={handleFormCompete} />}
      {step === 2 && <Recipiant onSelectChange={setRecipiant} setStep={setStep} selectComplete={handleSelectComplete} />}
    </Row>
  );
};
export default Detail;
