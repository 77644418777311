import { gql } from '@apollo/client';

export const GET_ALL_COHORT = gql`
	query getAllCohort {
		getAllCohort {
			id
			name
			createdAt
			user {
				id
				lastName
			}
			Subject {
				id
				name
			}
		}
	}
`;

export const GET_SINGLE_COHORT = gql`
	query getSingleCohort($where: CohortWhereUniqueInput!) {
		cohort(where: $where) {
			id
			name
			createdAt
			Subject {
				id
				name
			}
		}
	}
`;

export const GET_SINGLE_COHORT_NAME = gql`
	query getSingleCohort($where: CohortWhereUniqueInput!) {
		cohort(where: $where) {
			id
			name
			createdAt
		}
	}
`;

// ---------------- userCohort ---------------------

export const GET_USER_COHORT = gql`
	query getUserCohorts($where: UserCohortWhereInput) {
		userCohorts(where: $where) {
			id
			cohort {
				id
				name
			}
		}
	}
`;
