import { SETUP_COHORT_HOME, SETUP_COHORT_DETAIL } from '../../routes';
import CohortHome from './home';
import CohortDetail from './detail';

export default [
  {
    id: 10, title: 'Home', exact: true, path: SETUP_COHORT_HOME, component: CohortHome,
  },
  {
    id: 11, title: 'Detail', exact: true, path: SETUP_COHORT_DETAIL, component: CohortDetail,
  },
];
