import React, { useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Row, Col, Input, Form, Upload, Button, message, notification,
} from 'antd';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { func, shape, string } from 'prop-types';
import { STUDENT_ACTIVITIES } from '../../../../routes';
import { AuthContext } from '../../../../appContext';

import { UPLOAD_FILES, CREATE_ACTIVITY_SUBMISSION, GET_STUDENT_ACTIVITIES } from '../../../../graphql';

const { Item: FormItem } = Form;
const SubmitForm = ({
  form, setLoading, studentActivityId, activity,
}) => {
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILES);
  const { userInfo: { id: userId } } = useContext(AuthContext);
  const [submitActivity, { loading: submissionLoading }] = useMutation(CREATE_ACTIVITY_SUBMISSION);
  const { push } = useHistory();
  useEffect(() => {
    if (uploadLoading || submissionLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [uploadLoading, setLoading, submissionLoading]);

  const handleFormSubmit = async (inputs) => {
    const { textContent, attachments } = inputs;
    if (!textContent && !attachments) {
      if (activity.embedLink) {
        const embedLinkSubmission = {
          textContent: 'GOOGLE FORM SUBMISSION',
          activity: { connect: { id: activity.id } },
          studentActivity: { connect: { id: studentActivityId } },
        };
        await submitActivity({
          variables: { data: embedLinkSubmission },
        });
        notification.success({ message: 'Activity submitted successfully', description: 'Your activity has been submitted' });
        return push(STUDENT_ACTIVITIES);
      }
      return message.error('Please provide an input');
    }
    const newSubmission = {
      textContent,
      activity: { connect: { id: activity.id } },
      studentActivity: { connect: { id: studentActivityId } },
    };
    try {
      if (attachments && attachments.fileList) {
        const { fileList } = attachments;
        let fileArr = [];
        fileList.forEach((file) => {
          const actualFile = file.originFileObj || file;
          fileArr = [...fileArr, actualFile];
        });
        const { data: { createFiles } } = await filesUpload({ variables: { files: fileArr, folder: 'activity' } });
        const filesMap = createFiles.map(({ id }) => ({ id }));
        newSubmission.attachments = {
          connect: filesMap,
        };
      }
      await submitActivity({
        variables: { data: newSubmission },
        update: (cache) => {
          const { studentActivities } = cache.readQuery({
            query: GET_STUDENT_ACTIVITIES,
            variables: { where: { userId: { equals: userId } } },
          });
          cache.writeQuery({
            query: GET_STUDENT_ACTIVITIES,
            variables: { where: { userId: { equals: userId } } },
            data: {
              studentActivities: studentActivities.map((item) => {
                if (item.id === studentActivityId) {
                  return { ...item, status: 'SUBMITTED' };
                }
                return item;
              }),
            },
          });
        },
      });
      notification.success({ message: 'Activity submitted successfully', description: 'Your activity has been submitted' });
      push(STUDENT_ACTIVITIES);
    } catch (error) {
      message.error('Could not submit activity');
    }
  };
  return (
    <Row className="submission">
      <Col span={24}>
        <h4 className="ic-ft-dosis top">Submission</h4>
      </Col>
      <Col span={24}>
        <Form onFinish={handleFormSubmit} form={form} layout="vertical">
          {activity.embedLink && (
          <div className="embed-raper gx-mt-3">
            <h4>Note: click &quot;Complete Activity&quot; after submitting this form</h4>
            {parse(activity.embedLink)}
          </div>
          )}
          {activity.hasTextInput && (
            <FormItem name="textContent" label="Comment">
              <Input.TextArea rows={7} placeholder="write a comment for your teacher" />
            </FormItem>
          )}
          <FormItem valuePropName="file" name="attachments" label="Attach Files">
            <Upload
              listType="picture"
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Click Here</Button>
            </Upload>
          </FormItem>
        </Form>
      </Col>

    </Row>
  );
};

SubmitForm.propTypes = {
  form: shape({}).isRequired,
  setLoading: func.isRequired,
  studentActivityId: string.isRequired,
  activity: shape({}).isRequired,
};

export default SubmitForm;
