import React, { useEffect, useState } from 'react';
import { message, Row } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_USERS, GET_ALL_COHORT, UPDATE_USER_INFO } from '../../../graphql';
import Table from './table/Table';
import Filter from './filter/FIlter';
import DropdownBtn from './dropdownBtn/Dropdown';
import { applyFilter } from '../../../util/common';

const UserHome = () => {
  const {
    loading: userLoading, data: userPayload, error: userError, refetch,
  } = useQuery(GET_ALL_USERS);
  const { loading: cohortLoading, data: cohortPayload } = useQuery(GET_ALL_COHORT);
  const [updateUser] = useMutation(UPDATE_USER_INFO);
  const [users, setUsers] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [filterValue, setFilterValue] = useState({ cohortId: null, accountType: null, search: null });

  const handleDeleteUser = async (id) => {
    try {
      await updateUser({
        variables: {
          id,
          data: {
            status: { set: 'DELETED' },
          },
        },
        update: (cache) => {
          const exiting = cache.readQuery({ query: GET_ALL_USERS });
          if (exiting.Users && exiting.Users.length) {
            const updatedList = exiting.Users.filter(({ id: userId }) => userId !== id);
            cache.writeQuery({
              query: GET_ALL_USERS,
              data: {
                Users: updatedList,
              },
            });
          }
        },
      });
      message.success('User deleted successfully');
    } catch (error) {
      message.error('Error, could not delete user');
    }
  };

  useEffect(() => {
    if (!userLoading && userPayload) {
      const { Users } = userPayload;
      const filterDefault = applyFilter(Users, { cohortId: null, accountType: 'STUDENT' });
      setFilterValue({ ...filterValue, accountType: 'STUDENT' });
      const userRev = [...filterDefault].reverse();
      setUsers(userRev);
    }
    // eslint-disable-next-line
  }, [userLoading, userPayload]);

  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { getAllCohort } = cohortPayload;
      setCohorts(getAllCohort);
    }
  }, [cohortLoading, cohortPayload]);

  useEffect(() => {
    refetch();
  // eslint-disable-next-line
  }, []);

  if (userError) {
    console.log(userError);
    message.error('Error, could not fetch users');
  }

  const handleFiltering = (actionType, value) => {
    if (!userPayload || !userPayload.Users) return;
    const { Users } = userPayload;
    switch (actionType) {
      case 'ACCOUNT_TYPE': {
        if (value === 'ALL') {
          setFilterValue({ ...filterValue, accountType: null });
          const matches = applyFilter(Users, { ...filterValue, accountType: null });
          return setUsers(matches);
        }
        setFilterValue({ ...filterValue, accountType: value });
        const filtered = applyFilter(Users, { ...filterValue, accountType: value });
        return setUsers(filtered);
      }
      case 'SEARCH': {
        if (value === '') {
          setFilterValue({ ...filterValue, search: null });
          const match = applyFilter(Users, { ...filterValue, search: null });
          return setUsers(match);
        }
        setFilterValue({ ...filterValue, search: value });
        const filtered = [...users].filter(({ firstName, lastName, userName }) => {
          const name = `${firstName} ${lastName}`.toLocaleLowerCase();
          const valueFormatted = value.toLocaleLowerCase();
          const usernameFormatted = userName.toLocaleLowerCase();
          return name.includes(valueFormatted) || usernameFormatted.includes(valueFormatted);
        });
        return setUsers(filtered);
      }
      case 'COHORT_NAME': {
        if (value === 'ALL') {
          setFilterValue({ ...filterValue, cohortId: null });
          const reset = applyFilter(Users, { ...filterValue, cohortId: null });
          return setUsers(reset);
        }
        setFilterValue({ ...filterValue, cohortId: value });
        const match = applyFilter(Users, { ...filterValue, cohortId: value });
        return setUsers(match);
      }
      default: {
        return setUsers(Users);
      }
    }
  };

  return (
    <div>
      <Row justify="end">
        <DropdownBtn />
      </Row>
      <Filter onFilterChange={handleFiltering} cohorts={cohorts} />
      <Table handleDeleteUser={handleDeleteUser} data={users} loading={userLoading} />
    </div>
  );
};
export default UserHome;
