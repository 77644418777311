import React from 'react';
import { TeamOutlined, MobileOutlined } from '@ant-design/icons';
import {
  MdAccountBalance, MdInsertEmoticon, MdDataUsage, MdPersonOutline,
} from 'react-icons/md';
import {
  DASHBOARD_HOME,
  PROFILE,
  LEARNING,
  MESSAGING_EMAIL,
  MESSAGING_SMS,
  SETUP_COHORT,
  SETUP_USERS,
  ACTIVITIES,
  SUPPORT,
  STUDENT_ACTIVITIES,
  STUDENT_HOME,
  STUDENT_PROFILE,
  STUDENT_SUPPORT,
} from '../../routes';

// Views
import UserView from '../../views/user';
import CohortView from '../../views/cohort';
import LearningView from '../../views/learning';
import ActivityView from '../../views/activity';
import ProfileView from '../../views/profile';
import DashboardView from '../../views/dashboard';
import SupportView from '../../views/support';
import MessagingView from '../../views/messaging';

// Student views
import StudentHomeView from '../../views/studentViews/home';
import StudentActivityView from '../../views/studentViews/studentActivity';
import StudentProfileView from '../../views/studentViews/profile';
import StudentSupportView from '../../views/studentViews/studentSupport';

const iconStyles = { marginLeft: '30px', marginRight: '10px' };
export const instructorAdmin = [
  {
    title: 'Dashboard',
    hasSubRoute: false,
    exact: true,
    icon: <i data-icon="/" className="icon" />,
    path: DASHBOARD_HOME,
    view: DashboardView,
    key: '1',
  },
  {
    title: 'Learning',
    hasSubRoute: false,
    exact: false,
    icon: <i className="icon icon-graduation" />,
    path: LEARNING,
    view: LearningView,
    key: '2',
  },
  {
    title: 'Activities',
    hasSubRoute: false,
    exact: false,
    icon: <i className="icon icon-megaphone" />,
    path: ACTIVITIES,
    view: ActivityView,
    key: '3',
  },
  {
    title: 'Support',
    hasSubRoute: false,
    exact: true,
    icon: <i data-icon="-" className="icon" />,
    path: SUPPORT,
    view: SupportView,
    key: '4',
  },
  {
    title: 'Message',
    hasSubRoute: true,
    exact: true,
    icon: <i data-icon="~" className="icon" />,
    key: '5',
    path: 'messaging',
    sub: [
      {
        path: MESSAGING_SMS, view: MessagingView, title: 'SMS', icon: <i className="icon icon-chat-bubble" />,
      },
      {
        path: MESSAGING_EMAIL, view: MessagingView, title: 'Email', icon: <MobileOutlined style={iconStyles} />,
      },
    ],
  },
  {
    title: 'Profile',
    hasSubRoute: false,
    exact: true,
    icon: <i className="icon icon-user-o" />,
    path: PROFILE,
    view: ProfileView,
    key: '6',
  },
  {
    title: 'Setup',
    hasSubRoute: true,
    exact: true,
    icon: <i className="icon icon-components" />,
    key: '7',
    path: 'setup',
    sub: [
      {
        path: SETUP_USERS, view: UserView, title: 'Users', icon: <TeamOutlined style={iconStyles} />,
      },
      {
        path: SETUP_COHORT, view: CohortView, title: 'Cohort', icon: <i className="icon icon-growth" />,
      },
    ],
  },
];

// consider changing exact to false

export const instructor = [
  {
    title: 'Dashboard',
    hasSubRoute: false,
    exact: true,
    icon: <i data-icon="/" className="icon" />,
    path: DASHBOARD_HOME,
    view: DashboardView,
    key: '1',
  },
  {
    title: 'Learning',
    hasSubRoute: false,
    exact: false,
    icon: <i className="icon icon-graduation" />,
    path: LEARNING,
    view: LearningView,
    key: '2',
  },
  {
    title: 'Activities',
    hasSubRoute: false,
    exact: false,
    icon: <i className="icon icon-megaphone" />,
    path: ACTIVITIES,
    view: ActivityView,
    key: '3',
  },
  {
    title: 'Support',
    hasSubRoute: false,
    exact: true,
    icon: <i data-icon="-" className="icon" />,
    path: SUPPORT,
    view: SupportView,
    key: '4',
  },
  {
    title: 'Profile',
    hasSubRoute: false,
    exact: true,
    icon: <i className="icon icon-user-o" />,
    path: PROFILE,
    view: ProfileView,
    key: '6',
  },
];

export const student = [
  {
    title: 'Home',
    exact: false,
    icon: <MdAccountBalance />,
    path: STUDENT_HOME,
    view: StudentHomeView,
    key: '1',
  },
  {
    title: 'Activities',
    exact: false,
    icon: <MdInsertEmoticon />,
    path: STUDENT_ACTIVITIES,
    view: StudentActivityView,
    key: '2',
  },
  {
    title: 'Support',
    exact: false,
    icon: <MdDataUsage />,
    path: STUDENT_SUPPORT,
    view: StudentSupportView,
    key: '3',
  },
  {
    title: 'My Profile',
    exact: true,
    icon: <MdPersonOutline />,
    path: STUDENT_PROFILE,
    view: StudentProfileView,
    key: '4',
  },
];
