import { SETUP_USER_HOME, SETUP_USER_NEW, SETUP_USER_DETAIL } from '../../routes';
import UserHome from './userHome';
import AddNewUser from './addNew';
import UserDetail from './userDetail';

export default [
  {
    id: 6, title: 'Home', exact: true, path: SETUP_USER_HOME, component: UserHome,
  },
  {
    id: 7, title: 'Detail', exact: true, path: SETUP_USER_DETAIL, component: UserDetail,
  },
  {
    id: 8, title: 'New User', exact: true, path: SETUP_USER_NEW, component: AddNewUser,
  },
];
