import { gql } from '@apollo/client';

export const GET_STATISTICS = gql`
	query getStatistic {
		getStatistics {
			id
			students
			teachers
			cohorts
			subjects
		}
	}
`;

export const GET_INSTRUCTOR_STATISTICS = gql`
	query getInstructorStatistics {
		getInstructorStatistics {
			id
			students
			cohorts
			subjects
		}
	}
`;