import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form, Input, Button, Avatar, Card, Upload, Select, message, Tag, Divider, Space, DatePicker, Modal,
} from 'antd';
import { useMutation } from '@apollo/client';
import { shape } from 'prop-types';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import CohortCard from './cohortCard';
import { makeUpdateObject } from '../../../../util/common';
import {
  UPLOAD_FILE, UPDATE_USER_INFO, RESET_PASSWORD,
} from '../../../../graphql';
import './styles.less';

const { Item: FormItem, useForm } = Form;
const { Option } = Select;

const RoleTag = (props) => {
  // eslint-disable-next-line react/prop-types
  const { label, onClose } = props;
  return (
    <Tag
      color="green"
      onClose={onClose}
      style={{ marginBottom: '-2px' }}
    >
      {label}
    </Tag>
  );
};
const { confirm } = Modal;
const ProfileTeacher = ({ userDetail }) => {
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [mutateUpdateUser, { loading: updateLoading }] = useMutation(UPDATE_USER_INFO);
  const [mutateResetPassword, { loading: resetPassLoading }] = useMutation(RESET_PASSWORD);
  const [isEdit, setIsEdit] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileTeacher, setProfileTeacher] = useState(null);
  const [user, setUser] = useState(null);
  const [form] = useForm();

  const handleEdit = () => {
    form.scrollToField('firstName');
    setIsEdit(true);
  };
  useEffect(() => {
    setUser(userDetail);
    setProfileTeacher(userDetail.profileTeacher);
    setProfile(userDetail.profileTeacher.profileImg);
  }, [userDetail]);

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    try {
      const { data: { createFile: { url } } } = await filesUpload({ variables: { file, folder: 'profileImg' } });
      await mutateUpdateUser({
        variables: {
          id: user.id,
          data: {
            profileTeacher: {
              update: { profileImg: { set: url } },
            },
          },
        },
      });
      setProfile(url);
      message.success('Profile picture updated successfully');
      onSuccess({ url });
    } catch (error) {
      onError(error);
      message.error('Error, could not update picture');
    }
  };
  const handleProfileUpdate = async (inputs) => {
    // select are users related, and rest all profile related
    const {
      firstName, lastName, status, email, userName, password, role, doe, ...profileInput
    } = inputs;
    try {
      const userUpdate = makeUpdateObject({
        firstName,
        lastName,
        status,
        role: role.length > 1 ? role[1] : role[0],
        password,
      });
      await mutateUpdateUser({
        variables: {
          id: user.id,
          data: {
            ...userUpdate,
            profileTeacher: {
              update: makeUpdateObject({
                ...profileInput,
                doe: moment(doe).toLocaleString(),
              }),
            },
          },
        },
      });
      setIsEdit(false);
      message.success('User profile updated successfully');
    } catch (error) {
      message.error('Error, could not update user profile');
    }
  };
  const handleResetPassword = async () => {
    try {
      await mutateResetPassword({
        variables: {
          userId: user.id,
        },
      });
      message.success('User password successfully updated');
    } catch (error) {
      message.error('Error, could not reset user password');
    }
  };
  const showConfirm = () => {
    confirm({
      title: 'Do you wish to reset this user password',
      icon: <ExclamationCircleOutlined />,
      content: 'this would prevent the user from logging in with previous password',
      onOk() {
        return handleResetPassword();
      },
      onCancel() {
        message.info('message sending was canceled');
      },
    });
  };
  return (
    <div className="profile-teacher-full">
      { user && (
      <>
        <Row>
          <Col md={16} xs={24}>
            <Card className="profile-card" title="Teacher Information">
              <Form form={form} onFinish={handleProfileUpdate} layout="vertical">
                <Row style={{ flexDirection: 'row' }}>
                  <Col className="gx-p-0 gx-pr-1" sm={12} xs={24}>
                    <FormItem initialValue={user.firstName} label="First Name" name="firstName">
                      <Input disabled={!isEdit} />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={12} xs={24}>
                    <FormItem initialValue={user.lastName} label="Last Name" name="lastName">
                      <Input disabled={!isEdit} />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pr-1" sm={12} xs={24}>
                    <FormItem initialValue={profileTeacher.phoneNumber || 'None'} label="Phone Number" name="phoneNumber">
                      <Input disabled={!isEdit} />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={12} xs={24}>
                    <FormItem initialValue={user.email} label="Email" name="email">
                      <Input disabled />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={24} md={12}>
                    <FormItem initialValue={user.userName} label="Username" name="userName">
                      <Input disabled={!isEdit} />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={24} md={12}>
                    <FormItem
                      initialValue=""
                      label="Password"
                      name="password"
                      rules={[{
                        min: 8, type: 'string', message: 'provide 8 character password',
                      }]}
                    >
                      <Input disabled={!isEdit} placeholder="Enter Password" />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <Divider>Bio Details</Divider>
                  </Col>
                  <Col className="gx-p-0" sm={24} xs={24}>
                    <FormItem initialValue={profileTeacher.address || 'Not set'} label="Address" name="address">
                      <Input disabled={!isEdit} />
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pr-1" sm={12} xs={24}>
                    {!isEdit ? (
                      <FormItem
                        initialValue={moment(profileTeacher.doe).format('MMM Do YYYY')}
                        label="Date Employment"
                        name="doex"
                      >
                        <Input disabled />
                      </FormItem>
                    ) : (
                      <FormItem
                        initialValue={moment(profileTeacher.doe)}
                        label="Date Employment"
                        name="doe"
                      >
                        <DatePicker className="gx-w-100" />
                      </FormItem>
                    )}
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={12} xs={24}>
                    <FormItem initialValue={[user.role]} label="Role" name="role">
                      <Select disabled={!isEdit} tagRender={RoleTag} mode="multiple">
                        <Option key="1" value="INSTRUCTOR">INSTRUCTOR</Option>
                        <Option key="2" value="ADMIN">ADMIN</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pr-1" sm={12} xs={24}>
                    <FormItem initialValue={profileTeacher.gender} name="gender" label="Gender">
                      <Select disabled={!isEdit}>
                        <Option key="MALE" value="MALE">MALE</Option>
                        <Option key="FEMALE" value="FEMALE">FEMALE</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0 gx-pl-1" sm={12} xs={24}>
                    <FormItem initialValue={user.status} name="status" label="Account Status">
                      <Select disabled={!isEdit}>
                        <Option key="ACTIVE" value="ACTIVE">ACTIVE</Option>
                        <Option key="WITHDRAWN" value="WITHDRAWN">WITHDRAWN</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col className="gx-p-0" span={24}>
                    <Space>
                      {isEdit ? (
                        <>
                          <Button onClick={() => setIsEdit(false)}>Cancel</Button>
                          <Button onClick={() => form.submit()} loading={updateLoading} type="primary">Save Changes</Button>
                        </>
                      ) : (
                        <>
                          <Button loading={resetPassLoading} onClick={showConfirm} type="danger">Reset Password</Button>
                          <Button onClick={handleEdit}>Update</Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col md={8} xs={24}>
            <Card className="profile-card picture" title="Profile Picture">
              <div className="photo-card">
                <div className="avatar-wrapper">
                  <Avatar src={profile} size="large" className="teacher-avatar" />
                </div>
                <Upload
                  customRequest={handleFileUpload}
                  accept="image/png,image/jpeg,image/jpg"
                  showUploadList={false}
                >
                  <Button
                    loading={uploadLoading || updateLoading}
                    className="upload-btn"
                    icon={<UploadOutlined />}
                  >
                    Change Picture
                  </Button>
                </Upload>
              </div>
            </Card>
          </Col>
        </Row>
        {user && <CohortCard user={user} />}
      </>
      )}
    </div>
  );
};

ProfileTeacher.propTypes = {
  userDetail: shape({}).isRequired,
};

export default ProfileTeacher;
