import { ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { cache } from './graphql';

let uri;
if (process.env.REACT_APP_ENV === 'production') {
  uri = process.env.REACT_APP_BACKEND_URL_PROD;
} else if (process.env.REACT_APP_ENV === 'staging') {
  uri = process.env.REACT_APP_BACKEND_URL_STAGING;
} else {
  uri = process.env.REACT_APP_BACKEND_URL_DEV;
}

const httpLink = createUploadLink({ uri });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
