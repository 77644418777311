import React, { useContext, useState, useEffect } from 'react';
import {
  Row, Empty, Col, Button, Card, message, Tag, Spin,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { CREATE_SUPPORT, GET_SUPPORTS } from '../../../../graphql';
import { AuthContext } from '../../../../appContext';
import { STUDENT_SUPPORT } from '../../../../routes';
import AddSupportModal from './addSupportModal';
import './styles.less';

const StudentSupport = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const [createSupport, { loading }] = useMutation(CREATE_SUPPORT);
  const [userSupports, setUserSupport] = useState([]);
  const { data: supportPayload, loading: supportLoading } = useQuery(GET_SUPPORTS, {
    variables: {
      where: { userId: { equals: userInfo.id } },
      orderBy: { createdAt: 'desc' },
    },
  });

  useEffect(() => {
    if (!supportLoading && supportPayload) {
      const { supports } = supportPayload;
      setUserSupport(supports);
    }
  }, [supportLoading, supportPayload]);
  const handleSubmit = async ({ title, description }) => {
    try {
      await createSupport({
        variables: {
          data: {
            description,
            title,
            supportId: 'DEFAULT',
            user: { connect: { id: userInfo.id } },
          },
        },
        update: (cache, { data: { createSupport: newSupport } }) => {
          const { supports: existingSupports } = cache.readQuery({
            query: GET_SUPPORTS,
            variables: {
              where: { userId: { equals: userInfo.id } },
              orderBy: { createdAt: 'desc' },
            },
          });
          cache.writeQuery({
            query: GET_SUPPORTS,
            variables: {
              where: { userId: { equals: userInfo.id } },
              orderBy: { createdAt: 'desc' },
            },
            data: {
              supports: [...existingSupports, newSupport],
            },
          });
        },
      });
      setShowAddModal(false);
      message.success('Support Request Sent!');
    } catch (error) {
      message.error('Error, Could not create support');
    }
  };
  return (
    <Row className="support">
      <AddSupportModal
        handleSubmit={handleSubmit}
        visible={showAddModal}
        loading={loading}
        handleCancel={() => setShowAddModal(false)}
      />
      <Col className="gx-mb-3" span={24}>
        <Button
          onClick={() => setShowAddModal(true)}
          type="primary"
        >
          Request Support
        </Button>
      </Col>
      <Col span={24}>
        <Card title="Your Support Requests" className="support-card">
          {supportLoading && <Spin className="gx-w-100 gx-mt-3" size="large" />}
          {userSupports.map(({
            id, description, title, replyCount, isClosed,
          }) => (
            <Card
              key={id}
              type="inner"
              title={<h5>{startCase(title)}</h5>}
              className="support gx-card"
              extra={(
                <Link to={`${STUDENT_SUPPORT}/${id}`}>
                  <Button type="primary" style={{ margin: '0' }} size="small">
                    View Replies
                  </Button>
                </Link>
            )}
            >
              <p className="description ic-ft-dosis">{description}</p>
              <h4 className="gx-mt-3">
                <Tag color="gold">{`${replyCount - 1} Replies`}</Tag>
                {isClosed ? <Tag color="volcano">Closed Support</Tag> : <Tag color="purple">Opened Support</Tag>}
              </h4>
            </Card>
          ))}
          {userSupports.length === 0 && (
            <Empty description="You have no opened support yet" />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default StudentSupport;
