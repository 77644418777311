import {
  MESSAGING_EMAIL,
  MESSAGING_SMS,
  CREATE_MESSAGE_EMAIL,
  CREATE_MESSAGE_SMS,
} from '../../routes';

import Home from './home';
import Create from './create';

export default [
  {
    id: 70, title: 'Home', exact: true, path: MESSAGING_SMS, component: Home,
  },
  {
    id: 71, title: 'Home', exact: true, path: MESSAGING_EMAIL, component: Home,
  },
  {
    id: 74, title: 'New Message', exact: true, path: CREATE_MESSAGE_SMS, component: Create,
  },
  {
    id: 75, title: 'New Message', exact: true, path: CREATE_MESSAGE_EMAIL, component: Create,
  },
];
