import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';

const Activities = () => (
  <Switch>
    {routes.map(({
      id, component, exact, path,
    }) => (
      <Route path={path} component={component} exact={exact} key={id} />
    ))}
  </Switch>
);

export default Activities;
