import React from 'react';
import {
  Form, Input, Row, Col,
} from 'antd';
import moment from 'moment';
import { shape } from 'prop-types';
import parse from 'html-react-parser';

const { Item: FormItem } = Form;
const DetailsForm = ({ message }) => (
  <Form className="message-detail" layout="vertical">
    <Row style={{ flexDirection: 'row' }}>
      <Col span={24}>
        <FormItem name="title" initialValue={message.title} label="Title">
          <Input disabled />
        </FormItem>
      </Col>
      <Col span={24}>
        {message.type === 'SMS' ? (
          <FormItem name="body" initialValue={message.body} label="Body">
            <Input.TextArea disabled />
          </FormItem>
        ) : (
          <div className="message-body">
            {parse(message.body)}
          </div>
        )}
      </Col>
      <Col xs={24} md={12}>
        <FormItem
          name="createdAt"
          initialValue={moment(message.createdAt).format('Do MMM YYYY')}
          label="Date Created"
        >
          <Input disabled />
        </FormItem>
      </Col>
      <Col xs={24} md={12}>
        <FormItem name="repCount" initialValue={message.recipiantCount} label="Recipiant Count">
          <Input disabled />
        </FormItem>
      </Col>
    </Row>
  </Form>
);

DetailsForm.propTypes = {
  message: shape({}).isRequired,
};

export default DetailsForm;
