import { gql } from '@apollo/client';

// this query must use connect and not create on relationship
export const CREATE_ACTIVITY = gql`
	mutation createActivity($data: ActivityCreateInput!) {
		createActivity(data: $data) {
			id
			title
			type
			hasTextInput
			instruction
			dueDate
			cohort {
				id
				name
			}
			subjects {
				id
				name
			}
			attachments {
				url
				filename
				id
				mimetype
			}
		}
	}
`;

export const UPDATE_ACTIVITY = gql`
	mutation updateActivity($data: ActivityUpdateInput!, $where: ActivityWhereUniqueInput!) {
		updateActivity(where: $where, data: $data) {
			id
			title
			type
			hasTextInput
			instruction
			dueDate
			cohort {
				id
				name
			}
			subjects {
				id
				name
			}
			attachments {
				url
				filename
				id
				mimetype
			}
		}
	}
`;

export const DELETE_ACTIVITY = gql`
	mutation deleteActivity($where: ActivityWhereUniqueInput!) {
		deleteActivity(where: $where){
			id
		}
	}
`;


// ---------------------------- Activity submission ---------------

export const CREATE_ACTIVITY_SUBMISSION = gql`
	mutation createActivitySubmission($data: SubmissionCreateInput!) {
		createSubmission(data: $data){
			id
			textContent
			status
		}
	}
`;

export const UPDATE_ACTIVITY_SUBMISSION = gql`
	mutation updateActivitySubmission($data: SubmissionUpdateInput!, $where: SubmissionWhereUniqueInput!) {
		updateSubmission(data: $data, where: $where){
			id
			textContent,
			status,
			rating
			createdAt
			activity{
				id
				title
			}
			studentActivity {
				user {
					id
					firstName
					lastName
					profileStudent {
						admissionNumber
					}
				}
			}
		}
	}
`;

export const DELETE_ACTIVITY_SUBMISSION = gql`
	mutation deleteActivitySubmission($where: SubmissionWhereUniqueInput!) {
		deleteSubmission(where: $where){
			id
			__typename
		}
	}
`;