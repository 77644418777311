/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';

const User = () => (
  <Switch>
    {routes.map(({
      id, component, exact, path,
    }) => (
      <Route path={path} component={component} exact={exact} key={id} />
    ))}
  </Switch>
);

export default User;
