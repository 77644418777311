import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UiContext } from '../../../appContext';
import logo from '../../../assets/images/icsa-logo.png';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../../constants/ThemeSetting';

const SidebarLogo = () => {
  const { uiState: { width, ...rest } } = useContext(UiContext);
  let { navStyle } = rest;
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE
          ? <img alt="" src={logo} />
          : <img alt="" src={logo} />}
      </Link>

    </div>
  );
};

export default SidebarLogo;
