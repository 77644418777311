import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SINGLE_USER, GET_SINGLE_COHORT_NAME } from '../../../../graphql';
import { CohortSubjectItem } from '../common';
import { STUDENT_HOME, STUDENT_HOME_SUBJECT_R } from '../../../../routes';
import { AuthContext } from '../../../../appContext';
import ViewSpinner from '../../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const Subjects = () => {
  const { userInfo: { id: userId } } = useContext(AuthContext);
  const { id: urlCohortId } = useParams();
  const { data: userPayload, loading } = useQuery(GET_SINGLE_USER, { variables: { id: userId } });
  const { data: cohortPayload, loading: cohortLoading } = useQuery(GET_SINGLE_COHORT_NAME, { variables: { where: { id: urlCohortId } } });
  const [subjects, setSubjects] = useState([]);
  const [cohortName, setCohortName] = useState(null);

  useEffect(() => {
    if (!loading && userPayload) {
      const { User: { UserSubject } } = userPayload;
      const cohortSubject = UserSubject.filter(({ subject: { cohortId } }) => cohortId === urlCohortId);
      setSubjects(cohortSubject);
    }
  }, [userPayload, loading, urlCohortId]);

  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { cohort } = cohortPayload;
      setCohortName(cohort.name);
    }
  }, [cohortPayload, cohortLoading]);

  return (
    <div>
      {!loading && userPayload ? (
        <Row className="student-subject">
          <Col span={24}>
            <Link to={STUDENT_HOME}><Button shape="circle" icon={<ArrowLeftOutlined />} /></Link>
            {cohortName && <h3 className="ic-ft-dosis">{`${cohortName} - Subjects`}</h3>}
          </Col>
          {subjects.map(({ id, subject: { name, id: subjectId } }) => (
            <CohortSubjectItem
              title={name}
              key={id}
              extras="Click learn to start subject"
              btnText="Learn"
              link={`${STUDENT_HOME_SUBJECT_R}${urlCohortId}/learn?subjectId=${subjectId}`}
            />
          ))}
        </Row>
      ) : <ViewSpinner />}
    </div>
  );
};

export default Subjects;
