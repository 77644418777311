import React, { useContext } from 'react';
import { shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { HiDownload } from 'react-icons/hi';
import moment from 'moment';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, BackTop, Space } from 'antd';
import { LearnContext, learnActionTypes } from '../../../../appContext/learnContext';
import { ScrollToWithProps } from '../../../../util/ScrollToTop';
import Player from '../../../../components/player';

const Reader = ({ topic }) => {
  const { learnState: { subject, selectedTopic }, learnDispatch } = useContext(LearnContext);
  const {
    title, video, content, createdAt,
  } = topic;
  const { push, location: { pathname, search } } = useHistory();
  const { index } = selectedTopic;
  const handlePrevClick = () => {
    const topics = subject ? subject.Topic : [];
    const newIndex = index - 1;
    if (newIndex < 0) return;
    const newTopic = topics[newIndex];
    learnDispatch({
      type: learnActionTypes.SET_SELECTED_TOPIC,
      payload: { title: newTopic.title, index: newIndex },
    });
    return push(`${pathname}?subjectId=${subject.id}&t=${newTopic.id}`);
  };
  const handleNextClick = () => {
    const topics = subject ? subject.Topic : [];
    const newIndex = index + 1;
    if (newIndex > (topics.length - 1)) return;
    const newTopic = topics[newIndex];
    learnDispatch({
      type: learnActionTypes.SET_SELECTED_TOPIC,
      payload: { title: newTopic.title, index: newIndex },
    });
    return push(`${pathname}?subjectId=${subject.id}&t=${newTopic.id}`);
  };
  console.log(video);
  return (
    <>
      <div className="video-wrapper">
        {video && <Player videoId={`${video}?rel=0`} />}
      </div>
      <div className="reader">
        <ScrollToWithProps change={search} />
        <div className="heading">
          <h4 className="ic-ft-dosis heading-text">{title}</h4>
        </div>
        <div className="content">
          {parse(content)}
        </div>
        {topic.uploads && topic.uploads.length > 0 && (
          <div className="attachments">
            <h4>
              Attached Files
              {' '}
              <span>(click to download)</span>
            </h4>
            {topic.uploads && topic.uploads.map(({ url, id, filename }) => (
              <ol className="file">
                <li key={id}>
                  <a href={url} download>
                    {filename.slice(11)}
                    {' '}
                    <HiDownload />
                  </a>
                </li>
              </ol>
            ))}
          </div>
        )}
        <h4 className="date-created ic-ft-dosis">
          <span>{`Created ${moment(createdAt).format('MMM Do YYYY')}`}</span>
        </h4>
        <div className="controls">
          {subject && subject.Topic && (
            <Space>
              <Button disabled={index === 0} onClick={handlePrevClick} size="large" type="primary">
                <ArrowLeftOutlined />
                {' '}
                Previous
              </Button>
              <Button disabled={index === (subject.Topic.length - 1)} onClick={handleNextClick} size="large" type="primary">
                NEXT
                {' '}
                <ArrowRightOutlined />
              </Button>
            </Space>
          )}
        </div>
        <BackTop />
      </div>
    </>
  );
};
Reader.propTypes = {
  topic: shape({}).isRequired,
};

export default Reader;
