import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { MdAdjust, MdTrendingFlat, MdDoneAll } from 'react-icons/md';
import { Col } from 'antd';
import { shape, string } from 'prop-types';
import { STUDENT_ACTIVITIES } from '../../../routes';
import './styles.less';

const ActivityCard = ({
  title, date, status, id, submission, type,
}) => {
  const dtFormatted = moment(date).format('MMM Do YYYY');
  return (
    <Col xs={24} md={12}>
      <Link to={`${STUDENT_ACTIVITIES}/${id}`}>
        <div className="activity-item">
          {status === 'SUBMITTED' ? (
            <span className="icon submitted">
              <MdDoneAll />
            </span>
          ) : (
            <span className="icon">
              <MdAdjust />
            </span>
          )}

          <span className="icon view">
            <h6>View</h6>
            {' '}
            <MdTrendingFlat />
          </span>
          <h6 className="type">{type && type.split('_').join(' ')}</h6>
          <h5>{title}</h5>
          {status === 'NOT_SUBMITTED' ? (
            <h6>No Submission</h6>
          ) : (
            <h6>
              Submitted
              { submission && submission.status === 'ACCEPTED' && (
              <span className="score">
                {', '}
                Score
                {': '}
                <span>{`${submission.rating}%`}</span>
              </span>
              )}
              { submission && submission.status === 'REJECTED' && (
              <span>
                {', '}
                <span className="reject">REJECTED</span>
              </span>
              )}
            </h6>
          ) }
          <h6 className="date">
            Due Date:
            {' '}
            <span>{dtFormatted}</span>
          </h6>
        </div>
      </Link>
    </Col>
  );
};

ActivityCard.defaultProps = {
  submission: null,
};

ActivityCard.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  id: string.isRequired,
  status: string.isRequired,
  submission: shape({}),
  type: string.isRequired,
};

export default ActivityCard;
