import { gql } from '@apollo/client';

export const GET_SUBJECTS = gql`
	query getAllSubjects($where: SubjectWhereInput) {
		subjects(where: $where) {
			id
			name
			cohortId
		}
	}
`;

export const GET_SUBJECT = gql`
	query getSubject($where: SubjectWhereUniqueInput!) {
		subject(where: $where) {
			id
			name
			Topic {
				id
				topicNo
				title
				status
				updatedAt
				createdAt
			}
		}
	}
`;

// -------------------- userSubject --------------------

export const GET_USER_SUBJECT = gql`
	query getUserSubject($where: UserSubjectWhereInput) {
		userSubjects(where: $where) {
			id
			subjectId
			subject {
				id
				name
				cohortId
			}
		}
	}
`;
