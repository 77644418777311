import React, { useState, useEffect } from 'react';
import {
  Modal, Row, Col, Input, Spin, Radio, message,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { func, bool, string } from 'prop-types';
import { GET_ACTIVITY_SUBMISSION, UPDATE_ACTIVITY_SUBMISSION, GET_ACTIVITY_SUBMISSIONS } from '../../../graphql';

const SubmissionDetailModal = ({ setShowModal, showModal, id }) => {
  const [submission, setSubmission] = useState(null);
  const [inputs, setInputs] = useState({ status: null, rating: null });
  const { loading: submissionLoading, data: submissionPayload } = useQuery(
    GET_ACTIVITY_SUBMISSION, { variables: { where: { id } } },
  );
  const [updateSubmission, { loading: updateLoading }] = useMutation(UPDATE_ACTIVITY_SUBMISSION);

  useEffect(() => {
    if (!submissionLoading && submissionPayload) {
      const { submission: submissionRes } = submissionPayload;
      setInputs({ status: submissionRes.status, rating: submissionRes.rating });
      setSubmission(submissionRes);
    }
  }, [submissionLoading, submissionPayload]);
  const handleOk = async () => {
    const { status, rating } = inputs;
    if (!status) return setShowModal(false);
    try {
      await updateSubmission({
        variables: {
          where: { id: submission.id },
          data: { status: { set: status }, rating: { set: +rating } },
        },
        update: (cache, { data: { updateSubmission: res } }) => {
          const { activity: { id: activityId }, id: submissionId } = res;
          const { submissions } = cache.readQuery({
            query: GET_ACTIVITY_SUBMISSIONS,
            variables: { where: { activityId: { equals: activityId } } },
          });
          const update = submissions.map((item) => {
            if (item.id === submissionId) return res;
            return item;
          });
          cache.writeQuery({
            query: GET_ACTIVITY_SUBMISSIONS,
            variables: { where: { activityId: { equals: activityId } } },
            data: update,
          });
        },
      });
      message.success(`Submission was ${status}`);
      setShowModal(false);
    } catch (error) {
      console.error(error);
      message.error('Error, could not update submission');
    }
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  return (
    <Modal
      title="Submission Details"
      style={{ top: 20 }}
      visible={showModal}
      onOk={handleOk}
      onCancel={() => setShowModal(false)}
      maskClosable={false}
      confirmLoading={updateLoading}
    >
      <Row justify="center">
        {submission && (
        <>
          <Col span={24}>
            <h5>Student Name</h5>
            <Input disabled value={`${submission.studentActivity.user.firstName} ${submission.studentActivity.user.lastName}`} />
          </Col>
          <Col span={24}>
            <h5 className="gx-mt-3">Text Content</h5>
            <Input.TextArea disabled value={`${submission.textContent}`} />
          </Col>
          <Col className="gx-mb-3" span={24}>
            <h5 className="gx-mt-3">Attached Files</h5>
            <ol>
              {submission.attachments.map(({ url, filename }) => (
                <li key={url}><h5><a className="sub-link" rel="noopener noreferrer" target="_blank" download href={url}>{filename.slice(11)}</a></h5></li>
              ))}
            </ol>
            {submission.attachments.length === 0 && <h6>No file attached</h6>}
          </Col>
          <Col span={24}>
            <Radio.Group value={inputs.status} name="status" onChange={onChange}>
              <Radio value="ACCEPTED">Accept</Radio>
              <Radio value="REJECTED">Reject</Radio>
            </Radio.Group>
          </Col>
          {inputs.status === 'ACCEPTED' && (
            <Col className="gx-mt-3" span={24}>
              <Input prefix="% " type="number" onChange={onChange} value={inputs.rating} placeholder="Rating in %" name="rating" />
            </Col>
          )}
        </>
        )}
        {submissionLoading && <Spin className="gx-mt-3" />}
      </Row>
    </Modal>
  );
};

SubmissionDetailModal.propTypes = {
  setShowModal: func.isRequired,
  showModal: bool.isRequired,
  id: string.isRequired,
};

export default SubmissionDetailModal;
