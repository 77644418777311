import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Col, notification, Row, Empty,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import ContainerHeader from '../../../components/containerHeader';
import Cohorts from './cohorts';
import { GET_ALL_COHORT, CREATE_COHORT } from '../../../graphql';
import { AuthContext } from '../../../appContext';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import AddCohortModal from './addCohort';

export default () => {
  const { data: cohortPayload, loading: cohortLoading } = useQuery(GET_ALL_COHORT);
  const [addCohort, { data: addCohortRes, loading: addCohortLoading }] = useMutation(CREATE_COHORT);
  const [cohorts, setCohort] = useState([]);
  const [showAddCohort, setShowAddCohort] = useState(false);
  const { userInfo: { id } } = useContext(AuthContext);
  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { getAllCohort } = cohortPayload;
      const returnedCohort = [...getAllCohort].reverse();
      setCohort(returnedCohort);
    }
  }, [cohortLoading, cohortPayload]);

  const handleAddCohort = async (payload) => {
    const { name, description } = payload;
    const input = { name, description, user: { connect: { id } } };
    try {
      await addCohort({
        variables: { input },
        optimisticResponse: {
          __typename: 'Mutation',
          createCohort: {
            __typename: 'Cohort',
            name: input.name,
            description: input.description,
            createdAt: new Date(),
            Subject: [],
            user: {
              lastName: 'Incoming',
            },
          },
        },
        update: (proxy, { data: { createCohort } }) => {
          const data = proxy.readQuery({ query: GET_ALL_COHORT });
          proxy.writeQuery({
            query: GET_ALL_COHORT,
            data: {
              getAllCohort: [...data.getAllCohort, createCohort],
            },
          });
        },
      });
    } catch (error) {
      notification.error({ message: 'Error, something went wrong', description: 'Could not add cohort' });
    }
  };
  useEffect(() => {
    if (!cohortLoading && addCohortRes) {
      notification.success({ message: 'New Cohort Added', description: "You've successfully created a new cohort" });
      setShowAddCohort(false);
    }
  }, [cohortLoading, addCohortRes]);
  return (
    <div>
      <AddCohortModal
        handleAddCohort={handleAddCohort}
        visible={showAddCohort}
        addLoading={addCohortLoading}
        handleCancel={() => setShowAddCohort(!showAddCohort)}
      />
      <div className="gx-d-flex gx-flex-row-reverse gx-w-100 ">
        <Button type="primary" onClick={() => setShowAddCohort(!showAddCohort)}>New Cohort</Button>
      </div>
      <Row className="gx-mb-3">
        <Col span={24}>
          <ContainerHeader title="Cohorts" />
        </Col>
        {!cohortLoading && <Cohorts cohorts={cohorts} />}
        {!cohortLoading && cohortPayload && cohorts.length < 1 && (
          <Col span={24}>
            <Empty description="No cohort created yet" />
          </Col>
        )}
        {cohortLoading && <ViewSpinner />}
      </Row>
    </div>
  );
};
