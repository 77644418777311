import React from 'react';
import { string, number } from 'prop-types';

const Header = ({ cohortName, subjectNumber }) => (
  <div>
    <h4>{cohortName}</h4>
    <p className="gx-m-0">{`${subjectNumber} subjects available`}</p>
  </div>
);

Header.propTypes = {
  cohortName: string.isRequired,
  subjectNumber: number.isRequired,
};

export default Header;
