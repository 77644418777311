import React from 'react';
import {
  Row, Col, Tag, Table, Card,
} from 'antd';
import { shape, arrayOf } from 'prop-types';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Account Type',
    dataIndex: 'accountType',
    key: 'accountType',
    render: (text) => {
      if (text === 'STUDENT') return <Tag color="#2db7f5">{text}</Tag>;
      if (text === 'ADMIN') return <Tag color="#87d068">{text}</Tag>;
      if (text === 'TEACHER') return <Tag color="#108ee9">{text}</Tag>;
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
];

const RecipiantTable = ({ recipiant }) => (
  <Row>
    <Col span={24}>
      <Card title="Recipiant">
        <Table rowKey="id" columns={columns} dataSource={recipiant || []} />
      </Card>
    </Col>
  </Row>
);

RecipiantTable.propTypes = {
  recipiant: arrayOf(shape({})).isRequired,
};

export default RecipiantTable;
