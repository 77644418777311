import React, { useState } from 'react';
import {
  Row, Col, Form, Input, Card, Button, message,
} from 'antd';
import { RightCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import Editor from '../../../components/editor';
import { MESSAGING_EMAIL, MESSAGING_SMS } from '../../../routes';

const { Item: FormItem } = Form;
const MessageForm = ({ onFormFinish, type, initValue }) => {
  const [editorContent, setEditorContent] = useState(initValue.body);
  const handleFromFinish = (input) => {
    if (type === 'email' && !editorContent) {
      return message.error('Body is required');
    }
    const { title } = input;
    const payload = {
      title,
      body: input.body || editorContent,
    };
    onFormFinish(payload);
  };
  return (
    <Col span={24}>
      <Row>
        <Col sm={18} xs={24}>
          <Card
            title="Message"
            extra={(
              <Link to={type === 'sms' ? MESSAGING_SMS : MESSAGING_EMAIL}>
                <Button className="gx-m-0" size="small">
                  Cancel
                  {' '}
                  <CloseCircleOutlined />
                </Button>
              </Link>
              )}
          >
            <Form preserve onFinish={handleFromFinish} name="message-form" layout="vertical">
              <Row>
                <Col span={24}>
                  <FormItem initialValue={initValue.title} rules={[{ required: true }]} name="title" label="Title">
                    <Input placeholder="title for your message" />
                  </FormItem>
                </Col>
                {type === 'sms' ? (
                  <Col span={24}>
                    <FormItem initialValue={initValue.body} rules={[{ required: true }]} name="body" label="Body">
                      <Input.TextArea placeholder="your message content" autoSize={{ minRows: 10 }} maxLength={160} />
                    </FormItem>
                  </Col>
                ) : (
                  <Col span={24} className="gx-mb-3">
                    <Editor setContent={setEditorContent} content={editorContent} />
                  </Col>
                )}
                <Col className="gx-d-flex gx-justify-content-end" span={24}>
                  <Button htmlType="submit" type="primary">
                    Proceed
                    {' '}
                    <RightCircleOutlined />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

MessageForm.propTypes = {
  onFormFinish: func.isRequired,
  type: string.isRequired,
  initValue: shape({}).isRequired,
};

export default MessageForm;
