import { gql } from '@apollo/client';

export const UPLOAD_FILE = gql`
    mutation uploadFile($folder: String, $description: String, $file: Upload!) {
        createFile(file: $file, folder: $folder, description: $description) {
            id
            filename
            url
        }
    }
`;

export const UPLOAD_FILES = gql`
    mutation uploadFiles($folder: String, $description: String, $files: [Upload!]!) {
        createFiles(files: $files, folder: $folder, description: $description) {
            id
            filename
            url
        }
    }
`;
