import React from 'react';
import { Col, Button } from 'antd';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';

const CohortSubjectItem = ({
  link, btnText, title, extras,
}) => (
  <Col span={24}>
    <div className="cohort-subject-item">
      <h4 className="ic-ft-bubble">
        {title}
        {' '}
        <span>{extras}</span>
      </h4>
      <Link to={link}>
        <Button size="large" className="btn-student">{btnText}</Button>
      </Link>
    </div>
  </Col>
);

CohortSubjectItem.propTypes = {
  link: string.isRequired,
  btnText: string.isRequired,
  title: string.isRequired,
  extras: string.isRequired,
};

export default CohortSubjectItem;
