import React, { useState, useEffect } from 'react';
import {
  Col, Row, Button, Card, Popconfirm, Empty, Space, message,
} from 'antd';
import moment from 'moment';
import {
  ArrowLeftOutlined, FormOutlined, CheckCircleOutlined, CheckCircleTwoTone,
} from '@ant-design/icons';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_SUBJECT, DELETE_TOPIC } from '../../../graphql';
import { LEARNING_TOPIC_CREATE, LEARNING_TOPIC_EDIT } from '../../../routes';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const Topics = () => {
  const { subjectId } = useParams();
  const paramsVariable = { where: { id: subjectId } };
  const { data: subjectPayload, loading } = useQuery(GET_SUBJECT, {
    variables: paramsVariable,
  });
  const [deleteTopic] = useMutation(DELETE_TOPIC);
  const { goBack } = useHistory();
  const [topics, setTopics] = useState([]);
  const [subjectInfo, setSubjectInfo] = useState(null);
  const [publishCount, setPublishCount] = useState(0);
  useEffect(() => {
    if (!loading && subjectPayload) {
      const { subject } = subjectPayload;
      const { id, name, Topic } = subject;
      let pubCount = 0;
      Topic.forEach(({ status }) => {
        if (status === 'PUBLISHED') {
          pubCount += 1;
        }
      });
      setPublishCount(pubCount);
      setSubjectInfo({ id, name });
      setTopics(Topic);
    }
  }, [subjectPayload, loading]);
  const handleDeleteTopic = async (topicId) => {
    try {
      await deleteTopic({
        variables: { where: { id: topicId } },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteTopic: {
            __typename: 'Topic',
            id: topicId,
          },
        },
        update: (proxy) => {
          const data = proxy.readQuery({ query: GET_SUBJECT, variables: paramsVariable });
          const { subject } = data;
          proxy.writeQuery({
            query: GET_SUBJECT,
            variables: paramsVariable,
            data: {
              subject: {
                ...subject,
                Topic: subject.Topic.filter(({ id }) => id !== topicId),
              },
            },
          });
        },
      });
      message.success('Topic deleted');
    } catch (err) {
      console.log(err);
      message.error('Could not delete topic');
    }
  };
  return (
    <div>
      {!loading && (
        <Row>
          <Col span={24}>
            <Card className="gx-card gx-mb-3">
              {subjectInfo && (
                <div className="subject-detail">
                  <div>
                    <h4>{subjectInfo.name}</h4>
                    <small>{`${topics.length} topics, ${publishCount} published`}</small>
                  </div>
                  <Button onClick={goBack} size="middle" shape="circle" icon={<ArrowLeftOutlined />} />
                </div>
              )}
            </Card>
          </Col>
          <Col span={24}>
            <Card className="gx-card gx-pb-0">
              <div className="topic-header">
                <h5>Topics</h5>
                <Link to={`${LEARNING_TOPIC_CREATE}?sub=${subjectId}`}><Button type="primary">Add</Button></Link>
              </div>
              <div className="topics">
                {topics.map(({
                  id, title, status, createdAt,
                }) => (
                  <div key={id} className="topic-item">
                    <h5>
                      {status === 'PUBLISHED' ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleOutlined />}
                      {' '}
                      {' '}
                      {title}
                      <span>{`| created ${moment(createdAt).format('MMM Do YYYY')}`}</span>
                    </h5>
                    <Space size="small">
                      <Link to={`${LEARNING_TOPIC_EDIT}?topic=${id}`}>
                        <Button type="link">
                          <FormOutlined />
                          {' '}
                          Edit
                        </Button>
                      </Link>
                      <Button type="text">
                        <Popconfirm
                          title="Are you sure want to delete"
                          onConfirm={() => handleDeleteTopic(id)}
                          onCancel={() => {}}
                          okText="Delete"
                          cancelText="Cancel"
                          placement="topLeft"
                        >
                          Delete
                        </Popconfirm>
                      </Button>
                    </Space>
                  </div>
                ))}
              </div>
              {topics.length === 0 && <Empty description="No topics yet" />}
            </Card>
          </Col>
        </Row>
      )}
      {loading && <ViewSpinner />}
    </div>
  );
};

export default Topics;
