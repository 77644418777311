import React, { useEffect, useState } from 'react';
import {
  Card, Table, Button, message,
} from 'antd';
import { useParams, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ArrowLeftOutlined } from '@ant-design/icons';
import makeColumns from './tableColumn';
import { ACTIVITIES } from '../../../routes';
import { GET_ACTIVITY_SUBMISSIONS, DELETE_ACTIVITY_SUBMISSION } from '../../../graphql';
import DetailModal from './DetailModal';
import './styles.less';

const Submission = () => {
  const { activityId } = useParams();
  const { data: submissionPayload, loading: submissionLoading, refetch } = useQuery(
    GET_ACTIVITY_SUBMISSIONS, {
      variables: { where: { activityId: { equals: activityId } } },
    },
  );
  const [deleteActivity] = useMutation(DELETE_ACTIVITY_SUBMISSION);
  const [submissions, setSubmissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [submissionId, setSubmissionId] = useState(null);

  const handleViewClick = (id) => {
    setSubmissionId(id);
    setShowModal(true);
  };
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);
  const handleDeleteClick = async (id) => {
    try {
      await deleteActivity({
        variables: { where: { id } },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteSubmission: {
            id,
            __typename: 'Submission',
          },
        },
        update: (cache, { data: { deleteSubmission: { id: updateId } } }) => {
          const { submissions: existingSub } = cache.readQuery({
            query: GET_ACTIVITY_SUBMISSIONS,
            variables: { where: { activityId: { equals: activityId } } },
          });
          cache.writeQuery({
            query: GET_ACTIVITY_SUBMISSIONS,
            variables: { where: { activityId: { equals: activityId } } },
            data: {
              submissions: existingSub.filter(({ id: subId }) => subId !== updateId),
            },
          });
        },
      });
    } catch (error) {
      message.error('Error, could not delete activity');
    }
  };
  useEffect(() => {
    if (!submissionLoading && submissionPayload) {
      const { submissions: submissionRes } = submissionPayload;
      setSubmissions(submissionRes);
    }
  }, [submissionLoading, submissionPayload]);
  return (
    <div className="gx-mb-4 submission-wrapper">
      {showModal && <DetailModal showModal={showModal} id={submissionId} setShowModal={setShowModal} />}
      <Link to={ACTIVITIES}>
        <Button>
          <ArrowLeftOutlined />
        </Button>
      </Link>
      <Card title="Activity Submissions">
        <Table
          loading={submissionLoading}
          className="gx-table-responsive"
          columns={makeColumns(handleViewClick, handleDeleteClick)}
          dataSource={submissions}
          rowKey="id"
        />
      </Card>
    </div>
  );
};

export default Submission;
