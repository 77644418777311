import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';

const renderDate = (val) => <Tag color="green">{moment(val).format('MMM Do YYYY')}</Tag>;
const renderReply = (val) => <Tag color="orange">{val - 1}</Tag>;
const renderName = ({ firstName, lastName }) => <span>{`${firstName} ${lastName}`}</span>;

export default [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderDate,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: 'Replies',
    dataIndex: 'replyCount',
    key: 'replyCount',
    render: renderReply,
  },
  {
    title: 'Name',
    dataIndex: 'user',
    render: renderName,
    ellipsis: true,
  },
];
