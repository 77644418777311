import { STUDENT_ACTIVITIES, STUDENT_ACTIVITY_DETAILS } from '../../../routes';
import StudentActivityHome from './home';
import StudentActivityDetail from './detail';

export default [
  {
    id: 50, title: 'Home', exact: true, path: STUDENT_ACTIVITIES, component: StudentActivityHome,
  },
  {
    id: 52, title: 'Activity', exact: true, path: STUDENT_ACTIVITY_DETAILS, component: StudentActivityDetail,
  },
];
