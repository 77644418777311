import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { truncate } from 'lodash';

const renderDate = (val) => <span>{moment(val).format('MMM Do YYYY')}</span>;
const renderCohort = ({ name }) => <Tag color="orange">{name}</Tag>;
const renderSubmission = (val) => <Tag color="green">{val.length}</Tag>;
const renderSubject = (val) => (
  <>
    {val && val.map(({ id, name }) => <Tag color="blue" key={id}>{name}</Tag>)}
  </>
);
const renderAuthor = ({ firstName, lastName }) => (
  <span>
    {truncate(`${firstName} ${lastName}`, { length: 50 })}
  </span>
);

export default [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderDate,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Author',
    dataIndex: 'user',
    key: 'title',
    render: renderAuthor,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Cohort',
    dataIndex: 'cohort',
    key: 'cohort',
    render: renderCohort,
  },
  {
    title: 'Subjects',
    dataIndex: 'subjects',
    key: 'subject',
    render: renderSubject,
  },
  {
    title: 'Submission',
    dataIndex: 'submissions',
    key: 'submissions',
    render: renderSubmission,
  },
];

export const instructorColumns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderDate,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Cohort',
    dataIndex: 'cohort',
    key: 'cohort',
    render: renderCohort,
  },
  {
    title: 'Subjects',
    dataIndex: 'subjects',
    key: 'subject',
    render: renderSubject,
  },
  {
    title: 'Submission',
    dataIndex: 'submissions',
    key: 'submissions',
    render: renderSubmission,
  },
];
