import React from 'react';
import {
  Card, Col, Space, Button, Modal, message, notification,
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { arrayOf, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { upperFirst } from 'lodash';
import { DELETE_COHORT, GET_ALL_COHORT } from '../../../../graphql';
import { SETUP_COHORT_DETAIL_R } from '../../../../routes';
import './styles.less';

const { confirm } = Modal;
const Cohorts = ({ cohorts }) => {
  const [deleteCohort] = useMutation(DELETE_COHORT);
  const handleDeleteCohort = async (id) => {
    try {
      await deleteCohort({
        variables: {
          where: { id },
        },
        update: (cache) => {
          const existing = cache.readQuery({
            query: GET_ALL_COHORT,
          });
          if (existing && existing.getAllCohort) {
            const updatedList = existing.getAllCohort.filter(({ id: cohortId }) => cohortId !== id);
            cache.writeQuery({
              query: GET_ALL_COHORT,
              data: {
                getAllCohort: updatedList,
              },
            });
          }
        },
      });
      message.success('Cohort successfully deleted');
    } catch (error) {
      if (error.message && error.message.includes('ActivityToStudentActivity')) {
        return notification.info({
          description: 'Please ensure all activity for this cohort has been deleted',
          message: 'Unable to delete cohort',
        });
      }
      message.error('Error, could not delete cohort');
    }
  };
  const showConfirm = (id) => {
    confirm({
      title: 'Do you wish to delete this cohort',
      icon: <ExclamationCircleOutlined />,
      content: 'deleting this cohort would also delete content related to it',
      onOk() {
        return handleDeleteCohort(id);
      },
      onCancel() {
        message.info('cohort delete was canceled');
      },
    });
  };
  return (
    <>
      {cohorts.map(({
        id, name, Subject, createdAt,
      }) => (
        <Col key={id} span={24}>
          <Card className="gx-card gx-mb-3 cohort">
            <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
              <div className="content">
                <h4>{upperFirst(name)}</h4>
                <small>{`${Subject.length} subjects, created on ${moment(createdAt).format('MMM Do YYYY')}`}</small>
              </div>
              <div className="control-wrapper">
                <Space>
                  <Link to={`${SETUP_COHORT_DETAIL_R}${id}`}>
                    <button type="button" className="custom-view-btn">View</button>
                  </Link>
                  <Button onClick={() => showConfirm(id)} size="middle" className="gx-m-0" type="text"><DeleteOutlined /></Button>
                </Space>
              </div>
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};

Cohorts.propTypes = {
  cohorts: arrayOf(shape({})).isRequired,
};

export default Cohorts;
