import {gql} from '@apollo/client';

export const CREATE_COHORT = gql`
    mutation createCohort($input: CohortCreateInput!){
        createCohort(input: $input){
            id
            name
            user {
                firstName
            }
        }
    }
`;

export const DELETE_COHORT = gql`
    mutation deleteCohort($where: CohortWhereUniqueInput!){
        deleteCohort(where: $where){
            id
            name
        }
    }
`;