import React from 'react';
import { string } from 'prop-types';

const ContainerHeader = ({ title }) => (
  <div className="gx-page-heading">
    <h2 className="gx-page-title">{title}</h2>
  </div>
);

ContainerHeader.propTypes = {
  title: string.isRequired,
};
export default ContainerHeader;
