import React from 'react';
import { Button, Empty } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { shape, arrayOf } from 'prop-types';
import { LEARNING } from '../../../routes';

const PanelContent = ({ subjects }) => (
  <div className="subjects">
    {subjects.map(({ name, id }) => (
      <div key={id} className="subject">
        <h5 className="ic-ft-dosis">
          <RightCircleOutlined />
          {' '}
          {name}
        </h5>
        <Link to={`${LEARNING}/${id}`}>
          <Button size="small" type="link">View</Button>
        </Link>
      </div>
    ))}
    {subjects.length === 0 && (
    <Empty description="No subject for this cohort yet" />
    )}
  </div>
);

PanelContent.propTypes = {
  subjects: arrayOf(shape({})).isRequired,
};

export default PanelContent;
