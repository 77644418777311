import React, { useState, useEffect, useContext } from 'react';
import {
  Drawer, Button, Form, Spin,
} from 'antd';
import {
  func, bool, string, shape,
} from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { AuthContext } from '../../../appContext';
import { GET_ALL_COHORT } from '../../../graphql';
import CreateActivity from './CreateActivity';
import EditActivity from './EditActivity';
import './styles.less';

const { useForm } = Form;
const ActivityDrawer = ({
  showDrawer, handleClose, mode, payload,
}) => {
  const { userInfo } = useContext(AuthContext);
  const { data: cohortPayload, loading: cohortLoading } = useQuery(GET_ALL_COHORT);
  const [cohort, setCohorts] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [form] = useForm();
  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { getAllCohort } = cohortPayload;
      setCohorts(getAllCohort);
    }
  }, [cohortPayload, cohortLoading]);
  return (
    <Drawer
      title={mode === 'CREATE' ? 'Create new activity' : 'Edit Activity'}
      className="new-activity-drawer"
      width={720}
      onClose={handleClose}
      visible={showDrawer}
      destroyOnClose
      bodyStyle={{ paddingBottom: 40 }}
      footer={(
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button disabled={createLoading} onClick={form.submit} type="primary">
            {createLoading && <LoadingOutlined />}
            Save
          </Button>
        </div>
    )}
    >
      {cohort && mode === 'CREATE' && (
        <CreateActivity
          cohortLoading={cohortLoading}
          setLoading={setCreateLoading}
          cohort={cohort}
          userInfo={userInfo}
          closeModal={handleClose}
          form={form}
        />
      )}
      {cohort && mode === 'EDIT' && (
        <EditActivity
          cohortLoading={cohortLoading}
          setLoading={setCreateLoading}
          cohort={cohort}
          userInfo={userInfo}
          closeModal={handleClose}
          form={form}
          initValue={payload}
        />
      )}
      {cohortLoading && <Spin />}
    </Drawer>
  );
};

ActivityDrawer.defaultProps = {
  mode: 'CREATE',
  payload: {},
};

ActivityDrawer.propTypes = {
  handleClose: func.isRequired,
  showDrawer: bool.isRequired,
  mode: string,
  payload: shape({}),
};

export default ActivityDrawer;
