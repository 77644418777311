import React, { useContext } from 'react';
import { Menu } from 'antd';
import { arrayOf, any } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { UiContext } from '../../../appContext';
import CustomScrollbars from '../../../util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import { NAV_STYLE_MINI_SIDEBAR } from '../../../constants/ThemeSetting';
import './styles.less';

const { SubMenu, Item } = Menu;

const SidebarContent = ({ routes }) => {
  const { uiState: { navStyle } } = useContext(UiContext);
  const { location: { pathname } } = useHistory();
  const selectedSub = pathname.substr(1).toLocaleLowerCase().split('/')[1];
  const getSubNavStyle = (path) => {
    let style = 'ic-nav-item-sub';
    if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      style = `${style} mini`;
    }
    if (path === selectedSub.toLocaleLowerCase()) {
      style = `${style} selected`;
    }
    return style;
  };
  const pathToMatch = pathname.substr(1).split('/');
  const match1 = `${pathToMatch[0]}/${pathToMatch[1]}`;
  const match2 = `${match1}/${pathToMatch[2]}`;
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu selectedKeys={[match1, match2]} theme="dark" mode="inline">
            {routes.map(({
              hasSubRoute, icon, title, path, sub,
            }) => {
              if (hasSubRoute) {
                return (
                  <SubMenu
                    className={getSubNavStyle(path)}
                    title={(
                      <span>
                        {' '}
                        {icon}
                        <span className="item-title">{title}</span>
                      </span>
                    )}
                    key={path.toLocaleLowerCase()}
                  >
                    {sub.map(({ title: subtitle, path: subPath, icon: subIcon }) => (
                      <Item key={subPath.substr(1).toLocaleLowerCase()} className="ic-item-sub">
                        <Link to={subPath}>
                          <span>
                            <span className="ic-bar" />
                            {subIcon}
                            <span>{subtitle}</span>
                          </span>
                        </Link>
                      </Item>
                    ))}
                  </SubMenu>
                );
              }
              return (
                <Item
                  className={navStyle === NAV_STYLE_MINI_SIDEBAR ? 'ic-nav-item mini' : 'ic-nav-item'}
                  key={path.substr(1).toLocaleLowerCase()}
                >
                  <Link to={path}>
                    <span className="ic-bar" />
                    {icon}
                    <span>{title}</span>
                  </Link>
                </Item>
              );
            })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  routes: arrayOf(any.isRequired).isRequired,
};

export default SidebarContent;
