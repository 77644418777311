import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Card, Tag, message as antAlert, Empty,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { truncate, upperFirst } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { PlusOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
import { GET_MESSAGES, DELETE_MESSAGING } from '../../../graphql';
import { CREATE_MESSAGE_EMAIL, CREATE_MESSAGE_SMS } from '../../../routes';
import ViewMessageDrawer from '../viewDrawer';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const Home = () => {
  const { location: { pathname } } = useHistory();
  const url = pathname.split('/');
  const messageType = url[url.length - 1];
  const [messageId, setMessageId] = useState(null);
  const [showViewDrawer, setShowViewDrawer] = useState(false);
  const { data: messagesPayload, loading: loadingMessages } = useQuery(GET_MESSAGES, {
    variables: {
      where: { type: { equals: messageType.toUpperCase() } },
      orderBy: { createdAt: 'desc' },
    },
  });
  const [deleteMessage] = useMutation(DELETE_MESSAGING);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (!loadingMessages && messagesPayload) {
      const { messages: messagingRes } = messagesPayload;
      setMessages(messagingRes);
    }
  }, [messagesPayload, loadingMessages]);
  const handleDeleteMessage = async (id) => {
    try {
      await deleteMessage({
        variables: { where: { id } },
        update: (cache) => {
          const { messages: existingMessages } = cache.readQuery({
            query: GET_MESSAGES,
            variables: {
              where: { type: { equals: messageType.toUpperCase() } },
              orderBy: { createdAt: 'desc' },
            },
            orderBy: { createdAt: 'desc' },
          });
          const update = existingMessages ? existingMessages.filter(({ id: itemId }) => itemId !== id) : [];
          cache.writeQuery({
            query: GET_MESSAGES,
            variables: {
              where: { type: { equals: messageType.toUpperCase() } },
              orderBy: { createdAt: 'desc' },
            },
            data: {
              messages: update,
            },
          });
        },
      });
      antAlert.success('Message deleted successfully');
    } catch (error) {
      antAlert.error('Error deleting message');
    }
  };
  const handleViewMessage = (id) => {
    setMessageId(id);
    setShowViewDrawer(true);
  };
  return (
    <Row className="messaging-home gx-mb-3">
      {showViewDrawer && (
      <ViewMessageDrawer
        showDrawer={showViewDrawer}
        handleClose={setShowViewDrawer}
        messageId={messageId}
      />
      )}
      <Col span={24} className="gx-p-0">
        <div className="header">
          <h4>{`${upperFirst(messageType)} Messaging`}</h4>
          <Link to={messageType === 'sms' ? CREATE_MESSAGE_SMS : CREATE_MESSAGE_EMAIL}>
            <Button type="primary" size="large">
              <PlusOutlined />
              {' '}
              New Message
            </Button>
          </Link>
        </div>
      </Col>
      <Row className="gx-w-100" gutter={{ md: 15 }}>
        {messages.map(({
          id, type, title, createdAt, recipiantCount,
        }) => (
          <Col key={id} xs={24} md={12}>
            <Card className="gx-m-1 gx-w-100 activity-list" hoverable>
              <div className="icon-wrapper"><EllipsisOutlined /></div>
              <div className="card-content">
                <div className="description">
                  <h4 className="type assignment">{type.toUpperCase()}</h4>
                  <h4 className="title">{truncate(title, { length: 40 })}</h4>
                  <div className="extras">
                    <Tag color="blue">{moment(createdAt).format('Do MMM YYYY')}</Tag>
                    <Tag color="green">{`${recipiantCount} Recipiant`}</Tag>
                  </div>
                </div>
                <div className="controls">
                  <Button onClick={() => handleDeleteMessage(id)} type="link" className="delete-btn">
                    <DeleteOutlined />
                  </Button>
                  <div>
                    <Button
                      onClick={() => handleViewMessage(id)}
                      className="edit"
                      type="link"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
        {!loadingMessages && messages.length === 0 && (
          <Col span={24} className="gx-mt-4">
            <Empty description="No message created yet" />
          </Col>
        )}
        {loadingMessages && (
          <ViewSpinner />
        )}
      </Row>
    </Row>
  );
};

export default Home;
