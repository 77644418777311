import React from 'react';
import {
  Modal, Form, Input, Button, Space,
} from 'antd';
import { bool, func } from 'prop-types';

const { Item: FormItem } = Form;
const AddSupportModal = ({
  visible, handleCancel, loading, handleSubmit,
}) => (
  <Modal
    visible={visible}
    title="Request Support"
    onCancel={handleCancel}
    footer={null}
    destroyOnClose
  >
    <Form requiredMark={false} onFinish={handleSubmit} layout="vertical">
      <FormItem name="title" label="Title" rules={[{ required: true }]}>
        <Input placeholder="support title" />
      </FormItem>
      <FormItem name="description" label="Description" rules={[{ required: true }]}>
        <Input.TextArea placeholder="add a description" autoSize={{ minRows: 3 }} />
      </FormItem>
      <FormItem>
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button loading={loading} type="primary" htmlType="submit">Submit</Button>
        </Space>
      </FormItem>

    </Form>
  </Modal>
);

AddSupportModal.propTypes = {
  visible: bool.isRequired,
  handleCancel: func.isRequired,
  handleSubmit: func.isRequired,
  loading: bool.isRequired,
};

export default AddSupportModal;
