/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { arrayOf, shape } from 'prop-types';
import { AuthContext } from '../../../appContext';
import { SentMessageCell, ReceivedMessageCell } from './MessageCells';

const Conversation = ({ conversationData, selectedTicket }) => {
  const { userInfo } = useContext(AuthContext);
  const firstConversation = {
    message: selectedTicket.description,
    name: `${selectedTicket.user.firstName} ${selectedTicket.user.firstName}`,
    createdAt: selectedTicket.createdAt,
    avatar: selectedTicket.user ? selectedTicket.user.profileStudent.profileImg : '',
  };
  return (
    <div className="gx-chat-main-content">
      <ReceivedMessageCell conversation={firstConversation} />
      {conversationData.map((reply, index) => {
        const {
          message, user: {
            firstName, lastName, profileStudent, profileTeacher,
          }, createdAt,
        } = reply;
        const conversation = {
          message,
          name: `${firstName} ${lastName}`,
          createdAt,
        };
        if (reply.user.id === userInfo.id) {
          conversation.avatar = profileTeacher ? profileTeacher.profileImg : profileStudent ? profileStudent.profileImg : '';
          return <SentMessageCell key={index} conversation={conversation} />;
        }
        conversation.avatar = profileStudent ? profileStudent.profileImg : profileTeacher ? profileTeacher.profileImg : '';
        return <ReceivedMessageCell key={index} conversation={conversation} />;
      })}
    </div>
  );
};

Conversation.propTypes = {
  conversationData: arrayOf(shape({})).isRequired,
  selectedTicket: shape({}).isRequired,
};

export default Conversation;
