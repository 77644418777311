import React, { useEffect, useState } from 'react';
import { Drawer, Button, message as alert } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { func, bool, string } from 'prop-types';
import { GET_MESSAGING } from '../../../graphql';
import DetailForm from './Details';
import RecipiantTable from './RecipiantTable';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const ViewDrawer = ({ handleClose, showDrawer, messageId }) => {
  const [getMessage, { loading, data }] = useLazyQuery(GET_MESSAGING, { fetchPolicy: 'network-only' });
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (messageId) {
      try {
        getMessage({ variables: { where: { id: messageId } } });
      } catch (error) {
        alert.error('Error, could not load message details');
      }
    }
  }, [messageId, getMessage]);
  useEffect(() => {
    if (!loading && data) {
      const { messaging } = data;
      setMessage(messaging);
    }
  }, [loading, data]);
  return (
    <Drawer
      title="Message Details"
      className="new-activity-drawer"
      width={720}
      onClose={() => handleClose(false)}
      visible={showDrawer}
      destroyOnClose
      bodyStyle={{ paddingBottom: 40 }}
      footer={(
        <div
          style={{
            textAlign: 'left',
          }}
        >
          <Button type="primary" onClick={() => handleClose(false)} style={{ marginRight: 8 }}>
            Close Details
          </Button>
        </div>
    )}
    >
      <div className="message-detail">
        {!loading && message && (
        <>
          <DetailForm message={message} />
          <RecipiantTable recipiant={message.recipiant} />
        </>
        )}
        {loading && (
        <ViewSpinner />
        )}
      </div>
    </Drawer>
  );
};

ViewDrawer.propTypes = {
  handleClose: func.isRequired,
  showDrawer: bool.isRequired,
  messageId: string.isRequired,
};

export default ViewDrawer;
