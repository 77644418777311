import React, { useState, useContext, useEffect } from 'react';
import {
  Button, Card, Col, Row, notification, Space, Popconfirm, message, Empty,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../appContext';
import SubjectModal from './addSubject';
import {
  ADD_SUBJECT, GET_SINGLE_COHORT, DELETE_SUBJECT, UPDATE_SUBJECT,
} from '../../../graphql';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

export default () => {
  const { id: cohortId } = useParams();
  const [addSubject, { loading: addLoading, data: subjectPayload }] = useMutation(ADD_SUBJECT);
  const [deleteSubject] = useMutation(DELETE_SUBJECT);
  const [updateSubject] = useMutation(UPDATE_SUBJECT);
  const { userInfo: { id: userId } } = useContext(AuthContext);
  const { data: cohortRes, loading: cohortLoading } = useQuery(GET_SINGLE_COHORT,
    { variables: { where: { id: cohortId } } });
  const [modelaConfig, setModalConfig] = useState({ mode: 'ADD' });
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [cohort, setCohort] = useState(null);
  const [subjects, setSubject] = useState([]);
  const { goBack } = useHistory();
  const handleAddClick = () => {
    setModalConfig({ mode: 'ADD' });
    setShowSubjectModal(true);
  };

  const handleEditClick = (id, name) => {
    setModalConfig({ mode: 'EDIT', id, name });
    setShowSubjectModal(true);
  };
  const handleModalSubmit = async (name, config) => {
    try {
      if (config.mode === 'ADD') {
        await addSubject({
          variables: {
            input: {
              name,
              cohort: { connect: { id: cohortId } },
              user: { connect: { id: userId } },
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createSubject: {
              __typename: 'Subject',
              name,
            },
          },
          update: (proxy, { data: { createSubject } }) => {
            const data = proxy.readQuery({ query: GET_SINGLE_COHORT, variables: { where: { id: cohortId } } });
            proxy.writeQuery({
              query: GET_SINGLE_COHORT,
              variables: { where: { id: cohortId } },
              data: {
                cohort: {
                  ...data,
                  Subject: [...data.cohort.Subject, createSubject],
                },
              },
            });
          },
        });
      } else if (config.mode === 'EDIT') {
        const { id } = config;
        updateSubject({
          variables: {
            where: { id },
            data: { name: { set: name } },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateSubject: {
              __typename: 'Subject',
              id,
              name,
            },
          },
          update: (proxy, { data: { updateSubject: mutate } }) => {
            const { name: subjectName, id: updateId } = mutate;
            const data = proxy.readQuery({ query: GET_SINGLE_COHORT, variables: { where: { id: cohortId } } });
            const { Subject } = data.cohort;
            const newSubject = Subject.map(({ id: subjectId, ...rest }) => {
              if (subjectId === updateId) return { ...rest, name: subjectName, id };
              return { ...rest, id };
            });
            proxy.writeQuery({
              query: GET_SINGLE_COHORT,
              variables: { where: { id: cohortId } },
              data: {
                cohort: {
                  ...data,
                  Subject: newSubject,
                },
              },
            });
          },
        });
        setShowSubjectModal(false);
      }
      return;
    } catch (error) {
      console.log(error);
      notification.error({ message: 'Could not add subject' });
    }
  };
  useEffect(() => {
    if (!addLoading && subjectPayload) {
      notification.success({ message: 'New Subject Added', description: "You've successfully created a new subject" });
      setShowSubjectModal(false);
    }
  }, [addLoading, subjectPayload]);

  useEffect(() => {
    if (!cohortLoading && cohortRes) {
      const { cohort: cohortData } = cohortRes;
      const { Subject, ...rest } = cohortData;
      setCohort(rest);
      setSubject([...Subject].reverse());
    }
  }, [cohortRes, cohortLoading]);

  const handleSubjectDelete = async (subjectId) => {
    try {
      await deleteSubject({
        variables: { where: { id: subjectId } },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteSubject: {
            __typename: 'Subject',
            id: subjectId,
          },
        },
        update: (proxy, { data: { deleteSubject: mutate } }) => {
          const data = proxy.readQuery({ query: GET_SINGLE_COHORT, variables: { where: { id: cohortId } } });
          const { id: deleteId } = mutate;
          proxy.writeQuery({
            query: GET_SINGLE_COHORT,
            variables: { where: { id: cohortId } },
            data: {
              cohort: {
                ...data,
                Subject: data.cohort.Subject.filter(({ id }) => id !== deleteId),
              },
            },
          });
        },
      });
      message.success('Subject deleted');
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  return (
    <div>
      {!cohortLoading && (
      <Row>
        <SubjectModal
          loading={addLoading}
          handleCancel={() => setShowSubjectModal(false)}
          handleSubmit={handleModalSubmit}
          visible={showSubjectModal}
          config={modelaConfig}
        />
        <Col span={24}>
          <Card className="gx-card gx-mb-3">
            <div className="cohort-detail">
              {cohort && (
              <>
                <div>
                  <h4>{cohort.name}</h4>
                  <small>{`${subjects.length} subject, created ${new Date(cohort.createdAt).toLocaleDateString()}`}</small>
                </div>
                <Button onClick={goBack} size="middle" shape="circle" icon={<ArrowLeftOutlined />} />
              </>
              )}
            </div>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="gx-card gx-pb-0">
            <div className="subject-header">
              <h5>Subjects</h5>
              <Button onClick={handleAddClick} type="primary">Add</Button>
            </div>
            {
              subjects.map(({ id: subjectId, name }) => (
                <div key={subjectId} className="subject-item">
                  <h5>{name}</h5>
                  <Space size="small">
                    <Button onClick={() => handleEditClick(subjectId, name)} type="text">
                      Edit
                    </Button>
                    <Button danger type="text">
                      <Popconfirm
                        title="Are you sure want to delete"
                        onConfirm={() => handleSubjectDelete(subjectId)}
                        onCancel={() => {}}
                        okText="Delete"
                        cancelText="Cancel"
                        placement="topLeft"
                      >
                        Delete
                      </Popconfirm>
                    </Button>
                  </Space>
                </div>
              ))
            }
            {!cohortLoading && subjects.length === 0 && <Empty description="No subjects yet" />}
          </Card>
        </Col>
      </Row>
      )}
      {cohortLoading && <ViewSpinner />}
    </div>
  );
};
