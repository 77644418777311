import { createContext } from 'react';

export const LearnContext = createContext({});
export const LearnProvider = LearnContext.Provider;
export const LearnConsumer = LearnContext.Consumer;

export const learnActionTypes = {
  SET_SUBJECT: 'SET_SUBJECT',
  SET_SELECTED_TOPIC: 'SET_SELECTED_TOPIC',
};

export const initialState = {
  subject: null,
  selectedTopic: {
    index: 0,
    title: null,
  },
};

export const learnReducer = (state = initialState, action) => {
  switch (action.type) {
    case learnActionTypes.SET_SELECTED_TOPIC:
      return {
        ...state, selectedTopic: action.payload,
      };
    case learnActionTypes.SET_SUBJECT:
      return {
        ...state,
        subject: action.payload,
      };
    default:
      return state;
  }
};
