import React, { useState, useEffect } from 'react';
import {
  Row, Col, Input, Button, Space, Form, Card,
} from 'antd';
import parse from 'html-react-parser';
import { capitalize } from 'lodash';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { GET_STUDENT_ACTIVITY } from '../../../../graphql';
import SubmitForm from './SubmitForm';
import { STUDENT_ACTIVITIES } from '../../../../routes';
import ViewSpinner from '../../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const { useForm } = Form;
const ActivityDetail = () => {
  const [showSubmit, setShowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);
  const [submission, setSubmission] = useState(null);
  const { id } = useParams();
  const { data: activityPayload, loading: activityLoading } = useQuery(GET_STUDENT_ACTIVITY, {
    variables: { where: { id } },
  });
  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const {
        studentActivity: {
          activity: activityRes,
          submission: submissionRes,
        },
      } = activityPayload;
      setSubmission(submissionRes);
      setActivity(activityRes);
    }
  }, [activityLoading, activityPayload]);
  const [form] = useForm();

  return (
    <div className="student-activity-details">
      {!activityLoading && activity && (
        <>
          <Row>
            <Col span={24}>
              <Link to={STUDENT_ACTIVITIES}>
                <Button>
                  <ArrowLeftOutlined />
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <h4 className="ic-ft-dosis top">
                {capitalize(activity.type.split('_').join(' '))}
                {' '}
                -
                {' '}
                <span>{activity.cohort.name}</span>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col className="activity-column" span={24}>
              <h4 className="key">Activity Name</h4>
              <Input className="input turnoff" disabled size="large" value={activity.title} />
            </Col>
            <Col className="activity-column" span={24}>
              <h4 className="key">Instruction</h4>
              <div className="instruction">
                {parse(activity.instruction)}
              </div>
            </Col>
            {activity.attachments && activity.attachments.length > 0 && (
              <Col span={24} className="activityFiles">
                <h4>Attached files (click to download)</h4>
                {activity.attachments.map(({ url, filename, id: attachId }) => (
                  <h6 key={attachId}>
                    <RightCircleOutlined />
                    {' '}
                    <a rel="noopener noreferrer" target="_blank" href={url}>{filename.slice(11)}</a>
                  </h6>
                ))}
              </Col>
            )}
          </Row>
          {showSubmit && (
          <SubmitForm
            setLoading={setLoading}
            activity={activity}
            studentActivityId={id}
            form={form}
          />
          )}
          {submission && (
            <Row className="submitted">
              <Col span={24}><h4>You have already submitted this activity</h4></Col>
              <Col span={24}>
                <Card>
                  <h4>You&apos;ve submitted</h4>
                </Card>
              </Col>
            </Row>
          )}
          {!submission && (
            <Row>
              <Col span={24} className="control">
                {!showSubmit && (
                <Button onClick={() => setShowSubmit(!showSubmit)} size="large" className="btn-student">
                  Proceed
                </Button>
                )}
                {showSubmit && (
                <Space>
                  <Button className="cancel-btn" onClick={() => setShowSubmit(!showSubmit)} size="large">
                    Cancel
                  </Button>
                  <Button loading={loading} disabled={loading} onClick={() => form.submit()} size="large" className="btn-student">
                    {activity.embedLink || activity.type === 'ONLINE_CLASS' ? 'Complete Activity' : 'Submit Activity'}
                  </Button>
                </Space>
                )}
              </Col>
            </Row>
          )}
        </>
      )}
      {activityLoading && <ViewSpinner />}
    </div>
  );
};

export default ActivityDetail;
