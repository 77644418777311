// this is the authentication context, void of reducer
import React, { createContext, useState, useEffect } from 'react';
import { node } from 'prop-types';
import { notification } from 'antd';
import client from '../ApolloClient';
import { GET_TOKEN_USER } from '../graphql';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [authStateLoading, setAuthStateLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const revokeAuth = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserInfo(null);
  };
  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const userExisting = JSON.parse(localStorage.getItem('user'));
          const { data: { User } } = await client.query({
            query: GET_TOKEN_USER,
            variables: { id: userExisting.id },
          });
          setUserInfo(User);
          setAuthStateLoading(false);
          setIsAuthenticated(true);
        } catch (error) {
          revokeAuth();
          setAuthStateLoading(false);
          setIsAuthenticated(false);
          notification.info({
            message: 'Account Login',
            description: 'Please login with your username and password',
          });
        }
      } else {
        setAuthStateLoading(false);
        setIsAuthenticated(false);
      }
    })();
  }, []);
  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      userInfo,
      authStateLoading,
      setUserInfo,
      setIsAuthenticated,
      revokeAuth,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: node.isRequired,
};
