import React, { useContext } from 'react';
import { AuthContext } from '../../appContext';
import { instructorAdmin, instructor, student } from './dashRoutes';
import InstructorDashboard from './Instructor';
import StudentDashboard from './Student';

export default () => {
  const { userInfo } = useContext(AuthContext);
  const { accountType, role } = userInfo;
  return (
    <>
      {accountType === 'TEACHER' ? (
        <>
          {role === 'ADMIN' ? <InstructorDashboard routes={instructorAdmin} /> : <InstructorDashboard routes={instructor} />}
        </>
      ) : <StudentDashboard routes={student} />}
    </>
  );
};
