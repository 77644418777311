import React from 'react';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DELETE_ACTIVITY, GET_ALL_ACTIVITY } from '../../../graphql';
import client from '../../../ApolloClient';

const { confirm } = Modal;

const deleteConfirm = (id) => {
  confirm({
    title: 'Are you sure you want to delete this activity?',
    icon: <ExclamationCircleOutlined />,
    content: 'Deleting this activity would also delete on the student dashboard',
    onOk: () => client.mutate({
      mutation: DELETE_ACTIVITY,
      variables: { where: { id } },
      update: (proxy) => {
        const { activities } = proxy.readQuery({ query: GET_ALL_ACTIVITY });
        const newActivity = activities.filter(({ id: activityId }) => activityId !== id);
        proxy.writeQuery({
          query: GET_ALL_ACTIVITY,
          data: {
            activities: newActivity,
          },
        });
      },
    }).then(() => {
      message.success('Activity Deleted Successfully');
    }).catch(() => {
      message.error('Could not delete activity');
    }),
    onCancel() {},
  });
};

export default deleteConfirm;
