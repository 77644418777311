import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import {
  Card, Button, message,
} from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { AuthContext } from '../../../../appContext';
import { STUDENT_SUPPORT } from '../../../../routes';
import { GET_SUPPORT_WITH_REPLIES, CREATE_SUPPORT_REPLY } from '../../../../graphql';
import { SentMessageCell, ReceivedMessageCell } from '../../../support/replies/MessageCells';
import SupportFooter from './footer';
import './styles.less';

const Replies = () => {
  const { id } = useParams();
  const { userInfo } = useContext(AuthContext);
  const [replies, setReplies] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [firstMessage, setFirstMessage] = useState(null);
  const scrollBarStudent = useRef(null);
  const [replySupport] = useMutation(CREATE_SUPPORT_REPLY);
  const { data: supportPayload, loading: supportLoading } = useQuery(GET_SUPPORT_WITH_REPLIES, {
    variables: { where: { id } },
    pollInterval: 1000,
  });

  useEffect(() => {
    if (!supportLoading && supportPayload) {
      try {
        const { support } = supportPayload;
        const { replies: repliesRes, ...ticketRes } = support;
        setReplies(repliesRes);
        setFirstMessage({
          message: ticketRes.description,
          createdAt: ticketRes.createdAt,
          name: `${ticketRes.firstName} ${ticketRes.firstName}`,
          avatar: ticketRes.user.profileStudent.profileImg || '',
        });
        setTicket(ticketRes);
      } catch (error) {
        message.error('Error, could not read replies');
      }
    }
  }, [supportPayload, supportLoading]);

  useEffect(() => {
    if (scrollBarStudent.current) {
      scrollBarStudent.current.scrollToBottom();
    }
  }, [supportPayload, ticket]);

  const handleReply = (replyMessage) => {
    replySupport({
      variables: {
        data: {
          message: replyMessage,
          Support: { connect: { id } },
          user: { connect: { id: userInfo.id } },
        },
      },
    });
  };
  return (
    <Card
      style={{ marginTop: '-1rem' }}
      title={(<h5>{ticket ? startCase(ticket.title) : 'loading..'}</h5>)}
      className="reply-card"
      extra={(
        <Link to={STUDENT_SUPPORT}>
          <Button type="primary" className="gx-m-0" size="small">
            {' '}
            <LeftCircleOutlined />
            {' '}
            Back to Supports
          </Button>
        </Link>
    )}
    >
      <div className="gx-chat-main gx-h-100 gx-justify-content-around ">
        {supportPayload && ticket && (
          <>
            <Scrollbars ref={scrollBarStudent} style={{ height: 100 }} autoHide className="gx-chat-list-scroll">
              <div className="gx-chat-main-content">
                <SentMessageCell conversation={firstMessage} />
                {replies.map(({ id: replyId, ...reply }) => {
                  const {
                    user: {
                      id: userId, firstName, lastName, profileTeacher,
                    }, message: replyMessage, createdAt,
                  } = reply;
                  const conversation = {
                    message: replyMessage,
                    createdAt,
                    name: `${firstName} ${lastName}`,
                  };
                  if (userId === userInfo.id) {
                    conversation.avatar = ticket.user.profileStudent.profileImg || '';
                    return (
                      <SentMessageCell key={replyId} conversation={conversation} />
                    );
                  }
                  conversation.avatar = profileTeacher ? profileTeacher.profileImg : '';
                  return (
                    <ReceivedMessageCell key={replyId} conversation={conversation} />
                  );
                })}
              </div>
            </Scrollbars>
            <SupportFooter isClosed={ticket.isClosed} handleReply={handleReply} />
          </>
        )}
      </div>
    </Card>
  );
};
export default Replies;
