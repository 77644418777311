import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dropdown, Button, Col, Menu,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { SETUP_USER_NEW_R } from '../../../../routes';

const DropdownBtn = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { push } = useHistory();
  const handleMenuClick = ({ key }) => push(`${SETUP_USER_NEW_R}${key}`);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="student">Student</Menu.Item>
      <Menu.Item key="teacher">Teacher</Menu.Item>
    </Menu>
  );

  return (
    <Col className="gx-mr-3 gx-mb-4">
      <Dropdown overlay={menu} onVisibleChange={() => setIsOpen(!isOpen)} trigger={['click']}>
        <span className="gx-d-inline-flex">
          <Button type="primary" className="gx-mb-0">
            Add New User
            {' '}
            {!isOpen ? <DownOutlined className="gx-fs-xs" /> : <UpOutlined className="gx-fs-xs" /> }
          </Button>
        </span>
      </Dropdown>
    </Col>
  );
};

export default DropdownBtn;
