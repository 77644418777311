import React from 'react';
import { Avatar } from 'antd';
import {
  func, shape, string,
} from 'prop-types';
import { truncate } from 'lodash';
import moment from 'moment';

const TicketCell = ({ ticket, selectedTicketId, onSelectTicket }) => (
  <div
    className={`gx-chat-user-item ${selectedTicketId === ticket.id ? 'active' : ''}`}
    onClick={() => {
      onSelectTicket(ticket);
    }}
    role="presentation"
  >
    <div className="gx-chat-user-row">
      <div className="gx-chat-avatar">
        <div className="gx-status-pos">
          <Avatar src={ticket.user.profileStudent ? ticket.user.profileStudent.profileImg : null} className="gx-size-40" alt={ticket.title}>
            {ticket.user.firstName[0]}
          </Avatar>
          <span className={`gx-status gx-small gx-${ticket.status}`} />
        </div>
      </div>

      <div className="gx-chat-info">
        <span className="gx-name h4">{truncate(`${ticket.user.firstName} ${ticket.user.lastName}`, { length: 20 })}</span>
        <div className="gx-chat-info-des gx-text-truncate">{truncate(ticket.title, { length: 50 })}</div>
        <div className="gx-last-message-time gx-mt-1">{moment(ticket.createdAt).calendar()}</div>
      </div>
      <div className="gx-chat-date">
        <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">{ticket.replyCount}</div>
      </div>
    </div>
  </div>
);

TicketCell.defaultProps = {
  selectedTicketId: null,
};

TicketCell.propTypes = {
  ticket: shape({}).isRequired,
  selectedTicketId: string,
  onSelectTicket: func.isRequired,
};

export default TicketCell;
