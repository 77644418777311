import React from 'react';
import { Avatar } from 'antd';
import { upperCase } from 'lodash';
import { shape } from 'prop-types';
import moment from 'moment';

export const SentMessageCell = ({ conversation }) => (
  <div className="gx-chat-item gx-flex-row-reverse">
    <Avatar
      className="gx-size-40 gx-align-self-end"
      src={conversation.avatar}
      alt={conversation.name}
      style={{ backgroundColor: '#87d068' }}
    >
      {conversation.name ? upperCase(conversation.name[0]) : 'U'}
    </Avatar>
    <div className="gx-bubble-block">
      <div className="gx-bubble">
        <div className="gx-message">{conversation.message}</div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
          you
        </div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
          {moment(conversation.createdAt).calendar()}
        </div>
      </div>
    </div>
  </div>
);

export const ReceivedMessageCell = ({ conversation }) => (
  <div className="gx-chat-item">
    <Avatar
      className="gx-size-40 gx-align-self-end"
      src={conversation.avatar || ''}
      alt={conversation.name}
      style={{ backgroundColor: '#87d068' }}
    >
      {conversation.name ? upperCase(conversation.name[0]) : 'U'}
    </Avatar>
    <div className="gx-bubble-block">
      <div className="gx-bubble">
        <div className="gx-message">{conversation.message}</div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
          {conversation.name}
        </div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
          {moment(conversation.createdAt).calendar()}
        </div>
      </div>
    </div>

  </div>
);

ReceivedMessageCell.propTypes = {
  conversation: shape({}).isRequired,
};

SentMessageCell.propTypes = {
  conversation: shape({}).isRequired,
};
