import React from 'react';
import { Link } from 'react-router-dom';
import {
  Divider, Tag, Popconfirm,
} from 'antd';
import { SETUP_USER_DETAIL_R } from '../../../../routes';

export default (handleDeleteUser) => [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Account Type',
    dataIndex: 'accountType',
    key: 'accountType',
    render: (text) => {
      if (text === 'STUDENT') return <Tag color="#2db7f5">{text}</Tag>;
      if (text === 'ADMIN') return <Tag color="#87d068">{text}</Tag>;
      if (text === 'TEACHER') return <Tag color="#108ee9">{text}</Tag>;
    },
  },
  {
    title: 'Cohorts',
    dataIndex: 'UserCohort',
    key: 'cohorts',
    render: (userCohort) => (
      <span>
        {userCohort.length > 0 ? (
          userCohort.map(({ id, cohort: { name } }) => (
            <Tag key={id}>{name}</Tag>
          ))
        ) : <span>None</span>}
      </span>
    ),
  },
  {
    title: 'Username',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text, record) => (
      <span>
        <Link to={`${SETUP_USER_DETAIL_R}${record.id}`}>
          {' '}
          <span className="gx-link gx-link-table">View</span>
        </Link>
        <Divider type="vertical" />
        <Popconfirm
          title="Are you sure want to delete"
          onConfirm={() => handleDeleteUser(record.id)}
          onCancel={() => {}}
          okText="Delete"
          cancelText="Cancel"
        >
          <span style={{ color: '#f50', fontWeight: '400' }} className="gx-link">Delete</span>
        </Popconfirm>

      </span>
    ),
  },
];
