import React from 'react';
import {
  Modal, Button, Form, Input,
} from 'antd';
import { func, bool } from 'prop-types';
import { capitalize } from 'lodash';

const { Item: FormItem, useForm } = Form;
const { TextArea } = Input;
const AddCohort = ({
  visible, handleAddCohort, handleCancel, addLoading,
}) => {
  const [form] = useForm();
  const handleFormSubmit = async (inputs) => {
    const { name, description } = inputs;
    await handleAddCohort({ name: capitalize(name), description });
    form.resetFields();
  };
  return (
    (
      <Modal
        visible={visible}
        title="New Cohort"
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <FormItem name="name" label="Cohort Name" rules={[{ required: true, message: 'Provide a valid name' }]}>
            <Input placeholder="cohort name" />
          </FormItem>
          <FormItem name="description" label="Description">
            <TextArea placeholder="write some description..." />
          </FormItem>
          <FormItem className="gx-mb-0">
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={addLoading}>
              Add Cohort
            </Button>
          </FormItem>
        </Form>
      </Modal>
    )
  );
};

AddCohort.propTypes = {
  visible: bool.isRequired,
  handleAddCohort: func.isRequired,
  handleCancel: func.isRequired,
  addLoading: bool.isRequired,
};

export default AddCohort;
