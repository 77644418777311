import React from 'react';
import {
  VimePlayer, VimeUi, VimeYoutube,
} from '@vime/react';
import { string } from 'prop-types';

const Player = ({ videoId }) => (
  <VimePlayer playsinline controls>
    <VimeYoutube videoId={videoId} />
    <VimeUi />
  </VimePlayer>
);

Player.propTypes = {
  videoId: string.isRequired,
};

export default Player;
