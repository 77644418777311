import React, { useReducer } from 'react';
import { Layout } from 'antd';
import { shape, arrayOf } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { LearnProvider, learnReducer, initialState } from '../../appContext/learnContext';
import { StudentSidebar as Sidebar } from '../../components/Sidebar/index';
import TopBar from '../../components/topBar';
import { footerText } from '../../util/config';

const { Content, Footer } = Layout;
const StudentDashboard = ({ routes }) => {
  const [learnState, learnDispatch] = useReducer(learnReducer, initialState);
  return (
    <LearnProvider value={{ learnState, learnDispatch }}>
      <Layout className="gx-app-layout">
        <Sidebar routes={routes} />
        <Layout>
          <TopBar />
          <Content className="gx-layout-content">
            <div style={{ background: '#FAFBFC' }} className="gx-main-content-wrapper">
              <Switch>
                {routes.map(({
                  exact, path, view, key,
                }) => (
                  <Route exact={exact} path={path} component={view} key={key} />
                ))}
              </Switch>
            </div>
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </LearnProvider>
  );
};
StudentDashboard.propTypes = {
  routes: arrayOf(shape({})).isRequired,
};

export default StudentDashboard;
