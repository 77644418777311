import React, { useEffect, useState } from 'react';
import {
  message, Card, Table, Col, Button, Space,
} from 'antd';
import { useQuery } from '@apollo/client';
import { func } from 'prop-types';
import { GET_ALL_USERS, GET_ALL_COHORT } from '../../../../graphql';
import Filter from './filter';
import columns from './column';
import { applyFilter } from '../../../../util/common';

const UserHome = ({ setStep, selectComplete, onSelectChange }) => {
  const {
    loading: userLoading, data: userPayload, error: userError,
  } = useQuery(GET_ALL_USERS, { variables: { orderBy: { createdAt: 'desc' } } });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { loading: cohortLoading, data: cohortPayload } = useQuery(GET_ALL_COHORT);
  const [users, setUsers] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [filterValue, setFilterValue] = useState({ cohortId: null, accountType: null, search: null });

  useEffect(() => {
    if (!userLoading && userPayload) {
      const { Users } = userPayload;
      const filterDefault = applyFilter(Users, { cohortId: null, accountType: 'STUDENT' });
      setFilterValue({ ...filterValue, accountType: 'STUDENT' });
      const userRev = [...filterDefault];
      setUsers(userRev);
    }
    // eslint-disable-next-line
  }, [userLoading, userPayload]);

  useEffect(() => {
    if (!cohortLoading && cohortPayload) {
      const { getAllCohort } = cohortPayload;
      setCohorts(getAllCohort);
    }
  }, [cohortLoading, cohortPayload]);

  if (userError) {
    message.error('Error, could not fetch users');
  }

  const handleFiltering = (actionType, value) => {
    if (!userPayload || !userPayload.Users) return;
    const { Users } = userPayload;
    switch (actionType) {
      case 'ACCOUNT_TYPE': {
        if (value === 'ALL') {
          setFilterValue({ ...filterValue, accountType: null });
          const matches = applyFilter(Users, { ...filterValue, accountType: null });
          return setUsers(matches);
        }
        setFilterValue({ ...filterValue, accountType: value });
        const filtered = applyFilter(Users, { ...filterValue, accountType: value });
        return setUsers(filtered);
      }
      case 'SEARCH': {
        if (value === '') {
          setFilterValue({ ...filterValue, search: null });
          const match = applyFilter(Users, { ...filterValue, search: null });
          return setUsers(match);
        }
        setFilterValue({ ...filterValue, search: value });
        const filtered = [...users].filter(({ firstName, lastName, email }) => {
          const name = `${firstName} ${lastName}`.toLocaleLowerCase();
          const valueFormatted = value.toLocaleLowerCase();
          const emailFormatted = email.toLocaleLowerCase();
          return name.includes(valueFormatted) || emailFormatted.includes(valueFormatted);
        });
        return setUsers(filtered);
      }
      case 'COHORT_NAME': {
        if (value === 'ALL') {
          setFilterValue({ ...filterValue, cohortId: null });
          const reset = applyFilter(Users, { ...filterValue, cohortId: null });
          return setUsers(reset);
        }
        setFilterValue({ ...filterValue, cohortId: value });
        const match = applyFilter(Users, { ...filterValue, cohortId: value });
        return setUsers(match);
      }
      default: {
        return setUsers(Users);
      }
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
      onSelectChange(keys);
    },
  };
  return (
    <Col span={24} className="gx-pr-0">
      <Card
        title="Select Recipiant"
        extra={(
          <Space>
            <Button onClick={() => setStep(1)} size="middle" className="gx-m-0">Back</Button>
            <Button onClick={selectComplete} size="middle" className="gx-m-0" type="primary">Complete and Send</Button>
          </Space>
        )}
      >
        <Filter onFilterChange={handleFiltering} cohorts={cohorts} />
        <Table rowSelection={rowSelection} rowKey="id" loading={userLoading} columns={columns} dataSource={users} />
      </Card>
    </Col>
  );
};

UserHome.propTypes = {
  setStep: func.isRequired,
  selectComplete: func.isRequired,
  onSelectChange: func.isRequired,
};
export default UserHome;
