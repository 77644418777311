import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar, Button, Modal, message,
} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  func, shape, arrayOf,
} from 'prop-types';
import { upperCase } from 'lodash';
import Conversation from './Conversation';

const { confirm } = Modal;
const RepliesItem = ({
  replies, onReply, selectedTicket, setDrawerState, closeSupport,
}) => {
  const [reply, setReply] = useState();
  const scrollBar = useRef(null);

  const handleReply = () => {
    if (reply === '') return;
    setReply('');
    onReply(reply);
    scrollBar.current.scrollToBottom();
  };
  useEffect(() => {
    scrollBar.current.scrollToBottom();
  }, [selectedTicket, replies]);

  const showConfirm = (id) => {
    confirm({
      title: 'Do you wish to close this support',
      icon: <ExclamationCircleOutlined />,
      content: 'closing this support would prevent further replies',
      onOk() {
        return closeSupport(id);
      },
      onCancel() {
        message.info('Canceled');
      },
    });
  };

  return (
    <div className="gx-chat-main">
      <div className="gx-chat-main-header">
        <span className="gx-d-block gx-d-lg-none gx-chat-btn">
          <i
            role="presentation"
            className="gx-icon-btn icon icon-chat"
            onClick={() => setDrawerState(true)}
          />
        </span>
        <div className="gx-chat-main-header-info">
          {selectedTicket && (
            <>
              <div className="gx-chat-avatar gx-mr-2">
                <div className="gx-status-pos">
                  <Avatar
                    src={selectedTicket.user ? selectedTicket.user.profileStudent.profileImg : ''}
                    className="gx-rounded-circle gx-size-60"
                    alt=""
                    style={{ backgroundColor: '#87d068' }}
                  >
                    {selectedTicket.user.firstName ? upperCase(selectedTicket.user.firstName[0]) : 'U'}
                  </Avatar>
                  <span className="gx-status gx-online" />
                </div>
              </div>
              <div className="gx-chat-contact-name">
                {`${selectedTicket.user.firstName} ${selectedTicket.user.lastName}`}
                <h4>{selectedTicket.title}</h4>
              </div>
              <div className="close-container">
                <Button type="primary" onClick={() => showConfirm(selectedTicket.id)} size="small">Close Support</Button>
              </div>
            </>
          )}
        </div>

      </div>
      <Scrollbars ref={scrollBar} style={{ height: 100 }} autoHide className="gx-chat-list-scroll">
        <Conversation
          conversationData={replies}
          selectedTicket={selectedTicket}
        />
      </Scrollbars>
      <div className="gx-chat-main-footer">
        <div className="gx-flex-row gx-align-items-center" style={{ maxHeight: 51 }}>
          <div className="gx-col">
            <div className="gx-form-group">
              <textarea
                id="required"
                className="gx-border-0 ant-input gx-chat-textarea"
                onChange={(e) => setReply(e.target.value)}
                placeholder="Type in a reply"
                value={reply}
              />
            </div>
          </div>
          <i role="presentation" className="gx-icon-btn icon icon-sent" onClick={handleReply} />
        </div>
      </div>
    </div>
  );
};
RepliesItem.defaultProps = {
  selectedTicket: {},
};

RepliesItem.propTypes = {
  replies: arrayOf(shape({})).isRequired,
  onReply: func.isRequired,
  selectedTicket: shape({}),
  setDrawerState: func.isRequired,
  closeSupport: func.isRequired,
};

export default RepliesItem;
