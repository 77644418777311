import { gql } from '@apollo/client';

export const GET_ALL_ACTIVITY = gql`
	query getAllActivities($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $take: Int) {
		activities(where: $where, orderBy: $orderBy, take: $take) {
			id
			title
			type
			hasTextInput
			instruction
			embedLink
			dueDate
			createdAt
			cohort {
				id
				name
			}
			user {
				id
				firstName
				lastName
    	}
			subjects {
				id
				name
			}
			submissions{
      	id
    	}
			attachments {
				url
				filename
				id
				mimetype
			}
		}
	}
`;

export const GET_SINGLE_ACTIVITY = gql`
	query getActivity($where: ActivityWhereUniqueInput!) {
		activity(where: $where) {
			id
			title
			type
			hasTextInput
			embedLink
			instruction
			dueDate
			createdAt
			cohort {
				name
			}
			subjects {
				id
				name
			}
			attachments {
				url
				filename
				id
				mimetype
			}
		}
	}
`;

// --------------------- studentActivity -----------------

export const GET_STUDENT_ACTIVITIES = gql`
	query getStudentActivities($where: StudentActivityWhereInput, $orderBy: [StudentActivityOrderByWithRelationInput!]) {
		studentActivities(where: $where, orderBy: $orderBy) {
			id
			status
			user {
				id
				email
				firstName
				accountType
				lastName
			}
			activity {
				id
				type
				title
				instruction
				hasTextInput
				dueDate
				embedLink
				cohort{
					id
					name
				}
				attachments {
					id
					url
					filename
				}
			}
			submission {
				id
				rating
				status
				attachments {
					id
					url
				}
			}
		}
	}
`;

export const GET_STUDENT_ACTIVITY = gql`
	query getStudentActivity($where: StudentActivityWhereUniqueInput!) {
		studentActivity(where: $where) {
			id
			status
			user {
				id
				email
				firstName
				accountType
				lastName
			}
			activity {
				id
				title
				type
				instruction
				hasTextInput
				dueDate
				embedLink
				cohort{
					id
					name
				}
				attachments {
					id
					url
					filename
				}
			}
			submission {
				id
				rating
				status
				attachments {
					id
					url
				}
			}
		}
	}
`;


// ------------------------------ Activity submission ---------------
export const GET_ACTIVITY_SUBMISSIONS = gql`
	query getActivitySubmissions($where: SubmissionWhereInput) {
		submissions(where: $where){
			id
			textContent,
			status,
			rating
			createdAt
			activity{
				id
				title
			}
			studentActivity {
				user {
					id
					firstName
					lastName
					profileStudent {
						admissionNumber
					}
				}
			}
		}
	}
`;

export const GET_ACTIVITY_SUBMISSION = gql`
	query getActivitySubmission($where: SubmissionWhereUniqueInput!) {
		submission(where: $where){
			id
			textContent,
			status,
			rating
			createdAt
			activity{
				id
				title
			}
			studentActivity {
				user {
					id
					firstName
					lastName
					profileStudent {
						admissionNumber
					}
				}
			}
			attachments {
				id
				url
				filename
    	}
		}
	}
`;