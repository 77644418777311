import React, { useEffect, useContext } from 'react';
import {
  Form, Input, Button, message, notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { DASHBOARD_HOME, ACCOUNT_PATH, STUDENT_HOME } from '../../routes';
import { AuthContext } from '../../appContext';
import { LOGIN_USER } from '../../graphql';
import DecoCenter from '../../components/DecoCenter';
import './styles.less';

const SignIn = () => {
  const [form] = Form.useForm();
  const [loginUser, { data, loading }] = useMutation(LOGIN_USER);
  const {
    setUserInfo, setIsAuthenticated, isAuthenticated, userInfo,
  } = useContext(AuthContext);
  const { push } = useHistory();

  useEffect(() => {
    if (!loading && data) {
      setIsAuthenticated(true);
      const { loginUser: user } = data;
      const { token } = user;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserInfo(user);
      notification.success({
        message: 'Login Success',
        description: "You've successfully Logged in",
      });
      if (user.accountType === 'STUDENT') {
        push(STUDENT_HOME);
      } else {
        push(DASHBOARD_HOME);
      }
    }
  }, [loading, data, push, setIsAuthenticated, setUserInfo]);

  useEffect(() => {
    if (isAuthenticated) {
      const { accountType } = userInfo;
      if (accountType === 'STUDENT') {
        return push(STUDENT_HOME);
      }
      push(DASHBOARD_HOME);
    }
    // eslint-disable-next-line
  }, [push, isAuthenticated]);

  const handleFormFinish = async (input) => {
    const { userName, password } = input;
    try {
      await loginUser({ variables: { userName, password } });
    } catch (_err) {
      if (_err.message && _err.message.includes('Failed to fetch')) {
        return message.error('Sign in Fail, Network error');
      }
      message.error('Sign in Fail, Invalid email or password');
    }
  };

  return (
    <DecoCenter>
      <Form layout="vertical" requiredMark={false} form={form} onFinish={handleFormFinish} name="signIn_form" className="form-wrapper">
        <div className="gx-login-text">
          <h3 className="gx-m-3 gx-fs-xl">Account Login</h3>
        </div>
        <Form.Item label="Username" name="userName" rules={[{ required: true, type: 'string', message: 'provide a username' }]} className="input-field">
          <Input size="large" placeholder="enter your username" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'provide a valid password', min: 4 }]} className="input-field">
          <Input.Password size="large" placeholder="enter your password" type="password" />
        </Form.Item>
        <Form.Item className="input-field">
          <Button loading={loading} htmlType="submit" size="large" type="primary" className="gx-w-100">
            {loading ? 'Loading' : 'Login'}
          </Button>
        </Form.Item>
        <div className="gx-w-100  gx-p-0 gx-d-flex gx-flex-row-reverse no-m-bottom">
          <Link to={`${ACCOUNT_PATH}forget_password`}>Forgot password?</Link>
        </div>
      </Form>
    </DecoCenter>
  );
};

export default SignIn;
