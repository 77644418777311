import React, { useEffect } from 'react';
import {
  Modal, Button, Form, Input,
} from 'antd';
import { func, bool, shape } from 'prop-types';
import { capitalize } from 'lodash';

const { Item: FormItem, useForm } = Form;
const AddSubject = ({
  visible, handleSubmit, handleCancel, loading, config,
}) => {
  const [form] = useForm();
  const handleFormSubmit = async (inputs) => {
    const { name } = inputs;
    await handleSubmit(capitalize(name), config);
    form.resetFields();
  };
  useEffect(() => {
    form.resetFields();
  // eslint-disable-next-line
  }, [config]);
  return (
    (
      <Modal
        visible={visible}
        title={config.mode === 'EDIT' ? 'Edit Subject' : 'New Subject'}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name="create_topic" layout="vertical" onFinish={handleFormSubmit}>
          <FormItem initialValue={config.name ? config.name : null} name="name" label="Subject Name" rules={[{ required: true, message: 'Provide a valid subject name' }]}>
            <Input placeholder="subject name" />
          </FormItem>
          <FormItem className="gx-mb-0">
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {config.mode === 'EDIT' ? 'Save' : 'Add Subject'}
            </Button>
          </FormItem>
        </Form>
      </Modal>
    )
  );
};

AddSubject.propTypes = {
  visible: bool.isRequired,
  handleSubmit: func.isRequired,
  handleCancel: func.isRequired,
  loading: bool.isRequired,
  config: shape({}).isRequired,
};

export default AddSubject;
