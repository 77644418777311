import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { shape, arrayOf } from 'prop-types';
import { useMutation } from '@apollo/client';
import { getCountries } from 'country-state-picker';
import moment from 'moment';
import { includes } from 'lodash';
import {
  Card, Row, Col, Form, Input, Button, Select, DatePicker, Upload, notification, Avatar, Divider,
} from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { getBase64 } from '../../../../util/fileUpload';
import { SETUP_USER_HOME } from '../../../../routes';
import { REGISTER_TEACHER, UPLOAD_FILE } from '../../../../graphql';
import { makeStateOption, countriesOptions } from '../../common/formUtils';
import CohortField from '../../common/CohortField';

const { Item: FormItem, useForm } = Form;
const { Option } = Select;

const TeacherForm = ({ cohorts }) => {
  const [availableCohort, setAvailableCohort] = useState([]);
  const [states, setStates] = useState(makeStateOption('ng'));
  const [phoneCode, setPhoneCode] = useState('+234');
  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [showCohort, setShowCohort] = useState(true);
  const [profilePicture, setProfilePicture] = useState({ file: null, imgBase64: '' });
  const [registerTeacher,
    { data: response, loading: regLoading },
  ] = useMutation(REGISTER_TEACHER);
  const [form] = useForm();
  const { push } = useHistory();
  const handleFormSubmit = async (inputs) => {
    try {
      const {
        firstName,
        lastName,
        userName,
        email,
        role,
        address,
        phoneNumber,
        state,
        doe,
        kinName,
        kinPhoneNumber,
        kinAddress,
        country,
        zipCode,
        password,
      } = inputs;
      const newUser = {
        firstName, lastName, password, accountType: 'TEACHER', email, role, userName,
      };
      newUser.profileTeacher = {
        create: {
          address,
          phoneNumber: `${phoneCode}${phoneNumber}`,
          state,
          doe: moment(doe).toLocaleString(),
          kinName,
          kinPhoneNumber,
          kinAddress,
          country,
          zipCode,
        },
      };
      if (profilePicture.file) {
        const { file } = profilePicture;
        const { data: { createFile: { url } } } = await fileUpload({ variables: { file, folder: 'profileImg' } });
        newUser.profileTeacher.create.profileImg = url;
      }
      if (inputs.userCohort) {
        if (inputs.userCohort.length > 0) {
          const selectedCohorts = [];
          let selectedSubjects = [];
          inputs.userCohort.forEach((selection) => {
            if (selection.cohort) selectedCohorts.push(selection.cohort);
            if (selection.subject) {
              if (!includes(selection.subject, 'ALL')) {
                selectedSubjects = [...selectedSubjects, ...selection.subject];
                return selectedSubjects;
              }
              const cohort = availableCohort.filter(({ id }) => id === selection.cohort)[0];
              if (cohort.Subject) {
                const { Subject } = cohort;
                selectedSubjects = Subject.map(({ id }) => id);
                return selectedSubjects;
              }
            }
          });
          newUser.UserCohort = {
            create: selectedCohorts.map((id) => ({
              cohort: { connect: { id } },
            })),
          };
          newUser.UserSubject = {
            create: selectedSubjects.map((id) => ({
              subject: { connect: { id } },
            })),
          };
        }
      }
      await registerTeacher({ variables: { input: newUser } });
    } catch (error) {
      if (error.message) {
        notification.error({ message: error.message, description: 'Error registering teacher' });
      } else {
        notification.error({ message: 'Error Occurred', description: 'Error registering teacher' });
      }
    }
  };
  useEffect(() => {
    if (response && response.registerUser) {
      notification.success({ message: 'New teacher added', description: 'Teacher registration successful' });
      push(SETUP_USER_HOME);
    }
  }, [response, push]);

  useEffect(() => {
    if (cohorts.length > 0) {
      setAvailableCohort(cohorts);
    }
  }, [cohorts]);

  const handleFileChange = ({ file }) => {
    getBase64(file, (imgBase64) => setProfilePicture({ file, imgBase64 }));
  };
  const handleForm = ((field) => {
    if (field.userCohort) {
      const selectedCohort = form.getFieldValue('userCohort');
      if (selectedCohort && selectedCohort.length > 0) {
        const validItems = selectedCohort.map((item) => {
          if (item) return item.cohort;
          return undefined;
        });
        const remainsCohort = cohorts.map((item) => {
          if (includes(validItems, item.id)) return { ...item, selected: true };
          return { ...item, selected: false };
        });
        setAvailableCohort(remainsCohort);
      }
    }
    if (field.role) {
      if (field.role === 'ADMIN') {
        return setShowCohort(false);
      }
      return setShowCohort(true);
    }
    if (field.country) {
      const countryInfo = getCountries().filter(({ name }) => name === field.country)[0];
      const newStates = makeStateOption(countryInfo.code);
      setPhoneCode(countryInfo.dial_code);
      form.resetFields(['state']);
      setStates(newStates);
    }
  });

  return (
    <div className="profile-teacher-full">
      <Row>
        <Col md={16} xs={24}>
          <Card className="profile-card gx-pr-0 gx-pl-0" title="Teacher Registration">
            <Form onValuesChange={handleForm} form={form} onFinish={handleFormSubmit} name="Teacher_Form" layout="vertical">
              <Row style={{ flexDirection: 'row' }} justify="space-between" className="no-flex-column form-row">
                <Col sm={24} md={12}>
                  <FormItem name="firstName" label="First Name" rules={[{ required: true, type: 'string', message: 'provide first name' }]}>
                    <Input placeholder="Enter first name" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Last Name" name="lastName" rules={[{ required: true, type: 'string', message: 'provide last name' }]}>
                    <Input placeholder="Enter last name" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Email" name="email" rules={[{ required: true, type: 'email', message: 'provide teacher email' }]}>
                    <Input placeholder="Enter teacher email" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem
                    label="Phone number"
                    name="phoneNumber"
                    rules={[{
                      required: true, message: 'provide teacher phone number', min: 10, max: 11,
                    }]}
                  >
                    <Input addonBefore={phoneCode} type="number" placeholder="Enter teacher phone number" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Gender" name="gender" rules={[{ required: true, message: 'provide gender' }]}>
                    <Select placeholder="select gender">
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Username" name="userName" rules={[{ required: true, type: 'string', message: 'provide username' }]}>
                    <Input placeholder="Enter Username" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem
                    label="Password"
                    name="password"
                    rules={[{
                      min: 8, required: true, type: 'string', message: 'provide password',
                    }]}
                  >
                    <Input placeholder="Enter Password" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem initialValue="INSTRUCTOR" label="Role" name="role" rules={[{ required: true, message: 'provide role' }]}>
                    <Select placeholder="account role">
                      <Option value="ADMIN">Admin</Option>
                      <Option value="INSTRUCTOR">Instructor</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Date of Employment" name="doe" rules={[{ required: true, message: 'provide teacher role' }]}>
                    <DatePicker placeholder="select date" className="gx-w-100" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Zip Code" name="zipCode" rules={[{ required: false, type: 'string', message: 'provide student zip code' }]}>
                    <Input placeholder="Zip Code" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem initialValue="Nigeria" label="Country" name="country" rules={[{ required: true, type: 'string', message: 'provide student country' }]}>
                    <Select
                      filterOption={(input, option) => {
                        const countryName = option.label.toLowerCase();
                        return countryName.includes(input.toLocaleLowerCase());
                      }}
                      showSearch
                      placeholder="Country"
                      options={countriesOptions}
                      autoComplete="new-password"
                    />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="State of origin" name="state" rules={[{ required: true }]}>
                    <Select
                      filterOption={(input, option) => {
                        const stateName = option.label.toLowerCase();
                        return stateName.includes(input.toLocaleLowerCase());
                      }}
                      showSearch
                      placeholder="State of origin"
                      options={states}
                      autoComplete="new-password"
                    />
                  </FormItem>
                </Col>
                <Col sm={24}>
                  <FormItem label="Address" name="address" rules={[{ required: false, type: 'string', message: 'provide teacher address' }]}>
                    <Input placeholder="teacher Address" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Next of Kin Name" name="kinName" rules={[{ required: false, type: 'string', message: 'provide kin name' }]}>
                    <Input placeholder="teacher next of kin" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12}>
                  <FormItem label="Kin Phone Number" name="kinPhoneNumber">
                    <Input addonBefore={phoneCode} type="number" placeholder="Enter next of kin phone number" />
                  </FormItem>
                </Col>
                <Col sm={24}>
                  <FormItem label="Kin Address" name="kinAddress">
                    <Input placeholder="Enter next of kin address" />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <Divider>Assign Cohort</Divider>
                </Col>
                {showCohort && <CohortField availableCohorts={availableCohort} />}
              </Row>
              <Row className="no-flex-column form-row gx-mt-1">
                <Col sm={24}>
                  <Button className="gx-w-48"><Link to={SETUP_USER_HOME}>Cancel</Link></Button>
                  <Button loading={regLoading || uploadLoading} className="gx-w-48" htmlType="submit" type="primary">Submit</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col md={8} xs={24}>
          <Card className="profile-card picture" title="Profile Picture">
            <div className="photo-card">
              <div className="avatar-wrapper">
                <Avatar style={{ backgroundColor: '#00CC96' }} icon={<UserOutlined size="large" />} src={profilePicture.imgBase64} size="large" className="teacher-avatar" />
              </div>
              <Upload
                customRequest={handleFileChange}
                accept="image/png,image/jpeg,image/jpg"
                showUploadList={false}
              >
                <Button
                  className="upload-btn"
                  icon={<UploadOutlined />}
                >
                  Add Picture
                </Button>
              </Upload>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

TeacherForm.propTypes = {
  cohorts: arrayOf(shape({})).isRequired,
};

export default TeacherForm;
