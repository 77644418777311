export const applyFilter = (users = [], filters) => {
  const { cohortId, accountType } = filters;
  const results = users.filter(({ accountType: userAccType, UserCohort }) => {
    if (cohortId && accountType) {
      const isMatchAccount = accountType === userAccType;
      let cohortMatch = false;
      UserCohort.forEach(({ cohort: { id } }) => {
        if (id === cohortId) cohortMatch = true;
      });
      return isMatchAccount && cohortMatch;
    }
    if (accountType) {
      return accountType === userAccType;
    }
    if (cohortId) {
      let cohortMatch = false;
      UserCohort.forEach(({ cohort: { id } }) => {
        if (id === cohortId) cohortMatch = true;
      });
      return cohortMatch;
    }
    return true;
  });
  return results;
};

export const makeUpdateObject = (payload) => {
  const values = Object.values(payload);
  const keys = Object.keys(payload);
  const newObject = {};
  keys.forEach((key, index) => {
    newObject[key] = { set: values[index] };
  });
  return newObject;
};

export const AUTHOR = 'oliver-ke';
