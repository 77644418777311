import React from 'react';
import { node } from 'prop-types';
import logo from '../../assets/images/icsa-logo.png';
import ballImg from '../../assets/images/widget/balls.png';
import grass from '../../assets/images/widget/grass.png';
import grassSide from '../../assets/images/widget/grass-small.png';
import sideGradiant from '../../assets/images/widget/sidegradiant.png';
import './styles.less';

const DecoCenter = ({ children }) => (
  <div className="deco-center">
    <img className="logo" src={logo} alt="logo" />
    {children}
    <img className="gradient" src={sideGradiant} alt="deco" />
    <img className="balls" src={ballImg} alt="deco" />
    <img className="grass-side" src={grassSide} alt="deco" />
    <img className="footer" src={grass} alt="deco" />
  </div>
);

DecoCenter.propTypes = {
  children: node.isRequired,
};

export default DecoCenter;
