import React, { useContext, useState, useEffect } from 'react';
import {
  Row, Col, Avatar, message, Empty, notification, Divider,
} from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { AuthContext } from '../../../../appContext';
import { GET_SINGLE_USER, GET_STUDENT_ACTIVITIES } from '../../../../graphql';
import { CohortSubjectItem } from '../common';
import { STUDENT_HOME_SUBJECT_R, LOGIN } from '../../../../routes';
import ViewSpinner from '../../../../components/viewSpinner/ViewSpinner';
import ActivityCard from '../../common/ActivityCard';
import './styles.less';

const Landing = () => {
  const { userInfo: { id: userId }, revokeAuth } = useContext(AuthContext);
  const { data: userPayload, loading, error } = useQuery(GET_SINGLE_USER, {
    variables: { id: userId },
    // fetchPolicy: 'cache-and-network',
  });
  const { data: activityPayload, loading: activityLoading } = useQuery(GET_STUDENT_ACTIVITIES, {
    variables: { where: { userId: { equals: userId } } },
  });
  const { push } = useHistory();
  const [userCohort, setUserCohort] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [activities, setActivities] = useState({
    onlineClass: [],
    assignment: [],
  });
  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const { studentActivities } = activityPayload;
      const sorted = sortBy(studentActivities, [(value) => value.activity.dueDate]);
      const firstSix = [...sorted].reverse().slice(0, 6);
      const onlineClass = firstSix.filter(({ activity: { type } }) => type === 'ONLINE_CLASS');
      const assignment = firstSix.filter(({ activity: { type } }) => type === 'ASSIGNMENT');
      setActivities({ onlineClass, assignment });
    }
  }, [activityPayload, activityLoading]);
  useEffect(() => {
    if (!loading && userPayload) {
      if (!userPayload.User.profileStudent) {
        revokeAuth();
        notification.info({
          message: 'Account creation was not complete',
          description: 'Please contact your teacher',
        });
        return push(LOGIN);
      }
      const {
        User: {
          UserCohort, firstName, lastName, profileStudent: { profileImg },
        },
      } = userPayload;
      setUserCohort(UserCohort);
      setUserDetail({ firstName, lastName, profileImg });
    }
    // eslint-disable-next-line
  }, [userPayload, loading]);
  if (error) {
    message.error('Could not load cohorts');
    return <></>;
  }
  return (
    <div>
      <Row className="header">
        {userDetail && (
        <Col span={24} className="header-wrapper">
          <h3 className="ic-ft-bubble">
            Welcome!
            {' '}
            <span>{`${userDetail.firstName} ${userDetail.lastName}`}</span>
          </h3>
          <div className="avatar-wrapper">
            <Avatar src={userDetail.profileImg} size="large" className="student-avatar" />
          </div>
        </Col>
        )}
      </Row>
      {!loading && !error ? (
        <>
          <Row className="cohort-wrapper">
            <Col span={24}>
              <h4 className="ic-ft-dosis title">My Cohorts</h4>
            </Col>
            {userCohort.map(({ id, cohort: { name, id: cohortId } }) => (
              <CohortSubjectItem extras="click continue to see lesson notes" key={id} title={name} link={`${STUDENT_HOME_SUBJECT_R}${cohortId}`} btnText="Continue" />
            ))}
          </Row>
          <Row className="recent-activity">
            <Col span={24}>
              <h4 className="ic-ft-dosis title">Recent Activities</h4>
            </Col>
          </Row>
          <Row className="gx-mt-1">
            <Divider className="gx-mb-4" orientation="left">Online Classes</Divider>
            {activities.onlineClass.map((
              {
                id, status, activity: { title, dueDate, type }, submission,
              },
            ) => (
              <ActivityCard
                id={id}
                type={type}
                key={id}
                submission={submission}
                status={status}
                date={dueDate}
                title={title}
              />
            ))}
            {!activityLoading && activities.onlineClass.length === 0 && (
              <Col span={24}>
                <Empty description="You don't have any online class yet" />
              </Col>
            )}
          </Row>
          <Row className="gx-mb-2">
            <Divider className="gx-mb-4" orientation="left">Assignments</Divider>
            {activities.assignment.map((
              {
                id, status, activity: { title, dueDate, type }, submission,
              },
            ) => (
              <ActivityCard
                id={id}
                type={type}
                key={id}
                submission={submission}
                status={status}
                date={dueDate}
                title={title}
              />
            ))}
            {!activityLoading && activities.assignment.length === 0 && (
              <Col span={24}>
                <Empty description="You don't have any assignment yet" />
              </Col>
            )}
          </Row>
        </>
      ) : <ViewSpinner />}
    </div>
  );
};

export default Landing;
