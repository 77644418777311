import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useQuery } from '@apollo/client';
import { StudentForm, TeacherForm } from './forms';
import { GET_ALL_COHORT } from '../../../graphql';
import { AuthContext } from '../../../appContext';

const AddNewUser = () => {
  const { userType } = useParams();
  const { revokeAuth } = useContext(AuthContext);
  const { loading: cohortLoading, data: cohortData, error: cohortError } = useQuery(GET_ALL_COHORT);
  const [cohorts, setCohorts] = useState([]);
  useEffect(() => {
    if (!cohortLoading && cohortData) {
      const { getAllCohort } = cohortData;
      setCohorts(getAllCohort);
    }
  }, [cohortData, cohortLoading]);
  if (cohortError) {
    if (cohortError.message.includes('Access denied!')) {
      revokeAuth();
    }
    message.error('Could not get cohorts');
    return <></>;
  }

  return (
    <div>
      {userType === 'student' ? <StudentForm cohorts={cohorts} /> : <TeacherForm cohorts={cohorts} />}
    </div>
  );
};

export default AddNewUser;
