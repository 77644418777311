// the internal routes for this view would be placed here
import {
  LEARNING, LEARNING_TOPIC, LEARNING_TOPIC_CREATE, LEARNING_TOPIC_EDIT,
} from '../../routes';
import Home from './home';
import Topic from './topics';
import NewTopic from './newTopic';
import EditTopic from './editTopic';

export default [
  {
    id: 30, title: 'Home', exact: true, path: LEARNING, component: Home,
  },
  {
    id: 32, title: 'Topics', exact: true, path: LEARNING_TOPIC, component: Topic,
  },
  {
    id: 33, title: 'New Topic', exact: true, path: LEARNING_TOPIC_CREATE, component: NewTopic,
  },
  {
    id: 34, title: 'Edit Topic', exact: true, path: LEARNING_TOPIC_EDIT, component: EditTopic,
  },
];
