import React, { useContext } from 'react';
import {
  Layout, Popover, Avatar, Breadcrumb,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { upperFirst } from 'lodash';
import {
  AuthContext, UiContext, toggleCollapsedSideNav, onNavStyleChange,
} from '../../appContext';
import { LOGIN } from '../../routes';
import { NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import LearnHeader from './LearnHeader';

const { Header } = Layout;
const { Item: BreadcrumbItem } = Breadcrumb;
const TopBar = () => {
  const { uiState: { navStyle, navCollapsed, width }, uiDispatch } = useContext(UiContext);
  const {
    userInfo: {
      firstName, lastName, accountType, profileTeacher,
    }, setIsAuthenticated,
  } = useContext(AuthContext);
  const { push, location: { pathname } } = useHistory();
  const navs = pathname.split('/').filter((path) => path.length < 20 && path.length > 1);
  const handleUserLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    push(LOGIN);
  };

  const handleToggleNav = () => {
    if (width > TAB_SIZE) {
      // this is desktop
      if (navStyle === NAV_STYLE_FIXED) {
        return uiDispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
      }
      uiDispatch(onNavStyleChange(NAV_STYLE_FIXED));
    } else {
      // this should be mobile
      uiDispatch(toggleCollapsedSideNav(!navCollapsed));
    }
  };
  const isMobile = width < TAB_SIZE;
  return (
    <>
      {isMobile && <div style={{ marginBottom: '3.5rem' }} />}
      <Header style={{ background: '#F8B54D' }}>
        <div className="gx-linebar gx-mr-3">
          {accountType === 'STUDENT' ? (
            <LearnHeader
              handleToggleNav={handleToggleNav}
              navStyle={navStyle}
              isMobile={isMobile}
            />
          ) : (
            <>
              {isMobile ? (
                <i
                  className={`gx-icon-btn toggler icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'}`}
                  onClick={handleToggleNav}
                  role="presentation"
                />
              ) : (
                <h4 style={{ color: '#f0f0f0', opacity: 0.8 }}>
                  <Breadcrumb>
                    {navs.map((pathName, idx) => (
                      <BreadcrumbItem key={idx}>{upperFirst(pathName)}</BreadcrumbItem>
                    ))}
                  </Breadcrumb>
                </h4>
              )}
            </>
          )}
        </div>
        <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none" />
          {accountType !== 'STUDENT' && (
          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={(
                <div
                  role="presentation"
                  style={{ cursor: 'pointer' }}
                  onClick={handleUserLogout}
                >
                  Log Out
                </div>
            )}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                {(profileTeacher && profileTeacher.profileImg) ? (
                  <Avatar
                    src={profileTeacher.profileImg}
                    size="large"
                    style={{ verticalAlign: 'middle', border: '2px solid #fde3cf' }}
                  />
                ) : (
                  <Avatar
                    size="large"
                    style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  >
                    {firstName[0]}

                  </Avatar>
                )}
                <span style={{ color: '#4f4f4f' }} className="gx-pl-2">
                  {firstName}
                  {' '}
                  {lastName}
                </span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>
          )}
        </ul>
      </Header>
    </>
  );
};

export default TopBar;
