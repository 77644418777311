import { gql } from '@apollo/client';

export const DELETE_TOPIC = gql`
	mutation deleteTopic($where: TopicWhereUniqueInput!) {
		deleteTopic(where: $where) {
			id
			title
		}
	}
`;

export const CREATE_TOPIC = gql`
	mutation createTopic($data: TopicCreateInput!) {
		createTopic(data: $data) {
			id
			title
			topicNo
			status
			updatedAt
			uploads{
				id
				url 
				filename
			}
		}
	}
`;

export const UPDATE_TOPIC = gql`
	mutation updateTopic($where: TopicWhereUniqueInput!, $data: TopicUpdateInput!) {
		updateTopic(where: $where, data: $data) {
			id
			title
			topicNo
			video
			status
			content
			updatedAt
			uploads{
				id
				url 
				filename
			}
		}
	}
`;
