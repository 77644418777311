import React, { useReducer } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import { LOGIN, DASHBOARD, ACCOUNT } from './routes';
import SignIn from './pages/signin';
import Account from './pages/account';
import NotFound from './pages/404';
import ProtectedRoute from './util/ProtectedRoute';
import {
  AuthProvider, UiProvider, uiReducer, initialSettings,
} from './appContext';
import { ScrollToTop } from './util/ScrollToTop';
import './assets/vendors/style';
import './styles/wieldy.less';
import './app.less';

const App = () => {
  const [uiState, uiDispatch] = useReducer(uiReducer, initialSettings);
  return (
    <AuthProvider>
      <UiProvider value={{ uiState, uiDispatch }}>
        <Router>
          <ScrollToTop />
          <Switch>
            <ProtectedRoute exact={false} path={DASHBOARD} component={Dashboard} />
            <Route exact path={LOGIN} component={SignIn} />
            <Route exact path={ACCOUNT} component={Account} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </UiProvider>
    </AuthProvider>
  );
};

export default App;
