import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Card, Form, Row, Input, Col, Switch, Button, Space, InputNumber, message, Upload,
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import Editor from '../../../components/editor';
import { AuthContext } from '../../../appContext';
import { CREATE_TOPIC, GET_SUBJECT, UPLOAD_FILES } from '../../../graphql';
import './styles.less';

const { Item: FormItem } = Form;
const NewTopic = () => {
  const { userInfo: { id: userId } } = useContext(AuthContext);
  const { location: { search }, goBack } = useHistory();
  const [createTopic, { loading }] = useMutation(CREATE_TOPIC);
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILES);

  const subjectId = new URLSearchParams(search).get('sub');
  const [content, setContent] = useState('');
  const handleFormSubmit = async (inputs) => {
    const { attachment, ...fields } = inputs;
    const newTopic = { ...fields, content };
    newTopic.status = inputs.status ? 'PUBLISHED' : 'DRAFT';
    newTopic.user = { connect: { id: userId } };
    newTopic.subject = { connect: { id: subjectId } };
    try {
      if (attachment && attachment.fileList) {
        const { fileList } = attachment;
        let fileArr = [];
        fileList.forEach((file) => {
          const actualFile = file.originFileObj || file;
          fileArr = [...fileArr, actualFile];
        });
        const { data: { createFiles } } = await filesUpload({ variables: { files: fileArr, folder: 'topic' } });
        const filesMap = createFiles.map(({ id }) => ({ id }));
        newTopic.uploads = {
          connect: filesMap,
        };
      }
      await createTopic({
        variables: { data: newTopic },
        update: (proxy, { data: { createTopic: mutate } }) => {
          const variables = { where: { id: subjectId } };
          const dataProxy = proxy.readQuery({ query: GET_SUBJECT, variables });
          const { subject } = dataProxy;
          proxy.writeQuery({
            query: GET_SUBJECT,
            variables,
            data: {
              subject: {
                ...subject,
                Topic: [...subject.Topic, mutate],
              },
            },
          });
        },
      });
      message.success('Topic created successfully');
      goBack();
    } catch (err) {
      message.error('Could not create topic');
    }
  };
  return (
    <div>
      <Card className="gx-card gx-p-1 new-topic">
        <Form requiredMark="optional" onFinish={handleFormSubmit} layout="vertical">
          <Row className="no-flex-column">
            <Col span={24} className="gx-mb-3">
              <div className="heading">
                <h3>New Topic</h3>
                <Button onClick={goBack} size="middle" shape="circle" icon={<ArrowLeftOutlined />} />
              </div>
            </Col>
            <Col span={24}>
              <FormItem valuePropName="checked" name="status" initialValue={false} required={false}>
                <Switch checkedChildren="Publish" unCheckedChildren="Draft" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name="title" required label="Title">
                <Input placeholder="enter a title" />
              </FormItem>
            </Col>
            <Col xm={24} md={14}>
              <FormItem name="video" required={false} label="Video  Id">
                <Input placeholder="e.g ZZUyshYtsw" />
              </FormItem>
            </Col>
            <Col xm={24} md={10}>
              <FormItem name="topicNo" required={false} label="Topic Order">
                <InputNumber className="gx-w-100" placeholder="ordering" type="number" />
              </FormItem>
            </Col>
          </Row>
          <Col className="gx-mt-2" span={24}>
            <Editor content={content} setContent={setContent} />
          </Col>
          <Col className="gx-mt-4" span={24}>
            <FormItem
              label="Attachment"
              valuePropName="file"
              name="attachment"
            >
              <Upload
                listType="picture"
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Attach files</Button>
              </Upload>
            </FormItem>
          </Col>
          <Col className="gx-mt-4" span={24}>
            <Space>
              <Button onClick={goBack}>Cancel</Button>
              <Button loading={loading || uploadLoading} htmlType="submit" type="primary">Save Topic</Button>
            </Space>
          </Col>
        </Form>
      </Card>
    </div>
  );
};

export default NewTopic;
