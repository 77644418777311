import React, { useEffect, useState } from 'react';
import {
  Form, Button, Col, Row, Input, Select, DatePicker, Checkbox, Upload, message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { find } from 'lodash';
import {
  func, bool, shape, arrayOf,
} from 'prop-types';
import { UPLOAD_FILES, CREATE_ACTIVITY, GET_ALL_ACTIVITY } from '../../../graphql';
import Editor from '../../../components/editor';

const { Option } = Select;
const { Item: FormItem } = Form;
const CreateActivity = ({
  userInfo, cohort, setLoading, cohortLoading, closeModal, form,
}) => {
  const [instruction, setInstruction] = useState('');
  const [createActivity, { loading: activityLoading }] = useMutation(CREATE_ACTIVITY);
  const [filesUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILES);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (activityLoading || uploadLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [activityLoading, uploadLoading, setLoading]);
  const handleFormSubmit = async (inputs) => {
    const {
      attachment, type, dueDate, title, hasTextInput, embedLink,
    } = inputs;
    const subjectsMap = inputs.subjects.map((id) => ({ id }));
    const newActivity = {
      type,
      instruction,
      embedLink,
      dueDate: moment(dueDate).toString(),
      user: { connect: { id: userInfo.id } },
      title,
      cohort: { connect: { id: inputs.cohort } },
      subjects: { connect: subjectsMap },
      hasTextInput,
    };
    try {
      if (attachment && attachment.fileList) {
        const { fileList } = attachment;
        let fileArr = [];
        fileList.forEach((file) => {
          const actualFile = file.originFileObj || file;
          fileArr = [...fileArr, actualFile];
        });
        const { data: { createFiles } } = await filesUpload({ variables: { files: fileArr, folder: 'activity' } });
        const filesMap = createFiles.map(({ id }) => ({ id }));
        newActivity.attachments = {
          connect: filesMap,
        };
      }
      await createActivity({
        variables: { data: newActivity },
        update: (proxy, { data: { createActivity: payload } }) => {
          const existing = proxy.readQuery({ query: GET_ALL_ACTIVITY });
          proxy.writeQuery({
            query: GET_ALL_ACTIVITY,
            data: {
              activities: [
                payload,
                ...existing.activities,
              ],
            },
          });
        },
      });
      form.resetFields();
      message.success('Activity created successfully');
      return closeModal();
    } catch (err) {
      message.error('Error creating activity');
    }
  };
  const getSubjects = (cohortId) => {
    const withSubject = find(cohort, (c) => c.id === cohortId);
    if (!withSubject || !withSubject.length > 1) return [];
    return withSubject.Subject;
  };
  const handleFormChange = (inputs) => {
    if (inputs.cohort) {
      const availSubject = getSubjects(inputs.cohort);
      setSubjects(availSubject);
    }
  };
  return (
    <Form requiredMark onFinish={handleFormSubmit} onValuesChange={handleFormChange} form={form} name="add-activity-form" layout="vertical" hideRequiredMark>
      <Row style={{ flexDirection: 'row' }}>
        <Col span={24}>
          <FormItem name="title" label="Activity Title" rules={[{ required: true, message: 'Provide activity title' }]}>
            <Input placeholder="activity title" />
          </FormItem>
        </Col>
        <Col xm={24} md={12}>
          <FormItem name="dueDate" label="Due Date" rules={[{ required: true, message: 'Provide due date' }]}>
            <DatePicker className="gx-w-100" placeholder="select due date" />
          </FormItem>
        </Col>
        <Col xm={24} md={12}>
          <FormItem initialValue="ASSIGNMENT" name="type" label="Activity Type">
            <Select>
              <Option key="ONLINE_CLASS">Online Class</Option>
              <Option key="ASSIGNMENT">Assignment</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="embedLink" label="Form Embed Link">
            <Input placeholder="e.g <iframe src='https://docs.google.com/forms../>" />
          </FormItem>
        </Col>
        <Col span={24}>
          <Editor overrideConfig={{ toolbarSticky: false }} content={instruction} setContent={setInstruction} />
        </Col>
        <Col className="gx-mt-4" xm={24} md={12}>
          <FormItem name="cohort" label="Cohort" rules={[{ required: true, message: 'Provide cohort' }]}>
            <Select loading={cohortLoading} placeholder="Select cohort">
              {cohort.map(({ id, name }) => (
                <Option value={id} key={id}>{name}</Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col className="gx-mt-4" xm={24} md={12}>
          <FormItem name="subjects" label="Subjects" rules={[{ required: true, message: 'Provide activity subjects' }]}>
            <Select mode="multiple">
              {subjects.map(({ id, name }) => (
                <Option key={id} value={id}>{name}</Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem initialValue valuePropName="checked" name="hasTextInput">
            <Checkbox>Show text input?</Checkbox>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label="Attachment"
            valuePropName="file"
            name="attachment"
          >
            <Upload
              listType="picture"
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Attach files</Button>
            </Upload>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

CreateActivity.propTypes = {
  userInfo: shape({}).isRequired,
  cohort: arrayOf(shape({})).isRequired,
  setLoading: func.isRequired,
  cohortLoading: bool.isRequired,
  closeModal: func.isRequired,
  form: shape({}).isRequired,
};

export default CreateActivity;
