import React, { useEffect, useState } from 'react';
import {
  Form, Input, Button, notification, Card,
} from 'antd';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { INIT_FORGET_PASSWORD } from '../../graphql';
import DecoCenter from '../../components/DecoCenter';
import { LOGIN } from '../../routes';

const { Item: FormItem } = Form;
const ForgetPassword = () => {
  const [forgetPassword, { data, loading }] = useMutation(INIT_FORGET_PASSWORD);
  const [showRes, setShowRes] = useState(false);
  const [userMail, setUserMail] = useState('');
  const [noUser, setNoUser] = useState(false);

  const handleFormSubmit = async (input) => {
    const { email } = input;
    try {
      await forgetPassword({ variables: { email } });
      setUserMail(email);
    } catch (error) {
      notification.error({ message: 'Ops! Something went wrong' });
    }
  };

  useEffect(() => {
    if (!loading && data) {
      const { initForgetPassword } = data;
      if (!initForgetPassword) {
        notification.error({ message: 'Email does not exist!' });
        setNoUser(true);
      }
      setShowRes(true);
    }
  }, [loading, data]);

  return (
    <DecoCenter>
      {!showRes ? (
        <Form layout="vertical" requiredMark={false} onFinish={handleFormSubmit} name="forget_password" className="form-wrapper">
          <div className="gx-login-text">
            <h3 className="gx-m-4 gx-fs-xl">Forgot Your Password</h3>
          </div>
          <FormItem name="email" className="input-field" label="Enter Email" rules={[{ required: true, type: 'email', message: 'Please provide a valid email' }]}>
            <Input type="email" placeholder="enter account email" />
          </FormItem>
          <Form.Item className="input-field">
            <Button loading={loading} htmlType="submit" size="large" type="primary" className="gx-w-100">
              {loading ? 'Loading' : 'Forgot Password'}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Card className="notice-card gx-mt-4" title="Forgot password">
          {!noUser ? (
            <>
              <p>
                An email has been sent to your email address
                {' '}
                <i>{userMail}</i>
              </p>
              <p>
                Kindly click the link in the email to reset your password
              </p>
              <Button type="primary">
                <Link to={LOGIN}>Back</Link>
              </Button>
            </>
          ) : (
            <>
              <p>Not allowed</p>
              <p>Please check that the email provided is correct or contact support admin@icsa.com</p>
              <Button type="primary">
                <Link to={LOGIN}>Back</Link>
              </Button>
            </>
          )}
        </Card>
      )}
    </DecoCenter>
  );
};

export default ForgetPassword;
