import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Row, Col, Button, Card, Empty,
} from 'antd';
import { PlusOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { GET_ALL_ACTIVITY } from '../../../graphql';
import CreateEditActivity from '../createEdit';
import { ACTIVITIES } from '../../../routes';
import deleteConfirm from './deleteConfirm';
import ViewSpinner from '../../../components/viewSpinner/ViewSpinner';
import './styles.less';

const Home = () => {
  const { location: { search }, push } = useHistory();
  const showDetailId = new URLSearchParams(search).get('detail');
  const [showDrawer, setShowDrawer] = useState(false);
  const { data: activityPayload, loading: activityLoading } = useQuery(
    GET_ALL_ACTIVITY, { fetchPolicy: 'network-only' },
  );
  const [activities, setActivities] = useState([]);
  const [drawerMode, setDrawerMode] = useState('EDIT');
  const [drawerPayload, setDrawerPayload] = useState({});
  useEffect(() => {
    if (!activityLoading && activityPayload) {
      const { activities: activityRes } = activityPayload;
      const reverseRes = [...activityRes].reverse();
      setActivities(reverseRes);
    }
  }, [activityLoading, activityPayload]);

  useEffect(() => {
    if (showDetailId && activityPayload) {
      const { activities: activityRes } = activityPayload;
      const activity = activityRes.filter(({ id }) => id === showDetailId)[0];
      if (activity) {
        setDrawerPayload(activity);
        setDrawerMode('EDIT');
        setShowDrawer(true);
      }
    }
  }, [showDetailId, activityPayload]);

  const handleShowDrawerClick = (mode, payload = {}) => {
    if (mode === 'CREATE') {
      setDrawerMode('CREATE');
      return setShowDrawer(true);
    }
    setDrawerPayload(payload);
    setDrawerMode('EDIT');
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    if (showDrawer) {
      push(ACTIVITIES);
    }
    setShowDrawer(false);
  };

  return (
    <div className="gx-mb-4 activities-home">
      {showDrawer && (
      <CreateEditActivity
        payload={drawerPayload}
        mode={drawerMode}
        showDrawer={showDrawer}
        handleClose={handleCloseDrawer}
      />
      )}
      <Row>
        <Col span={24}>
          <div className="header">
            <h4>Activities</h4>
            <Button onClick={() => handleShowDrawerClick('CREATE')} type="primary" size="large">
              <PlusOutlined />
              {' '}
              Add New
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={{ md: 15 }}>
        {activities.map((activity) => {
          const {
            id, title, type, cohort: { name }, submissions,
          } = activity;
          return (
            <Col key={id} xs={24} md={12}>
              <Card className="gx-m-1 gx-w-100 activity-list" hoverable>
                <div className="icon-wrapper"><EllipsisOutlined /></div>
                <div className="card-content">
                  <div className="description">
                    <h4 className="type assignment">{type}</h4>
                    <h4 className="title">{title}</h4>
                    <div className="extras">
                      <h5>
                        <span>{`${submissions.length} `}</span>
                        Submission
                      </h5>
                      <div className="bar" />
                      <h5 className="cohort">
                        {name}
                      </h5>
                    </div>
                  </div>
                  <div className="controls">
                    <Button onClick={() => deleteConfirm(id)} type="link" className="delete-btn">
                      <DeleteOutlined />
                    </Button>
                    <div>
                      <Button
                        onClick={() => handleShowDrawerClick('EDIT', activity)}
                        className="edit"
                        type="link"
                      >
                        Edit
                      </Button>
                      <Link to={`${ACTIVITIES}/${id}`}>
                        <Button type="link">Submission</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
        { !activityLoading && activities.length === 0 && (
          <Col span={24}>
            <Card>
              <Empty description="You don't have activities yet" />
            </Card>
          </Col>
        )}
        {activityLoading && <ViewSpinner />}
      </Row>
    </div>
  );
};

export default Home;
