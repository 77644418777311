import React, { useState, useEffect, useContext } from 'react';
import { Drawer, message as AntMessage } from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import SupportRequests from './tickets';
import { AuthContext } from '../../appContext';
import {
  GET_SUPPORTS, GET_SUPPORT_REPLY, CREATE_SUPPORT_REPLY, GET_USER_SUPPORTS, UPDATE_SUPPORT,
} from '../../graphql';
import CircularProgress from '../../lib/CircularProgress';
import Replies from './replies';
import './styles.less';

const Support = () => {
  const { userInfo } = useContext(AuthContext);
  const query = userInfo.role === 'ADMIN' ? GET_SUPPORTS : GET_USER_SUPPORTS;
  const queryVariables = {
    orderBy: { createdAt: 'desc' },
    where: {
      isClosed: { equals: false },
    },
  };
  const { data: supportPayload, loading: supportLoading } = useQuery(query, {
    variables: queryVariables,
  });
  const [updateSupport] = useMutation(UPDATE_SUPPORT);
  const [replySupport] = useMutation(CREATE_SUPPORT_REPLY);
  const [getReplies, { data: repliesPayload, loading: replyLoading }] = useLazyQuery(GET_SUPPORT_REPLY, {
    pollInterval: 1000,
  });
  const [supports, setSupport] = useState([]);
  const [supportReplies, setSupportReplies] = useState([]);
  const [uiControl, setUiControl] = useState({
    loader: false,
    drawerState: false,
    selectedSupportId: null,
    searchInput: null,
    selectedSupport: null,
  });

  useEffect(() => {
    if (!supportLoading && supportPayload) {
      // const { supports: supportsRes } = supportPayload;
      const supportsRes = userInfo.role === 'ADMIN' ? supportPayload.supports : supportPayload.userSupports;
      setSupport(supportsRes);
    }
  }, [supportPayload, supportLoading, userInfo.role]);
  useEffect(() => {
    if (!replyLoading && repliesPayload) {
      const { supportReplies: supportRepliesRes } = repliesPayload;
      setSupportReplies(supportRepliesRes);
    }
  }, [repliesPayload, replyLoading]);

  const closeSupport = async (id) => {
    try {
      await updateSupport({
        variables: {
          where: { id },
          data: {
            isClosed: { set: true },
          },
        },
        update: (cache) => {
          const cacheValue = cache.readQuery({
            query,
            variables: queryVariables,
          });
          const dataUpdateKey = userInfo.role === 'ADMIN' ? 'supports' : 'userSupports';
          const existingSupport = cacheValue[dataUpdateKey];
          if (existingSupport) {
            const update = existingSupport.filter(({ id: supportId }) => supportId !== id);
            cache.writeQuery({
              query,
              variables: queryVariables,
              data: {
                [dataUpdateKey]: [...update],
              },
            });
          }
        },
      });
      setUiControl({
        ...uiControl,
        selectedSupportId: null,
        selectedSupport: null,
      });
      AntMessage.success('Support closed successfully');
    } catch (error) {
      AntMessage.error('Error, could not close support');
    }
  };

  const onSelectTicket = (ticket) => {
    setUiControl({ ...uiControl, selectedSupport: ticket, selectedSupportId: ticket.id });
    getReplies({ variables: { where: { supportId: { equals: ticket.id } } } });
  };
  const onReply = (message) => {
    replySupport({
      variables: {
        data: {
          message,
          Support: { connect: { id: uiControl.selectedSupportId } },
          user: { connect: { id: userInfo.id } },
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createSupportReply: {
          __typename: 'SupportReply',
          message,
        },
      },
      update: (cache, { data: { createSupportReply: res } }) => {
        const { supportReplies: existingReplies } = cache.readQuery({
          query: GET_SUPPORT_REPLY,
          variables: {
            where: { supportId: { equals: uiControl.selectedSupportId } },
          },
        });
        const cacheValue = cache.readQuery({
          query,
          variables: queryVariables,
        });
        const existingSupport = userInfo.role === 'ADMIN' ? cacheValue.supports : cacheValue.userSupports;
        const supportUpdate = existingSupport.map((support) => {
          if (support.id === uiControl.selectedSupportId) {
            return { ...support, replyCount: support.replyCount + 1 };
          }
          return support;
        });
        cache.writeQuery({
          query: GET_SUPPORT_REPLY,
          variables: {
            where: { supportId: { equals: uiControl.selectedSupportId } },
          },
          data: {
            supportReplies: [...existingReplies, res],
          },
        });
        const dataUpdateKey = userInfo.role === 'ADMIN' ? 'supports' : 'userSupports';
        cache.writeQuery({
          query,
          variables: queryVariables,
          data: {
            [dataUpdateKey]: [...supportUpdate],
          },
        });
      },
    });
  };

  return (
    <div className="gx-main-content">
      <div className="gx-app-module gx-chat-module">
        <div className="gx-chat-module-box">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={uiControl.drawerState}
              onClose={() => setUiControl({ ...uiControl, drawerState: false })}
            >
              <SupportRequests
                supports={supports}
                selectedTicketId={uiControl.selectedSupportId}
                onSelectTicket={onSelectTicket}
              />
            </Drawer>
          </div>
          <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
            <SupportRequests
              supports={supports}
              selectedTicketId={uiControl.selectedSupportId}
              onSelectTicket={onSelectTicket}
            />
          </div>
          {replyLoading
            ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              <Replies
                replies={supportReplies}
                selectedTicket={uiControl.selectedSupport}
                onReply={onReply}
                closeSupport={closeSupport}
                setDrawerState={(state) => setUiControl({ ...uiControl, drawerState: state })}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default Support;
