import { gql } from '@apollo/client';

// Get all users
export const GET_ALL_USERS = gql`
	query getAllUsers($orderBy: [UserOrderByWithRelationInput!]) {
		Users(orderBy: $orderBy) {
			id
			lastName
			firstName
			email
			userName
			accountType
			status
			role
			UserCohort {
				id
				cohort {
					id
					name
				}
			}
			UserSubject {
				id
				subject {
					id
					name
				}
			}
		}
	}
`;


export const GET_TOKEN_USER = gql`
	query getTokenUser($id: String!) {
		User(where: { id: $id }) {
			id
			lastName
			firstName
			email
			userName
			accountType
			status
			role
			UserCohort {
				id
				cohort {
					id
					name
				}
			}
			UserSubject {
				id
				subject {
					id
					name
				}
			}
		}
	}
`;

// get users own subjects
export const GET_USER_OWN_SUBJECT = gql`
	query getUserOwnSubject($where: UserSubjectWhereInput) {
		userSubjects(where: $where) {
			id
		}
	}
`;

// get single user
export const GET_SINGLE_USER = gql`
	query user($id: String!){
		User(where: { id: $id }) {
			id
			lastName
			firstName
			email
			userName
			accountType
			status
			role
			profileStudent {
				id
				profileImg
				gender
				dob
				state
				zipCode
				country
				parentName
				parentPhoneNumber
				address
				admissionNumber
			}
			profileTeacher {
				id
				address
				phoneNumber
				profileImg
				state
				zipCode
				country
				gender
				kinName
				kinPhoneNumber
				kinAddress
				doe
			}
			UserCohort {
				id
				cohort {
					id
					name
				}
			}
			UserSubject {
				id
				subject {
					id
					name
					cohortId
				}
			}
		}
	}
`;
