import { gql } from '@apollo/client';

export const CREATE_SUPPORT = gql`
	mutation createSupport($data: SupportCreateInput!) {
		createSupport(data: $data) {
			id
			description
			title
		}
	}
`;

export const UPDATE_SUPPORT = gql`
  mutation updateSupport($data: SupportUpdateInput!, $where: SupportWhereUniqueInput! ){
    updateSupport(data: $data, where: $where){
      id
      supportId
      title
      description
      createdAt
    }
  }
`;

export const CREATE_SUPPORT_REPLY = gql`
  mutation createSupportReply($data: SupportReplyCreateInput!){
    createSupportReply(data: $data){
      id
      message,
      user {
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_MESSAGING = gql`
  mutation createMessaging($data: MessagingCreateInput!){
    createMessaging(data: $data){
      id
			type
			title
			body
			createdAt
			recipiant {
				id
				firstName
				lastName
			}
    }
  }
`;

export const DELETE_MESSAGING = gql`
  mutation deleteMessage($where: MessagingWhereUniqueInput!) {
    deleteMessaging(where: $where){
      id
      type
      body
      title
      createdAt
			recipiant {
				id
				firstName
				lastName
			}
    }
  }
`;
